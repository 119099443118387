import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {Image, Video, Transformation, CloudinaryContext} from 'cloudinary-react';
import {TweenMax,TimelineMax,TimelineLite,TweenLite,Elastic,Sine,bbox,center,Power1,Circ} from 'gsap';
import {reactLocalStorage} from 'reactjs-localstorage';
import Path from './Path';
import Sidebar from './Sidebar';
class Analytics extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
            auth_token : reactLocalStorage.get('auth_token'),
            totalItems:'',
            totalRentals:'',
            earnings:''
        }
        this.handleChange = this.handleChange.bind(this);
        this.loadData = this.loadData.bind(this);
    }
    handleChange(e) {
        this.setState({
           [e.target.name]: e.target.value
        });
    }
    componentDidMount() {
       this.loadData();
       setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    loadData(){
        var _this = this;
        axios({
            method: 'get',
            url: Path.PRODUCT_URL+Path.URL.ANALYTICS_COUNT,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(response => {
            this.setState({
                totalItems:response.data.totalItems,
                totalRentals:response.data.totalRentals,
                earnings:response.data.earnings,
                selling:response.data.sellCount
            })
        })
        .catch(function (error) {
            //console.log(error);
        });
    }   
    render(){
        return(
            <div>               
                <div className="main_wrapper">
                    <section className="dashboard_bg">
                        <Sidebar/>
                        <div className="content-wrapper">
                            <div className="content-height-wrapper">
                                <div className="tab-content">
                                    <div className="col-xs-12 text-center">
                                        <h3 className="ho_renshop_sH">Analytics</h3>
                                    </div>
                                    <div className="col-xs-12 no-padding m-t-20">
                                        <div className="analytics_H">Stats</div>
                                        <div className="analytics_bor">
                                            <div className="analytics_innerCont">
                                                <label>Items listed</label>
                                                <h4>{this.state.totalItems} Items</h4>
                                            </div>
                                            <div className="analytics_innerCont">
                                                <label>Earnings</label>
                                                <h4>£ {this.state.earnings}	</h4>
                                            </div>
                                            <div className="analytics_innerCont">
                                                <label>Rentals</label>
                                                <h4>{this.state.totalRentals} Items</h4>
                                            </div>	
                                            <div className="analytics_innerCont">
                                                <label>Selling</label>
                                                <h4>{this.state.selling} Items</h4>
                                            </div>		
                                        </div>
                                        <div className="analytics_H hide">Lender stats</div>
                                        <div className="analytics_bor hide">
                                            <div className="analytics_innerCont">
                                                <label>Response time</label>
                                                <h4>&lt;1min</h4>
                                            </div>
                                            <div className="analytics_innerCont">
                                                <label>Return borrowers</label>
                                                <h4>0</h4>
                                            </div>	
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div> 
        );
    }
}
export default Analytics;