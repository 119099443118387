import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {reactLocalStorage} from 'reactjs-localstorage';
import $ from 'jquery';
import Path from './Path';
class About extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
        }
        this.goToLogin = this.goToLogin.bind(this);
    }
    goToLogin(){
		$(".weborro_modal").removeClass('display-none').addClass("display-block");
	}
    componentDidMount() {
       setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    componentWillReceiveProps(){
        if(reactLocalStorage.get('auth_token') !== undefined && reactLocalStorage.get('auth_token') !== ''){
            this.setState({loggedUserCheck:true});
        }
    }
    render() {
        return (
            <div className="aboutus-banner">
                <div className="web_bg">
                    <div className="container">
                        <h1 className="web_bg_head">Borrowing, lending and buying with Weborro couldn’t be easier!</h1>
                        <p className="web_bg_cont">Wish you could get whatever you want at a fraction of the price? Ever considered hiring out stuff you’re not using and make some extra money? Weborro can help.</p>
                        <div className="col-xs-12 no-padding">
                            <a className="web_bg_find" href={Path.BASE_URL+Path.PAGE_NAME.Howitworks}>FIND OUT MORE!</a>
                        </div>
                    </div>
                </div>
                
                <div className="borro_better">
                    <div className="container">
                        <h2>Borrowing is better with Weborro</h2>
                        <img className="borro_better_banner" src={Path.IMAGE_PATH+"/borrowbetterbg.jpg"}/>
                        <div className="col-xs-12 no-padding">
                            <div className="col-md-4 col-sm-4 col-xs-12 borro_better_left">    
                                <h3>"The option to Sell or Lend is a great way to help borrowers decide what would be better for them"</h3>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12 borro_better_center">    
                                <h3>The new approach to lending and borrowing</h3>
                                <p>At Weborro we believe in doing things a little differently because life can be so much better. You can buy anything you want off of Amazon and eBay, but what if you don’t want to buy it? What if you want to borrow it? That’s how Weborro was born.</p>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12 borro_better_center">    
                            <img src={Path.IMAGE_PATH+"/borro_better_right.jpg"}/>
                            </div>
                        </div>
                    </div>
                 </div>
                <div className="join_web">
                    <div className="container">
                        <h1 className="join_web_head">Join Weborro and become a member of on an online community of lenders earning money renting out their unused belongings. </h1>
                        <div className="col-xs-12 no-padding">
                            {this.props.loggedUser === true || this.state.loggedUserCheck === true? 
                            <a className="join_web_get" href={Path.BASE_URL+Path.PAGE_NAME.ProductAdd}>List an item</a>
                            :
                            <a className="join_web_get" onClick={e => this.goToLogin(e)}>List an item</a>
                            }
                        </div>
                    </div>
                </div>
                <div className="made_it_wasy">
                    <div className="container">
                        <h1 className="made_it_wasy_head">We have made it easy for our lenders and borrowers</h1>
                        <p className="made_it_wasy_cont">We’ve put in place a few easy to follow rules that allow everyone to enjoy Weborro </p>
                        <div className="col-xs-12 made_it_wasy_inner">
                            <div className="col-md-5 col-sm-5 col-xs-12 made_it_wasy_left">
                                <h2>BORROW</h2>
                                <p>Borrow what you need for as long as you need, but bring it back on time</p>
                            </div>
                            <div className="col-md-7 col-sm-7 col-xs-12 made_it_wasy_right">
                                <img src={Path.IMAGE_PATH+"/weborrow-camera-1.jpg"}/>
                            </div>
                        </div>
                        <div className="col-xs-12 made_it_wasy_inner">
                            <div className="col-md-7 col-sm-7 col-xs-12 made_it_wasy_right">
                                <img src={Path.IMAGE_PATH+"/weborrow-camera-2.jpg"}/>
                            </div>
                            <div className="col-md-5 col-sm-5 col-xs-12 made_it_wasy_left made_it_wasyRight_Cont">
                                <h2>HIRE OUT</h2>
                                <p>Rummage through your home and list anything and everything. There's always something for somebody</p>
                            </div>
                        </div>
                        <div className="col-xs-12 made_it_wasy_inner">
                            <div className="col-md-5 col-sm-5 col-xs-12 made_it_wasy_left">
                                <h2>Try before you buy</h2>
                                <p>Try something new and buy it if you like it </p>
                            </div>
                            <div className="col-md-7 col-sm-7 col-xs-12 made_it_wasy_right">
                                <img src={Path.IMAGE_PATH+"/weborrow-guitar-3.jpg"}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cus_dea">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-3 col-xs-12 cus_dea_hover">
                                <div className="cus_dea_head">
                                    <div className="cus_dea_image">
                                        <img src={Path.IMAGE_PATH+"/team1.jpg"}/>
                                    </div>
                                    <div className="cus_dea_title"><h3>This band borrowed a drum kit for gig</h3></div>
                                    <ul className="social-icons icon-size-1">
                                        <li>
                                            <a className="email" target="_blank" href="mailto:#">
                                                <i className="fa fa-envelope fa-1x"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="facebook" target="_blank" href="#">
                                                <i className="fa fa-facebook fa-1x"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="twitter" target="_blank" href="#">
                                                <i className="fa fa-twitter fa-1x"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="linkedin" target="_blank" href="#">
                                                <i className="fa fa-linkedin fa-1x"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="instagram" target="_blank" href="#">
                                                <i className="fa fa-instagram fa-1x"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-3 col-xs-12 cus_dea_hover">
                                <div className="cus_dea_head">
                                    <div className="cus_dea_image">
                                        <img src={Path.IMAGE_PATH+"/team2.jpg"}/>
                                    </div>
                                    <div className="cus_dea_title"><h3>This customer borrowed books for study</h3></div>
                                    <ul className="social-icons icon-size-1">
                                        <li>
                                            <a className="email" target="_blank" href="mailto:#">
                                                <i className="fa fa-envelope fa-1x"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="facebook" target="_blank" href="#">
                                                <i className="fa fa-facebook fa-1x"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="twitter" target="_blank" href="#">
                                                <i className="fa fa-twitter fa-1x"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="linkedin" target="_blank" href="#">
                                                <i className="fa fa-linkedin fa-1x"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="instagram" target="_blank" href="#">
                                                <i className="fa fa-instagram fa-1x"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-3 col-xs-12 cus_dea_hover">
                                <div className="cus_dea_head">
                                    <div className="cus_dea_image">
                                        <img src={Path.IMAGE_PATH+"/team3.jpg"}/>
                                    </div>
                                    <div className="cus_dea_title"><h3>This customer made £1500 from lending her camera for 1 month</h3></div>
                                    <ul className="social-icons icon-size-1">
                                        <li>
                                            <a className="email" target="_blank" href="mailto:#">
                                                <i className="fa fa-envelope fa-1x"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="facebook" target="_blank" href="#">
                                                <i className="fa fa-facebook fa-1x"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="twitter" target="_blank" href="#">
                                                <i className="fa fa-twitter fa-1x"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="linkedin" target="_blank" href="#">
                                                <i className="fa fa-linkedin fa-1x"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="instagram" target="_blank" href="#">
                                                <i className="fa fa-instagram fa-1x"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-3 col-xs-12 cus_dea_hover">
                                <div className="cus_dea_head">
                                    <div className="cus_dea_image">
                                        <img src={Path.IMAGE_PATH+"/team4.jpg"}/>
                                    </div>
                                    <div className="cus_dea_title"><h3>This customer loaned specialist lighting to an entertainment establishment</h3></div>
                                    <ul className="social-icons icon-size-1">
                                        <li>
                                            <a className="email" target="_blank" href="mailto:#">
                                                <i className="fa fa-envelope fa-1x"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="facebook" target="_blank" href="#">
                                                <i className="fa fa-facebook fa-1x"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="twitter" target="_blank" href="#">
                                                <i className="fa fa-twitter fa-1x"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="linkedin" target="_blank" href="#">
                                                <i className="fa fa-linkedin fa-1x"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="instagram" target="_blank" href="#">
                                                <i className="fa fa-instagram fa-1x"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cus_info text-center">
                    <div className="container">
                        <div className="cus_infoHead">Create an account weborro and start listing today</div>
                        {this.props.loggedUser === true || this.state.loggedUserCheck === true? 
                        <a className="cus_info_btn" href={Path.BASE_URL+Path.PAGE_NAME.MyAccount}>Sign Up</a>
                        :
                        <a className="cus_info_btn" onClick={e => this.goToLogin(e)}>Sign Up</a>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default About;