import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Accordion,AccordionItem,AccordionItemHeading,AccordionItemPanel,AccordionItemButton} from 'react-accessible-accordion';
import $ from 'jquery';
import Path from './Path';
class Sitemap extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {

        }
        this.loadData = this.loadData.bind(this);
    }
    loadData(){
    }
    componentDidMount() {
        this.loadData();     
        setTimeout(() =>
            this.props.handler(false)
        , 2000); 
    }
    render() {
        return (
                <div className="contactBg">
                <div className="container">
                      <div className="innerhead">Sitemap</div>
                      <div className="col-xs-12 col-sm-6 col-md-6 col-sm-offset-3 col-md-offset-3 col-xs-offset-0">
                        <ul className="sitemapList">
                            <li><a href={Path.BASE_URL+Path.PAGE_NAME.Root}>Home</a></li>
                            <li><a href={Path.BASE_URL+Path.PAGE_NAME.About}>About us</a></li>
                            <li><a href={Path.BASE_URL+Path.PAGE_NAME.Howitworks}>How it works</a></li>
                            <li><a href={Path.BASE_URL+Path.PAGE_NAME.Faq}>FAQ</a></li>
                            <li><a href={Path.BASE_URL+Path.PAGE_NAME.Terms}>Terms of service</a></li>
                            <li><a href={Path.BASE_URL+Path.PAGE_NAME.Sitemap}>Sitemap</a></li>
                            <li><a href={Path.BASE_URL+Path.PAGE_NAME.Careers}>Careers</a></li>
                            <li><a target="_blank" href={Path.BASE_URL+Path.PAGE_NAME.Blog}>Blog</a></li>
                            <li><a href={Path.BASE_URL+Path.PAGE_NAME.PrivacyPolicy}>Privacy policy</a></li>
                            <li><a href={Path.BASE_URL+Path.PAGE_NAME.Contactus}>Contact us</a></li>
                        </ul>
                      </div>
                  </div>
            </div>
        );
    }
}
export default Sitemap;