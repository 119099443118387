import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import {reactLocalStorage} from 'reactjs-localstorage';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond-polyfill';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import 'filepond/dist/filepond.min.css';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import Path from './Path';
import { async } from 'q';
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileRename);
class About extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
            name:'',
            email:'',
            message:'',
            resumeName:new Date().getTime()
        }
        this.submitData = this.submitData.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }  
    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    submitData(){
        var _this = this;
        axios({
            method: 'post',
            url: Path.USER_URL + Path.URL.CAREERS,
            data:{
                name:_this.state.name,
                email:_this.state.email,
                message:_this.state.message,
                resume:_this.state.resumePath
            }
        })
        .then(function (response) {
            confirmAlert({
                title: 'Your details submitted successfully. We will contact you as soon as possible.',
                buttons: [
                {
                    label: 'Ok',
                    onClick: () =>{
                        setTimeout(() => {
                            window.location = Path.BASE_URL+Path.PAGE_NAME.Contactus;
                        },1000);
                    }
                }
                ]
            });
        })
        .catch(function (error) {
            //console.log(error);
        });
    }
    componentDidMount() {
        setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    render() {
        return (
           <div className="careerBg careersTopSpace">
               <div className="careersec">
                <div className="container">
                        <div className="col-md-6 col-xs-12">
                            <h3 className="">WE HELP OUR EMPLOYEES GROW</h3>
                            <p>Professional Development and Training</p>
                            <p>We are committed to the continuous professional development of our employess and wherever possiable, we will provide essential training in topic areas that will contribute to an individuals personal and professional development.</p>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <h3 className="">RECRUITMENT OPPORTUNITIES</h3>
                            <p>Do You Have What is Takes?</p>
                            <p>at Weborro we pride oursolves on continuously delivering the best user experience for our customer on this unique platform. We are continuously creating technical solutions to aid this porpertual aycle of excellence. We aim to acquire skilled create individual that highly trained to join our team is paramount.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 text-center careerHead">
                    <h4>HOW TO APPLY</h4>
                    <p>Careers With Weborro</p>
                </div>
                <div className="contactForm">
                    <div className="container">                        
                        <div className="col-md-12 col-xs-12 no-padding">
                            <form className="col-xs-12 col-sm-6">
                                <div className="form-group">
                                    <label className="control-label">Name : </label>
                                    <input type="text" className="form-control" placeholder="Enter Your Name" onChange={e => this.handleChange(e)} value={this.state.name} id="name" name="name" />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Email address : </label>
                                    <input type="text" className="form-control" placeholder="Enter Your Email" onChange={e => this.handleChange(e)} value={this.state.email} id="email" name="email" />
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Your message :</label>
                                    <textarea className="form-control text-form-control" placeholder="Enter Your Message" onChange={e => this.handleChange(e)} value={this.state.message} id="message" name="message" >
                                    </textarea>
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Upload CV  : </label>
                                    <FilePond 
                                        allowMultiple={false}
                                        ref={ref => this.pond = ref} 
                                        acceptedFileTypes ={ ['application/pdf','application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
                                        server={Path.SITE_URL+Path.BASE_URL+'/'+Path.UPLOAD_CV}         
                                        onupdatefiles={(fileItems) => {
                                            this.setState({
                                                files: fileItems.map(fileItem => fileItem.file)
                                            });
                                        }}
                                        fileRenameFunction={(file) => {
                                            this.setState({resume:`weborro_${this.state.resumeName+file.extension}`}, () => {
                                                this.setState({resumePath:`${Path.SITE_URL+Path.BASE_URL+'/docs/'+this.state.resume}`});
                                                //return this.state.resume;
                                            })  
                                            return `weborro_${this.state.resumeName+file.extension}`;                                                                              
                                        }}
                                    />
                                </div>
                                <span className="submitBtn pointer text-center" onClick={e => this.submitData(e)}>Submit</span>
                            </form>
                            <div className="careers_right_section">
                                <img src={Path.IMAGE_PATH+"/carrer-banner.png"} className="carrer-img"/>
                            </div>
                        </div>
                    </div>
                </div>           
                <div className="howitsbg">
                   <div className="container">
                        <div className="howitsbgCont"><span>Weborro</span> because borrowing is better </div>
                   </div>
                </div>
           </div>
        );
    }
}
export default About;