import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import ReactSVG from 'react-svg';
import 'react-dates/initialize';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import createFragment from 'react-addons-create-fragment';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import {Image, Video, Transformation, CloudinaryContext} from 'cloudinary-react';
import {reactLocalStorage} from 'reactjs-localstorage';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ImageGallery from 'react-image-gallery';
import {Elements, StripeProvider, CardElement, injectStripe} from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';
import 'react-perfect-scrollbar/dist/css/styles.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "react-image-gallery/styles/css/image-gallery.css";
import Slider from "react-slick";
import {TweenMax,TimelineMax,TimelineLite,TweenLite,Elastic,Sine,bbox,center,Power1,Circ} from 'gsap';
import Path from './Path';
const moment = extendMoment(Moment);
class ProductDetails extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
			auth_token : reactLocalStorage.get('auth_token'),
			product_image_slide:[],
			total_days:'0',
			service_fee:'',
			profile_image:''
        }
		this.loadData = this.loadData.bind(this);
		this.datenth = this.datenth.bind(this);
		this.formatDate = this.formatDate.bind(this);
	} 
    loadData(){
        var _this = this;        
        axios({
            method: 'get',
            url: Path.PRODUCT_URL + Path.URL.GET_BORROWED_ITEM_DETAILS+'/'+Path.PRODUCT_ID,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {            
            _this.setState({
				name:response.data.product.borrower.name,
				email:response.data.product.borrower.email,
                product_name:response.data.product.product_name,
                description:response.data.product.description,
                price_day:response.data.product.price_day,
                price_week:response.data.product.price_week,
                pickup:response.data.product.pickup,
                product_worth:response.data.product.product_worth,
				delivery:response.data.product.delivery,
				postcode:response.data.product.postcode,
                cat_id:response.data.product.cat_id,
                subcat_id:response.data.product.subcat_id,
                sibling_id:response.data.product.sibling_id,
                product_image:response.data.product.product_image,
                delivery_area:response.data.product.delivery_area,
				product_image_slide:response.data.product.product.product_image_slide,
				city:response.data.product.city,
				available_date_status:response.data.product.available_date_status,
				available_date:response.data.product.available_date,
				total_days:response.data.product.total_days,
				service_fee:response.data.product.service_fee,
				delivery_charge:response.data.product.delivery_charge,
				product_name:response.data.product.product.product_name,
				rented_date:_this.formatDate(response.data.product.rented_date),
				returned_date:_this.formatDate(response.data.product.returned_date),
				profile_image:response.data.product.product.user.profile_image
			}, () => {
			});
        })
        .catch(function (error) {
            //console.log(error);
		});
	}
	datenth(d) {
		if(d>3 && d<21) return 'th';
		switch (d % 10) {
				case 1:  return "st";
				case 2:  return "nd";
				case 3:  return "rd";
				default: return "th";
			}
	} 
	formatDate(date){
		var splitDate = date.split("-");
		var fortnightAway = new Date(splitDate[0], splitDate[1] - 1, splitDate[2]), dateNew = fortnightAway.getDate(),
		month = "January,February,March,April,May,June,July,August,September,October,November,December".split(",")[fortnightAway.getMonth()];
		return dateNew+this.datenth(dateNew)+" "+month;
	}
    componentDidMount() {
		this.loadData();	
		setTimeout(() =>
            this.props.handler(false)
        , 2000);	
    }
    render(){		
        return(
            <div className="main_wrapper">
				<section className="menu_bg">
					<div className="container">
						<div className="col-md-6 col-xs-12 menu_bg_left">
							<div className="col-xs-12 no-padding">
								<div className="menu_pro_items col-xs-12 no-padding">
									<h3 className="ho_renshop_sH">{this.state.product_name}</h3>
								</div>
								<div className="menu_pro_tabCont">
									<div className="col-xs-12 no-padding">
										<ImageGallery items={this.state.product_image_slide} />
									</div>
								</div>
							</div>						
						</div>
						<div className="col-md-6 col-xs-12 menu_bg_right">
							<div className="menu_bg_right_rentBor">
								<div className="mess_leder_pro_dea">
									<div className="mess_leder_pro_deaImg">
										<img className="" src={this.state.profile_image} alt="profile image" onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_NEW_IMAGE}}/>
									</div>
									<div className="mess_leder_pro_deaName">
										<h4>{this.state.name}</h4>
										<span>{this.state.city}</span>
									</div>
								</div>
								<div className="col-xs-12 no-padding check_date_show">
									<div className="check_date_Startshow">
										<span>{this.state.rented_date}</span>
									</div>
									<span className="check_date_Arrowshow"><img src={Path.IMAGE_PATH+"/check_arrow.png"}/></span>
									<div className="check_date_Endshow">
										<span>{this.state.returned_date}</span>
									</div>
								</div>
								<div className="menu_bg_right_rentDea">
									<div className="menu_bg_right_rentDea_am">
										<div className="menu_bg_right_rentDea_amL">£ {this.state.price_day} x {this.state.total_days} Days:</div>
										<div className="menu_bg_right_rentDea_amR">£ {Number(this.state.price_day) * Number(this.state.total_days)}</div>
									</div>
									{this.state.delivery_charge !== '' &&
									<div className="menu_bg_right_rentDea_am">
										<div className="menu_bg_right_rentDea_amL">Delivery Fee:</div>
										<div className="menu_bg_right_rentDea_amR">£ {this.state.delivery_charge}</div>
									</div>
									}
									<div className="menu_bg_right_rentDea_am menu_bg_right_rentDea_amT check_total">
										<div className="menu_bg_right_rentDea_amL check_total_left">Total Price:</div>
										<div className="menu_bg_right_rentDea_amR check_total_right">£ {Number(this.state.delivery_charge) + (Number(this.state.price_day) * Number(this.state.total_days))}</div>
									</div>
								</div>
							</div>
							<div className="check_right_text m-t-20">You are liable for any loss or damages you cause to the item. <a href="">Read more.</a></div>
							<div className="check_right_text">You cannot cancel within 48 hours of the start of the rental. <a href="">See cancellation policy.</a></div>
						</div>
					</div>
				</section>
			</div>
        );
    }
}
export default ProductDetails;