import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import axios from 'axios';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import {reactLocalStorage} from 'reactjs-localstorage';
import { CookieBanner } from '@palmabit/react-cookie-law';
import {Helmet} from "react-helmet";
import Path from './views/Path';
import Header from './Header';
import Footer from './Footer';
import Home from './views/Home';
import MyAccount from './views/MyAccount';
import MyItems from './views/MyItems';
import ProductAdd from './views/ProductAdd';
import ProductEdit from './views/ProductEdit';
import ProductDetails from './views/ProductDetails';
import NotFound from './views/NotFound';
import Sidebar from './views/Sidebar';
import Search from './views/Search';
import About from './views/About';
import MyRentals from './views/MyRentals';
import Watchlist from './views/Watchlist';
import BorrowProductDetails from './views/BorrowProductDetails';
import Calender from './views/Calender';
import Toolkit from './views/Toolkit';
import Analytics from './views/Analytics';
import Notifications from './views/Notifications';
import Verifications from './views/Verifications';
import Messages from './views/Messages';
import MessagesWrapper from './views/MessagesWrapper';
import MessageToLender from './views/MessageToLender';
import PaymentLoader from './views/PaymentLoader';
import BuyProduct from './views/BuyProduct';
import MyCards from './views/MyCards';
import Invoice from './views/Invoice';
import LendInvoice from './views/LendInvoice';
import ViewProfile from './views/ViewProfile';
import Careers from './views/Careers';
import Contactus from './views/Contactus';
import Howitworks from './views/Howitworks';
import PrivacyPolicy from './views/PrivacyPolicy';
import Terms from './views/Terms';
import Sitemap from './views/Sitemap';
import Faq from './views/Faq';
import logo from './logo.png';

class App extends Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: true,
      facebook_url:'',
      twitter_url:'',
      instagram_url:'',
      meta_title:'',
      meta_keyword:'',
      meta_description:''
    }
    this.siteSettings = this.siteSettings.bind(this);
    this.loadData = this.loadData.bind(this);
    this.verfiyAuthToken = this.verfiyAuthToken.bind(this);
    this.handler = this.handler.bind(this);
  }
  handler(someValue) {
    this.setState({
      loading: someValue
    })
  }
  verfiyAuthToken(){  
    var _this = this;  
    if(reactLocalStorage.get('auth_token') !== undefined && reactLocalStorage.get('auth_token') !== ''){
        axios({
            method: 'post',
            url: Path.USER_URL + Path.URL.AUTH_TOKEN_VERFIY,
            data: {
                "user_id":reactLocalStorage.get('user_id'),
                "auth_token":reactLocalStorage.get('auth_token')
            }
        })
        .then(function (response) {
            if(response.data.success == '0'){
              _this.setState({loggedUser:false});
              axios({
                  method: 'get',
                  url: Path.USER_URL+Path.URL.LOGOUT,
                  headers: {
                      'Api-Token':'Bearer '+_this.state.auth_token
                  }
              })
              .then(function (response) {
                  reactLocalStorage.set('auth_token','');
                  reactLocalStorage.set('user_id','');

                  window.location = Path.BASE_URL+Path.PAGE_NAME.Root;
              })
              .catch(function (error) {
                  reactLocalStorage.set('auth_token','');
                  reactLocalStorage.set('user_id','');
                  window.location = Path.BASE_URL+Path.PAGE_NAME.Root;
              });
            }
            if(response.data.success == '1'){
              _this.setState({loggedUser:true});
            }
        })
        .catch(function (response) {
            //console.log(error);
            //_this.loginModal();
        });  
    }
    else{
      _this.setState({loggedUser:false});
      if(window.location.pathname !== Path.PAGE_NAME.Root && window.location.pathname.indexOf('myaccount') !== -1){
          
          window.location = Path.BASE_URL+Path.PAGE_NAME.Root;
      }
    }
  }
  siteSettings(){
    var _this = this;
    axios({
        method: 'get',
        url: Path.USER_URL+Path.URL.SITE_SETTINGS
    })
    .then(function (response) {
        _this.setState({
            site_name:response.data.setting.site_name,
            site_logo:response.data.setting.site_logo,
            site_fav_icon:response.data.setting.site_fav_icon,
            site_email:response.data.setting.site_email,
            site_address:response.data.setting.site_address,
            site_mobile_number:response.data.setting.site_mobile_number,
            service_fee:response.data.setting.service_fee,
            stripe_client_id:response.data.setting.stripe_client_id,
            stripe_api_key:response.data.setting.stripe_api_key,
            stripe_secret_key:response.data.setting.stripe_api_key,
            facebook_url:response.data.setting.facebook_url,
            twitter_url:response.data.setting.twitter_url,
            instagram_url:response.data.setting.instagram_url,
            meta_title:response.data.setting.meta_title,
            meta_keyword:response.data.setting.meta_keyword,
            meta_description:response.data.setting.meta_description
        });
    })
    .catch(function (error) {
        //console.log(error);
    });
  }
  loadData(){
    if(window.location.pathname == Path.BASE_URL+Path.PAGE_NAME.ProductAdd || ('/'+window.location.pathname.split("/")[1]+'/'+window.location.pathname.split("/")[2]) == (Path.BASE_URL+Path.PAGE_NAME.ProductEdit)){
      window.loadGoogle();
    }
  }
  componentDidMount() {
    this.loadData();
    this.siteSettings();  
    this.verfiyAuthToken();
  }
  render() {
    const { loading } = this.state;
    return (
        <div>     
          <Helmet>
            <title>{this.state.meta_title}</title>
            <meta name="keywords" content={this.state.meta_keyword}/>
            <meta name="description" content={this.state.meta_description}/>
          </Helmet>
          <CookieBanner
            message='We use cookies to help our site work, to understand how it is used, and to tailor the adverts presented on our site. By clicking "Accept" below, you agree to us doing so. You can read more in our cookie notice. Or, if you do not agree, you can click Manage below to access other choices.'
            showDeclineButton = {true}
            showPreferencesOption = {false}
            showMarketingOption = {false}
            showStatisticsOption = {false}
          />
          {this.state.loading === true &&
          <div className="loading_image_wrapper">
            <div className="loading_image_inner">
              <img src={logo}/>
              <p className="loading_text_contFirst">Loading... </p>
              {/*<p className="loading_text_cont"> Please wait while we redirect you</p>*/}
            </div>
          </div>
          }
          <Router>{/*<Router basename='/frontend'>*/}
            <div>              
              {window.location.pathname != Path.BASE_URL+Path.PAGE_NAME.PaymentLoader &&
              <Header
                site_name={this.state.site_name}
                site_logo={this.state.site_logo}
                site_fav_icon={this.state.site_fav_icon}
                site_email={this.state.site_email}
                site_address={this.state.site_address}
                site_mobile_number={this.state.site_mobile_number}
                service_fee={this.state.service_fee}
                stripe_client_id={this.state.stripe_client_id}
              />
              }
              <Switch>
                <Route exact={true} path={Path.PAGE_NAME.Root} render={(props) => <Home {...props} loggedUser={this.state.loggedUser} handler={this.handler} />}  />
                <Route exact={true} path={Path.PAGE_NAME.Home} render={(props) => <Home {...props} loggedUser={this.state.loggedUser} handler={this.handler} />}  />
                <Route exact={true} path={Path.PAGE_NAME.MyAccount} render={(props) => <MyAccount {...props} stripe_client_id={this.state.stripe_client_id} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.ProductAdd} render={(props) => <ProductAdd {...props} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.ProductEdit+'/:productid'} render={(props) => <ProductEdit {...props} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.MyItems} render={(props) => <MyItems {...props} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.MyCards} render={(props) => <MyCards {...props} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.MyRentals} render={(props) => <MyRentals {...props} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.Watchlist} render={(props) => <Watchlist {...props} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.Search} render={(props) => <Search {...props} logo={this.state.site_logo} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.NotFound} render={(props) => <NotFound {...props} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.About} render={(props) => <About {...props} loggedUser={this.state.loggedUser} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.ProductDetails+'/:productid'} render={(props) => <ProductDetails {...props} stripe_secret_key={this.state.stripe_secret_key} stripe_api_key={this.state.stripe_api_key} service_fee={this.state.service_fee} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.BuyProduct+'/:productid'} render={(props) => <BuyProduct {...props} stripe_secret_key={this.state.stripe_secret_key} stripe_api_key={this.state.stripe_api_key} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.BorrowProductDetails+'/:productid'} render={(props) => <BorrowProductDetails {...props} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.Analytics} render={(props) => <Analytics {...props} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.Notifications} render={(props) => <Notifications {...props} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.Verifications} render={(props) => <Verifications {...props} stripe_client_id={this.state.stripe_client_id} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.Toolkit} render={(props) => <Toolkit {...props} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.Calender} render={(props) => <Calender {...props} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.Messages} render={(props) => <Messages {...props} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.MessagesWrapper+'/:conversationId'} render={(props) => <MessagesWrapper {...props} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.MessageToLender+'/:productid'} render={(props) => <MessageToLender {...props} stripe_api_key={this.state.stripe_api_key} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.PaymentLoader} render={(props) => <PaymentLoader {...props} loggedUser={this.state.loggedUser} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.Invoice+'/:productid'} render={(props) => <Invoice {...props} logo={this.state.site_logo} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.LendInvoice+'/:productid'} render={(props) => <LendInvoice {...props} logo={this.state.site_logo} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.ViewProfile+'/:lenderid'} render={(props) => <ViewProfile {...props} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.Careers} render={(props) => <Careers {...props} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.Contactus} render={(props) => <Contactus {...props} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.Faq} render={(props) => <Faq {...props} handler={this.handler} />} />
                <Route exact={true} path={Path.PAGE_NAME.Howitworks} render={(props) => <Howitworks {...props} handler={this.handler} />} /> 
                <Route exact={true} path={Path.PAGE_NAME.Terms} render={(props) => <Terms {...props} handler={this.handler} />} /> 
                <Route exact={true} path={Path.PAGE_NAME.Sitemap} render={(props) => <Sitemap {...props} handler={this.handler} />} /> 
                <Route exact={true} path={Path.PAGE_NAME.PrivacyPolicy} render={(props) => <PrivacyPolicy {...props} handler={this.handler} />} />                
              </Switch>
              <Footer
                site_name={this.state.site_name}
                site_logo={this.state.site_logo}
                site_fav_icon={this.state.site_fav_icon}
                site_email={this.state.site_email}
                site_address={this.state.site_address}
                site_mobile_number={this.state.site_mobile_number}
                service_fee={this.state.service_fee}
                stripe_client_id={this.state.stripe_client_id}
                facebook_url={this.state.facebook_url}
                twitter_url={this.state.twitter_url}
                instagram_url={this.state.instagram_url}
              />
              </div>
          </Router>   
        </div>
    );
  }
}
export default App;