import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {Image, Video, Transformation, CloudinaryContext} from 'cloudinary-react';
import {TweenMax,TimelineMax,TimelineLite,TweenLite,Elastic,Sine,bbox,center,Power1,Circ} from 'gsap';
import {reactLocalStorage} from 'reactjs-localstorage';
import Path from './Path';
import Sidebar from './Sidebar';
class MyAccount extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
            auth_token : reactLocalStorage.get('auth_token'),
            address: '',
            addressLabel:' ',
            editProfile:false,
            profile_image:Path.IMAGE_PATH+"/profile.png",
            profile_image_upload:'Upload Profile Image',
            editcity: " ",
            invoice_period:'15',
            showError:false,
            bio_info:'',
            stripeAccount:'',
            profile_status:'0',
            profile_info_status:'0',
            current_pwd:'',
            new_pwd:'',
            confirm_new_pwd:'',
            social_login:'0',
            create_new_pwd:'',
            create_confirm_new_pwd:'',
            receive_notification:'No',
            receive_promotion:'No',

            //Bank Details
            editBankDetails:false,
            bank_name:'',
            account_holder_name:'rrrrrr',
            sort_code:'',
            account_number:''
        }
        this.handleChange = this.handleChange.bind(this);
        this.loadData = this.loadData.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.editProfile = this.editProfile.bind(this);
        this.clicktoeditProfile = this.clicktoeditProfile.bind(this);
        this.profileImageUpload = this.profileImageUpload.bind(this);
        this.getUserData = this.getUserData.bind(this);
        this.animationHands = this.animationHands.bind(this);
        this.recieveCheckbox = this.recieveCheckbox.bind(this);
        this.handlePostCodeChange = this.handlePostCodeChange.bind(this);
        this.disconnectStripe = this.disconnectStripe.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.createPassword = this.createPassword.bind(this);
    }
    handleChange(e) {
        this.setState({
           [e.target.name]: e.target.value
        });
        $('.error_message').html('');
    }
    handleLocationChange(editcity){
        this.setState({ editcity });
    }
    createPassword(){
        var _this = this; 
        if(_this.state.create_new_pwd == ''){
            $('#create_new_pwd').html('Enter New Password');
        }
        else if(_this.state.create_confirm_new_pwd == ''){
            $('#create_confirm_new_pwd').html('Enter Confirm Password');
        }
        else{
            axios({
                method: 'post',
                url: Path.USER_URL + Path.URL.CREATE_PASSWORD,
                headers: {
                    'Api-Token':'Bearer '+_this.state.auth_token
                },
                data: {
                    "password":_this.state.create_new_pwd,
                    "cnfm_password":_this.state.create_confirm_new_pwd,
                    "type":"web"           
                }
            })
            .then(function (response) {
                if(response.data.success == '1'){
                    _this.setState({showError:'success'}, () => {
                        $("#success_msg").html(response.data.message);
                        setTimeout(() => {
                            _this.setState({showError:''});
                        },2000);
                    });
                }
                else{
                    _this.setState({showError:'error'}, () => {
                        $("#error_msg").html(response.data.message);   
                        setTimeout(() => {
                            _this.setState({showError:''});
                        },2000);            
                    });
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
        } 
    }
    changePassword(){
        var _this = this;
        if(_this.state.current_pwd == ''){
            $('#current_pwd').html('Enter Current Password');
        }
        else if(_this.state.new_pwd == ''){
            $('#new_pwd').html('Enter New Password');
        }
        else if(_this.state.confirm_new_pwd == ''){
            $('#confirm_new_pwd').html('Enter Confirm Password');
        }
        else{
            axios({
                method: 'post',
                url: Path.USER_URL + Path.URL.CHANGE_PASSWORD,
                headers: {
                    'Api-Token':'Bearer '+_this.state.auth_token
                },
                data: {
                    "current_password":_this.state.current_pwd,
                    "password":_this.state.new_pwd,
                    "cnfm_password":_this.state.confirm_new_pwd                
                }
            })
            .then(function (response) {
                if(response.data.success == '1'){
                    _this.setState({showError:'success'}, () => {
                        $("#success_msg").html(response.data.message);
                        setTimeout(() => {
                            _this.setState({showError:''});
                        },2000);
                    });
                }
                else{
                    _this.setState({showError:'error'}, () => {
                        $("#error_msg").html(response.data.message);   
                        setTimeout(() => {
                            _this.setState({showError:''});
                        },2000);            
                    });
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
        }
    }

    editProfile(){
        var _this = this;
        axios({
            method: 'post',
            url: Path.USER_URL + Path.URL.UPDATE_PROFILE,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            },
            data: {
                "name":_this.state.editname,
                "lastname":_this.state.editlastname,
                "profile_image":_this.state.profile_image,
                "mobile_number":_this.state.editmobile_number,
                "invoice_period":_this.state.editinvoice_period,
                "city":_this.state.editcity,
                "latitude":_this.state.latitude,
                "longitude":_this.state.longitude,
                "postcode":_this.state.postcode,
                "bio_info":_this.state.bio_info,
                "receive_notification":_this.state.receive_notification,
                "receive_promotion": _this.state.receive_promotion,
                "address_line1":_this.state.address_line1,
                "address_line2":_this.state.address_line2
            }
        })
        .then(function (response) {
            _this.getUserData();
            _this.setState({editProfile:false});
        })
        .catch(function (error) {
            //console.log(error);
        });
    }


    //------------Bank details
    changeBankDetails = () => {
        var _this = this;
        axios({
            method: 'post',
            url: Path.USER_URL + Path.URL.UPDATE_BANK_DETAILS,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            },
            data: {
                "bank_name":_this.state.bank_name,
                "account_holder_name":_this.state.account_holder_name,
                "sort_code":_this.state.sort_code,
                "account_number":_this.state.account_number,
            }
        })
        .then(function (response) {
            //_this.getUserData();
            _this.setState({editBankDetails:false});
        })
        .catch(function (error) {
            //console.log(error);
        });
    }
    clickToEditBankDetails = () =>{
        this.setState({editBankDetails:true});
    }
    //-------------------------


    handlePostCodeChange(e){
        this.setState({
            showError:'',
            [e.target.name]: e.target.value
        });
        let postcode = e.target.value;
        if(postcode.replace(/\s/g,'').length >=3){
            var _this = this;
            axios({
                method: 'get',
                url: Path.POST_CODE_API+postcode+'/validate'
            })
            .then(function (response) {
                if(response.data.result){
                    _this.setState({
                        postcode:postcode
                    });
                    axios({
                        method: 'get',
                        url: Path.POST_CODE_API+postcode
                    })
                    .then(function (response) {
                        _this.setState({postcodeCheck:true,latitude:response.data.result.latitude,longitude:response.data.result.longitude,location:response.data.result.region});
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
                }
                else{
                    _this.setState({showError:'error'}, () => {
                        $("#error_msg").html('Invalid Postcode');               
                    });
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
        }
    } 
    profileImageUpload(){
        var widget = window.cloudinary.openUploadWidget({
            cloudName: Path.CLOUDINARY.cloudName,
            apiKey: Path.CLOUDINARY.apiKey,
            uploadPreset: Path.CLOUDINARY.uploadPreset,
            folder:Path.CLOUDINARY.folder,
            cropping: true,
            multiple: false,
            sources:['local','url'],
            croppingAspectRatio:'1',
            croppingDefaultSelectionRatio:'1.0',
            croppingShowDimensions:true,
            maxFileSize:'10000000',
            croppingValidateDimensions:true,
            styles:{
                palette: {
                    window: "#FFF",
                    windowBorder: "#90A0B3",
                    tabIcon: "#0E2F5A",
                    menuIcons: "#5A616A",
                    textDark: "#000000",
                    textLight: "#FFFFFF",
                    link:  "#0078FF",
                    action:  "#FF620C",
                    inactiveTabIcon: "#0E2F5A",
                    error: "#F44235",
                    inProgress: "#0078FF",
                    complete: "#20B832",
                    sourceBg: "#E4EBF1"
                },
                fonts: {
                    "Lato": "https://fonts.googleapis.com/css?family=Lato:400,700,900,900i",
                }
            }
        }, (error, result) => {
            if (result && result.event === "success") {
                console.log(result);
                //widget.close({quiet: true});
                if(typeof(result.info.coordinates) != 'undefined'  || result.info.coordinates != null){
                    let imageurl = "http://res.cloudinary.com/"+Path.CLOUDINARY.cloudName+"/image/upload/x_"+result.info.coordinates.custom[0][0]+",y_"+result.info.coordinates.custom[0][1]+",w_"+result.info.coordinates.custom[0][2]+",h_"+result.info.coordinates.custom[0][3]+",c_crop/"+result.info.path+"";
                    //$("#profile_image_view").attr("src",imageurl);
                    //$("#profile_image").val(imageurl);   
                    this.setState({profile_image:imageurl});
                }
                else{
                    //$("#profile_image_view").attr("src",result.info.secure_url);
                    //$("#profile_image").val(result.info.secure_url);
                    this.setState({profile_image:result.info.secure_url});
                }                
            }
        });
    }
    clicktoeditProfile(){
        this.setState({editProfile:true});
    }
    handleSelect(address){
        this.setState({'address':address});
        this.setState({'editcity':address.substring(0, address.length-4)});
        geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
            reactLocalStorage.set('getLocation',this.state.editcity);
            reactLocalStorage.set('getLat',lat);
            reactLocalStorage.set('getLng',lng);
            this.setState({latitude:lat,longitude:lng,city:this.state.editcity});
        });
    }
    animationHands(){       
        var iconAnimation4 = new TimelineMax({
            repeat: -1,
            repeatDelay: 1
        });            
        iconAnimation4
        .fromTo('#icon-4 #window', 2, {y: 4}, {y: 0, repeat: -1, yoyo: true, ease: Sine.easeInOut})
        .to('#icon-4 #head', 2, {rotation: '-8deg', svgOrigin: '97.8px 124.2px', repeat: -1, yoyo: true, ease: Sine.easeInOut}, 0)
        .to('#icon-4 #right-arm', 1, {rotation: '10deg', svgOrigin: '102.2px 129.8px', repeat: -1, yoyo: true, repeatDelay: 1, ease: Sine.easeInOut}, 0)
        .fromTo('#icon-4 #progress-bar', 2.5, {x: -5, y:-3}, {x: 5, y:3, repeat: -1, yoyo: true, repeatDelay: 1, ease: Sine.easeInOut}, 0);
    }
    componentDidMount() {
        this.getUserData();
        this.loadData();
        if(reactLocalStorage.get('auth_token') === undefined){
            window.location = Path.BASE_URL+Path.PAGE_NAME.Home;
        }
        setTimeout(() => {
            this.animationHands();
        },2000);
        setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    loadData(){

    }    
    recieveCheckbox(e){
        var _this = this;
        _this.setState({
           [e.target.name]: e.target.value
        });
        if(e.target.checked){
            if(e.target.name == 'receive_notification'){
                _this.setState({receive_notification:'Yes'});
            }
            if(e.target.name == 'receive_promotion'){
                _this.setState({receive_promotion:'Yes'});
            }
            if(e.target.name == 'holiday'){
                _this.setState({holiday:'No'} , () => {
                    axios({
                        method: 'post',
                        url: Path.USER_URL + Path.URL.HOLIDAY_STATUS,
                        headers: {
                            'Api-Token':'Bearer '+_this.state.auth_token
                        },
                        data: {
                            "holiday":_this.state.holiday
                        }
                    })
                    .then(function (response) {
                        
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
                });
            }          
        }
        else{
            if(e.target.name == 'receive_notification'){
                _this.setState({receive_notification:'No'});
            }
            if(e.target.name == 'receive_promotion'){
                _this.setState({receive_promotion:'No'});
            }
            if(e.target.name == 'holiday'){
                _this.setState({holiday:'Yes'} , () => {
                    axios({
                        method: 'post',
                        url: Path.USER_URL + Path.URL.HOLIDAY_STATUS,
                        headers: {
                            'Api-Token':'Bearer '+_this.state.auth_token
                        },
                        data: {
                            "holiday":_this.state.holiday
                        }
                    })
                    .then(function (response) {
                        
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
                });
            }
        }
    }   
    disconnectStripe(){
        var _this = this;
        axios({
            method: 'get',
            url: Path.USER_URL + Path.URL.DISCONNECT_STRIPE,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {
            _this.setState({stripeAccount:''}, () => {

            });
        })
        .catch(function (error) {
            //console.log(error);
        });
    } 
    getUserData(){
        var _this = this;
        axios({
            method: 'get',
            url: Path.USER_URL+Path.URL.GET_USER_DETAILS,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {
            if(response.data.success == "1"){
                _this.setState({
                    name:response.data.customer.name,
                    lastname:response.data.customer.lastname,
                    role:response.data.customer.role,
                    email:response.data.customer.email,
                    mobile_number:response.data.customer.mobile_number,
                    profile_image:response.data.customer.profile_image,
                    city:response.data.customer.city,
                    invoice_period:response.data.customer.invoice_period,
                    editcity: response.data.customer.city,
                    receive_notification:response.data.customer.receive_notification,
                    receive_promotion:response.data.customer.receive_promotion,
                    holiday:response.data.customer.holiday_mode,
                    bio_info:response.data.customer.bio_info,
                    postcode:response.data.customer.postcode,
                    address_line1:response.data.customer.address_line1,
                    address_line2:response.data.customer.address_line2,
                    stripeAccount:response.data.customer.stripe_account_id,
                    profile_status:response.data.customer.profile_status,
                    profile_info_status:response.data.customer.profile_info_status,
                    social_login:response.data.customer.social_login,

                    //bank details
                    bank_name:response.data.customer.bank_name,
                    account_holder_name:response.data.customer.account_holder_name,
                    sort_code:response.data.customer.sort_code,
                    account_number:response.data.customer.account_number
                })
            }
        })
        .catch(function (error) {
            //console.log(error);
        });
    }    
    render(){
        const renderInput = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
            <div className="autocomplete-root">
                <input className="form-control" {...getInputProps({
                    placeholder:"Location",
                    className:"form-control"
                })} />
                <ul className="autocomplete-dropdown-container">
                    {suggestions.map(suggestion => (                
                        <li {...getSuggestionItemProps(suggestion)} className="suggestion">
                            {suggestion.terms.length > 2 ? 
                                <span className="cityname1">{suggestion.terms[0].value}<span className="cityname2">, {suggestion.terms[1].value}</span></span>
                            : 
                            <span className="cityname1">{suggestion.terms[0].value}</span>
                            }
                        </li>
                    ))}
                </ul>
            </div>
        );
        // Limit the suggestions to show only cities in the US
        const searchOptions = {
        //types: ['(cities)'],
        types: ['geocode'], //For Home Page
        componentRestrictions: {country: "uk"}
        }
        return(
            <div>
                {this.state.showError === 'success' &&
                <div className="flashmessage show"><i className="fa fa-check"></i><span id="success_msg"></span></div>}
                {this.state.showError === 'error' &&
                <div className="flashmessage-error show-error"><i className="fa fa-close"></i><span id="error_msg"></span></div>
                }           
                <div className="main_wrapper">
                    <section className="dashboard_bg">
                        <Sidebar/>
                        <div className="content-wrapper">
                            <div className="content-height-wrapper">
                                <div className="tab-content">
                                    <div id="dashboard" className="tab-pane fade in active">
                                        <div className="col-xs-12 no-xs-padding">
                                            <div id="tab-1" className="tab-cont current">
                                                <div className="col-xs-12 no-padding">
                                                    <div className="col-md-5 col-xs-12 no-padding-xs dashboard_left">
                                                        {this.state.editProfile == false ? 
                                                        <div className="col-xs-12 no-padding" id="view_profile">
                                                            <div className="col-xs-12 dashboardHead">Profile</div>
                                                            <div className="col-xs-12">
                                                                <div className="my_account_pro">
                                                                {this.state.profile_image !== undefined && this.state.profile_image !== null && this.state.profile_image !== '' ?
                                                                <img onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_IMAGE}} src={this.state.profile_image}/>
                                                                :
                                                                <img src={Path.IMAGE_PATH+"/profile.jpg"} height="250" width="320"/>
                                                                }
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-12 my_account_right_info">
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">Name *:</span>
                                                                    <span className="pro_text">{this.state.name} {this.state.lastname}</span>
                                                                </div>
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">Email *:</span>
                                                                    <span className="pro_text">{this.state.email}</span>
                                                                </div>
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">Address Line 1 *:</span>
                                                                    <span className="pro_text">{this.state.address_line1}</span>
                                                                </div>
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">Address Line 2:</span>
                                                                    <span className="pro_text">{this.state.address_line2}</span>
                                                                </div>
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">City *:</span>
                                                                    <span className="pro_text">{this.state.city}</span>
                                                                </div>
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">Postcode *:</span>
                                                                    <span className="pro_text">{this.state.postcode}</span>
                                                                </div>                                                                
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">Country *:</span>
                                                                    <span className="pro_text">United Kingdom</span>
                                                                </div>   
                                                                {/*<div className="my_account_item">
                                                                    <span className="pro_label">Invoice Period *:</span>
                                                                    <span className="pro_text">{this.state.invoice_period} days</span>
                                                                </div>*/}
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">Telephone *:</span>
                                                                    <span className="pro_text">{this.state.mobile_number}</span>
                                                                </div>
                                                                <div className="my_account_item">
                                                                    {this.state.profile_info_status == '0' &&        
                                                                    <span className="connect_error"><a>Please complete your profile to start renting and selling.</a></span>
                                                                    }
                                                                    <a id="edit_my_profile" className="pro_text_btn" onClick={this.clicktoeditProfile}>Edit Profile</a>
                                                                </div>                                                                
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="col-xs-12 no-padding" id="view_profile">
                                                            <div className="col-xs-12 dashboardHead">Edit Profile</div>
                                                            <div className="col-xs-12"> 
                                                                <label className="my_account_pro">
                                                                    <input type="radio" className="hide_label" defaultChecked="checked" value={this.state.profile_image} id="profile_image" name="profile_image"/>
                                                                    {typeof(this.state.profile_image) != 'undefined'  && this.state.profile_image != null ?
                                                                        <img onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_IMAGE}} className="pull-left" src={this.state.profile_image} id="profile_image_view" width="320" height="250"/> :
                                                                        <img onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_IMAGE}} src={Path.IMAGE_PATH+"/profile.jpg"} className="pull-left" id="profile_image_view" width="320" height="250"/>
                                                                    }
                                                                    <span className="my_account_proEditIcon" id="profile_image_upload" onClick={this.profileImageUpload}><i className="fa fa-pencil"></i>Edit</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xs-12 my_account_right_info">
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">First Name *:</span>
                                                                    <span className="pro_text">
                                                                        <input type="text" placeholder="Name" className="form-control" name="editname" defaultValue={this.state.name} onChange={e => this.handleChange(e)}/>
                                                                    </span>
                                                                </div>
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">Last Name *:</span>
                                                                    <span className="pro_text">
                                                                        <input type="text" placeholder="Name" className="form-control" name="editlastname" defaultValue={this.state.lastname} onChange={e => this.handleChange(e)}/>
                                                                    </span>
                                                                </div>
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">Email *:</span>
                                                                    <span className="pro_text">{this.state.email}</span>
                                                                </div>
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">Address Line 1 *:</span>
                                                                    <span className="pro_text">
                                                                        <input type="text" placeholder="Address Line 1" className="form-control" name="address_line1" defaultValue={this.state.address_line1} onChange={e => this.handleChange(e)}/>
                                                                    </span>
                                                                </div>
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">Address Line 2:</span>
                                                                    <span className="pro_text">
                                                                        <input type="text" placeholder="Address Line 2" className="form-control" name="address_line2" defaultValue={this.state.address_line2} onChange={e => this.handleChange(e)}/>
                                                                    </span>
                                                                </div>
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">City *:</span>
                                                                    <span className="pro_text">
                                                                        {this.state.editcity !== null || this.state.editcity !== undefined ?
                                                                        <PlacesAutocomplete
                                                                            value={this.state.editcity}
                                                                            onChange={this.handleLocationChange}
                                                                            onSelect={this.handleSelect}
                                                                            // Pass the search options prop
                                                                            searchOptions={searchOptions}
                                                                            name="editcity"
                                                                        >
                                                                            {renderInput}
                                                                        </PlacesAutocomplete>
                                                                        :
                                                                        <PlacesAutocomplete
                                                                            value=""
                                                                            onChange={this.handleLocationChange}
                                                                            onSelect={this.handleSelect}
                                                                            // Pass the search options prop
                                                                            searchOptions={searchOptions}
                                                                            name="editcity"
                                                                        >
                                                                            {renderInput}
                                                                        </PlacesAutocomplete>
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">Postcode *:</span>
                                                                    <span className="pro_text">
                                                                        <input type="text" placeholder="Postcode" className="form-control" name="postcode" defaultValue={this.state.postcode} onChange={e => this.handlePostCodeChange(e)}/>
                                                                    </span>
                                                                </div>
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">Country:</span>
                                                                    <span className="pro_text">United Kingdom</span>
                                                                </div>
                                                                {/*<div className="my_account_item">
                                                                    <span className="pro_label">Invoice Period *:</span>
                                                                    <span className="pro_text">
                                                                        <select className="form-control" name="editinvoice_period" onChange={e => this.handleChange(e)} defaultValue={this.state.invoice_period}>
                                                                            <option value="15">15 days</option>
                                                                            <option value="30">30 days</option>
                                                                        </select>
                                                                    </span>
                                                                </div>*/}
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">Telephone *:</span>
                                                                    <span className="pro_text">
                                                                        <input type="text" placeholder="Mobile Number" name="editmobile_number" className="form-control" defaultValue={this.state.mobile_number} onChange={e => this.handleChange(e)}/>
                                                                    </span>
                                                                </div>
                                                                <div className="my_account_item">
                                                                    <a id="edit_my_profile" className="pro_text_btn" onClick={this.editProfile}>Save</a>
                                                                </div>
                                                            </div>
                                                        </div>   
                                                        }                                                     
                                                    </div>
                                                    <div className="col-md-7 col-xs-12 dashboard_right">
                                                        <div className="dashboardHead">Your bio</div>
                                                        <div className="my_account_item">
                                                            <textarea rows="6" className="my_account_yourself" id="bio_info" name="bio_info" className="form-control" value={this.state.bio_info || ''} onChange={e => this.handleChange(e)} placeholder="Tell your fellow users a bit about yourself">
                                                            </textarea>        
                                                        </div>  
                                                        <div className="my_account_item">
                                                            <a className="pro_text_btn" onClick={this.editProfile}>Save</a>
                                                        </div>   
                                                        <div className="dashboardHead">{this.state.social_login == '1' ? 'Create Password' : 'Change Password'}</div>
                                                        {this.state.social_login == '1' ? 
                                                        <div className="col-xs-12 my_account_right_info no-padding">
                                                            <div className="my_account_item pwd">
                                                                <span className="pro_label">New Password :</span>
                                                                <span className="pro_text">
                                                                    <input type="password" placeholder="Enter New Password" className="form-control" name="create_new_pwd" value={this.state.create_new_pwd} onChange={e => this.handleChange(e)}/>
                                                                </span>
                                                                <span id="create_new_pwd" className="error_message"></span>
                                                            </div>
                                                            <div className="my_account_item pwd">
                                                                <span className="pro_label">Confirm Password :</span>
                                                                <span className="pro_text">
                                                                    <input type="password" placeholder="Enter Confirm Password" className="form-control" name="create_confirm_new_pwd" value={this.state.create_confirm_new_pwd} onChange={e => this.handleChange(e)}/>
                                                                </span>
                                                                <span id="create_confirm_new_pwd" className="error_message"></span>
                                                            </div>
                                                            <div className="my_account_item">
                                                                <a id="edit_my_new_password" className="pro_text_btn" onClick={e => this.createPassword(e)}>Submit</a>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="col-xs-12 my_account_right_info no-padding">
                                                            <div className="my_account_item pwd">
                                                                <span className="pro_label">Current Password :</span>
                                                                <span className="pro_text">
                                                                    <input type="password" placeholder="Enter Current Password" className="form-control" name="current_pwd" value={this.state.current_pwd} onChange={e => this.handleChange(e)}/>
                                                                </span>
                                                                <span id="current_pwd" className="error_message"></span>
                                                            </div>
                                                            <div className="my_account_item pwd">
                                                                <span className="pro_label">New Password :</span>
                                                                <span className="pro_text">
                                                                    <input type="password" placeholder="Enter New Password" className="form-control" name="new_pwd" value={this.state.new_pwd} onChange={e => this.handleChange(e)}/>
                                                                </span>
                                                                <span id="new_pwd" className="error_message"></span>
                                                            </div>
                                                            <div className="my_account_item pwd">
                                                                <span className="pro_label">Confirm Password :</span>
                                                                <span className="pro_text">
                                                                    <input type="password" placeholder="Enter Confirm Password" className="form-control" name="confirm_new_pwd" value={this.state.confirm_new_pwd} onChange={e => this.handleChange(e)}/>
                                                                </span>
                                                                <span id="confirm_new_pwd" className="error_message"></span>
                                                            </div>
                                                            <div className="my_account_item">
                                                                <a id="edit_my_password" className="pro_text_btn" onClick={e => this.changePassword(e)}>Submit</a>
                                                            </div>
                                                        </div>
                                                        }                                           
                                                        {/*<div className="dashboardHead">Contact preferences</div>
                                                        <div className="my_account_contact">
                                                            <input id="receive_notification" checked={this.state.receive_notification == 'Yes' && 'checked'} type="checkbox" className="checkbox_swicth_input" name="receive_notification" onChange={e => this.recieveCheckbox(e)}/>
                                                            <label htmlFor="receive_notification" className="checkbox_swicth">
                                                                <span className="checkbox_swicth_wrapper">
                                                                    <span className="my_account_my_head">Receive notifications?</span>
                                                                    <span className="my_account_my_cont">These include Rental Requests and Confirmations, Messages and Review Notifications, and can’t be turned off.</span>
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div className="my_account_contact">
                                                            <input id="receive_promotion" checked={this.state.receive_promotion == 'Yes' && 'checked'} type="checkbox" className="checkbox_swicth_input" name="receive_promotion" onChange={e => this.recieveCheckbox(e)}/>
                                                            <label htmlFor="receive_promotion" className="checkbox_swicth">
                                                                <span className="checkbox_swicth_wrapper">
                                                                    <span className="my_account_my_head">Receive promotions</span>
                                                                    <span className="my_account_my_cont">Be the first to receive exclusive offers and hear weborro’s latest news.</span>
                                                                </span>
                                                            </label>
                                                        </div>*/}                                                        
                                                    </div>
                                                </div>

                                                {/*<div className="my_account_contact">
                                                    <div className="dashboardHead">Payout Account</div>
                                                    <div className="dashboardCont">This is where we'll send the money you earn lending on Weborro.</div>
                                                    {this.state.stripeAccount == '' || this.state.stripeAccount === undefined ?          
                                                    <div className="my_account_item">
                                                        <span className="connect_error"><a href={Path.CONNECT_STRIPE+this.props.stripe_client_id+'&scope=read_write'}>You won't be able to Rent or Sell until you create your online stripe account, click here now.</a></span>
                                                        <a className="pro_text_btn" href={Path.CONNECT_STRIPE+this.props.stripe_client_id+'&scope=read_write'}>Connect Stripe</a>
                                                    </div>
                                                    :
                                                    <div className="my_account_item">
                                                        <a className="pro_text_btn" onClick={e => this.disconnectStripe(e)}>Disconnect Stripe</a>
                                                    </div>
                                                    }
                                                </div>*/}



                                                <div className="col-xs-12 no-padding">


                                                    {/*<div className="col-md-6 col-xs-12 no-padding-xs dashboard_left">
                                                        {this.state.editBankDetails == false ?
                                                            <div className="col-xs-12 no-padding" id="view_profile">
                                                                <div className="col-xs-12 dashboardHead">Bank Details</div>
                                                                <div className="col-xs-12">

                                                                </div>
                                                                <div className="col-xs-12 my_account_right_info">
                                                                    <div className="my_account_item">
                                                                        <span className="pro_label">Name of Bank*:</span>
                                                                        <span className="pro_text">{this.state.bank_name}</span>
                                                                    </div>
                                                                    <div className="my_account_item">
                                                                        <span className="pro_label">Account Holder Name*:</span>
                                                                        <span className="pro_text">{this.state.account_holder_name}</span>
                                                                    </div>
                                                                    <div className="my_account_item">
                                                                        <span className="pro_label">Sort Code*:</span>
                                                                        <span className="pro_text">{this.state.sort_code}</span>
                                                                    </div>
                                                                    <div className="my_account_item">
                                                                        <span className="pro_label">Account Number*:</span>
                                                                        <span className="pro_text">{this.state.account_number}</span>
                                                                    </div>

                                                                    <div className="my_account_item">
                                                                        {this.state.profile_info_status == '0' &&
                                                                            <span className="connect_error"><a>Please complete your profile to start renting and selling.</a></span>
                                                                        }
                                                                        <a id="edit_bank_details" className="pro_text_btn" onClick={this.clickToEditBankDetails}>Edit Bank Details</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="col-xs-12 my_account_right_info no-padding">
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">Name of Bank*:</span>
                                                                    <span className="pro_text">
                                                                    <input type="text" placeholder="Enter name of bank" className="form-control"
                                                                           name="bank_name" value={this.state.bank_name} onChange={e => this.handleChange(e)}
                                                                    />
                                                                    </span>
                                                                    <span id="bank_name" className="error_message"></span>
                                                                </div>
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">Account Holder Name*:</span>
                                                                    <span className="pro_text">
                                                                    <input type="text" placeholder="Enter account holder name" className="form-control"
                                                                           name="account_holder_name" value={this.state.account_holder_name} onChange={e => this.handleChange(e)}
                                                                    />
                                                                    </span>
                                                                    <span id="account_holder_name" className="error_message"></span>
                                                                </div>
                                                                <div className="my_account_item">
                                                                    <span className="pro_label">Sort Code*:</span>
                                                                    <span className="pro_text">
                                                                    <input type="text" placeholder="Enter Sort Code" className="form-control"
                                                                           name="sort_code" value={this.state.sort_code} onChange={e => this.handleChange(e)}
                                                                    />
                                                                    </span>
                                                                    <span id="sort_code" className="error_message"></span>
                                                                </div>
                                                                <div className="my_account_item_account_number">
                                                                    <span className="pro_label">Account Number :</span>
                                                                    <span className="pro_text">
                                                                    <input type="text" placeholder="Enter Account Number" className="form-control"
                                                                           name="account_number" value={this.state.account_number} onChange={e => this.handleChange(e)}
                                                                    />
                                                                </span>
                                                                    <span id="account_number" className="error_message"></span>
                                                                </div>
                                                                <div className="my_account_item">
                                                                    <a id="edit_bank_details" className="pro_text_btn" onClick={e => this.changeBankDetails(e)}>Submit</a>
                                                                </div>


                                                            </div>
                                                        }
                                                    </div>*/}
                                                    <div className="col-md-12 col-xs-12 dashboard_left">

                                                        <div className="my_account_contact_" style={{
                                                            float: 'left',
                                                            width: '100%'
                                                        }}>
                                                            <div className="dashboardHead">Holiday mode</div>
                                                            <div className="dashboardCont">If you know you will be unable to manage your account for a while you can pause the entire account. This means your items will be paused so you don't hurt your lender rating with slow replies or rejected rentals.</div>
                                                            <div className="pull-left no-padding account_paused">Account paused</div>
                                                            <div className="m-lr-20 pull-left no-padding margin_top_15">
                                                                <input id="holiday" type="checkbox" checked={this.state.holiday == 'No' && 'checked'} className="checkbox_swicth_input" name="holiday" onChange={e => this.recieveCheckbox(e)}/>
                                                                <label htmlFor="holiday" className="checkbox_swicth"></label>
                                                            </div>
                                                            <div className="pull-left no-padding account_paused">Account live</div>
                                                        </div>
                                                    </div>

                                                </div>



                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div> 
        );
    }
}
export default GoogleApiWrapper({
    apiKey: (Path.API_LIST.GOOGLE_API_KEY)
})(MyAccount);