import React, {Component} from 'react';
import PropTypes from 'prop-types';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import axios from 'axios';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {Image, Video, Transformation, CloudinaryContext} from 'cloudinary-react';
import {TweenMax,TimelineMax,TimelineLite,TweenLite,Elastic,Sine,bbox,center,Power1,Circ} from 'gsap';
import {reactLocalStorage} from 'reactjs-localstorage';
import Path from './Path';
import Sidebar from './Sidebar';
moment.locale('en-GB');
BigCalendar.momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(BigCalendar);
class Calender extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
            auth_token : reactLocalStorage.get('auth_token'),
            cal_events: [],
            itemList: [],
            selectAll:false,
            removeEventId:[]
        }
        this.handleChange = this.handleChange.bind(this);
        this.loadData = this.loadData.bind(this);
        this.moveEvent = this.moveEvent.bind(this);
        this.newEvent = this.newEvent.bind(this);
        this.onSelectEvent = this.onSelectEvent.bind(this);
        this.convertDate = this.convertDate.bind(this);
        this.deSelectAll = this.deSelectAll.bind(this);
        this.formatDate = this.formatDate.bind(this);
    }
    convertDate = (date) => {
        //return moment.utc(date).toDate()
        var splitDate = date.split("-");
		return new Date(splitDate[0], splitDate[1] - 1, splitDate[2]);
    }
    formatDate = (date) => {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }
    handleChange(e) {        
        var _this = this;
        _this.setState({
           [e.target.name]: e.target.value
        });
        if(e.target.checked){
            axios({
                method: 'get',
                url: Path.PRODUCT_URL+Path.URL.GET_UNAVAIL_ITEMS+'/'+e.target.value,
                headers: {
                    'Api-Token':'Bearer '+_this.state.auth_token
                }
            })
            .then(response => {
                if(response.data.success == '1'){
                    let appointments = response.data.product;
                    for (let i = 0; i < appointments.length; i++) {
                        appointments[i].start = _this.convertDate(appointments[i].start)
                        appointments[i].end = _this.convertDate(appointments[i].end)
                    }
                    if(this.state.cal_events.length == '0'){
                        _this.setState({
                            cal_events:appointments
                        })
                    }
                    else{
                        this.setState(prevState => ({
                            cal_events: [...prevState.cal_events.concat(appointments)]
                        }))
                    }
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
        }
        else{            
            if(this.state.removeEventId.indexOf(e.target.value) < 0){
                this.state.removeEventId.push(e.target.value);
                let removeEventData = {"data": this.state.removeEventId}
                var _this = this;
                axios({
                    method: 'post',
                    url: Path.PRODUCT_URL+Path.URL.REMOVE_EVENTS,
                    headers: {
                        'Api-Token':'Bearer '+_this.state.auth_token
                    },
                    data:removeEventData
                })
                .then(response => {
                    let appointments = response.data.product;
                    for (let i = 0; i < appointments.length; i++) {
                        appointments[i].start = _this.convertDate(appointments[i].start)
                        appointments[i].end = _this.convertDate(appointments[i].end)
                    }
                    _this.setState({
                        cal_events:appointments
                    })
                })
                .catch(function (error) {
                    //console.log(error);
                });
            }            
        }
        if($(".itemListBlock").length == $(".itemListBlock:checked").length){
            this.setState({selectAll:false});
        }
        else{
            this.setState({selectAll:true});
        }
        if($(".itemListBlock:checked").length < 1){
            this.setState({cal_events:[]})
        }
    }
    onSelectEvent(pEvent) {
        confirmAlert({
            title: 'Are you sure to remove this slot for your item?',
            buttons: [
            {
                label: 'Yes',
                onClick: () =>{
                    this.setState((prevState, props) => {
                        const cal_events = [...prevState.cal_events]
                        const idx = cal_events.indexOf(pEvent)
                        cal_events.splice(idx, 1);
                        return { cal_events };
                    });
                    let fromDate = this.formatDate(pEvent.start);
                    let toDate = this.formatDate(pEvent.end);
                    let productId = pEvent.id;
                    let postData = [];
                    postData.push(
                        {                       
                            id: productId,
                            start: fromDate,
                            end: toDate
                        }
                    );
                    postData = {"data": postData}
                    var _this = this;
                    axios({
                        method: 'post',
                        url: Path.PRODUCT_URL + Path.URL.BLOCK_ITEM_AVAIL,
                        headers: {
                            'Api-Token':'Bearer '+_this.state.auth_token
                        },
                        data: postData
                    })
                    .then(function (response) {
                        
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
                }
            },
            {
                label: 'No'
            }
            ]
        });
    }
    moveEvent({ event, start, end, isAllDay: droppedOnAllDaySlot }) {
        /*const { cal_events } = this.state
        const idx = cal_events.indexOf(event)
        let allDay = event.allDay
        if (!event.allDay && droppedOnAllDaySlot) {
        allDay = true
        } else if (event.allDay && !droppedOnAllDaySlot) {
        allDay = false
        }
        const updatedEvent = { ...event, start, end, allDay }
        const nextEvents = [...cal_events]
        nextEvents.splice(idx, 1, updatedEvent)
        this.setState({
            cal_events: nextEvents,
        })*/
        // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
    }
    //resizeEvent(event, start, end){
    resizeEvent = (resizeType, { event, start, end }) => {
        const { events } = this.state;
        const nextEvents = events.map(existingEvent => {
        return existingEvent.id == event.id
        ? { ...existingEvent, start, end }
        : existingEvent
        })
        this.setState({
        events: nextEvents,
        })
        //alert(`${event.title} was resized to ${start}-${end}`)
    }
    newEvent(event) {
        let idList = this.state.cal_events.map((a) => a.id);
        let newId = Math.max(...idList) + 1;
        let hour = [];
        let postData = [];
        let fromDate = this.formatDate(event.start);
        let toDate = this.formatDate(event.end);
        $("#itemListBlockWrapper input:checkbox[name=items]:checked").each(function(){
            hour.push(
                {                       
                    id: newId,
                    title: $(this).attr('data-text'),
                    allDay: event.slots.length === 1,
                    start: event.start,
                    end: event.end,
                    product_id:event.product_id
                }
            );
            postData.push(
                {                       
                    id: newId,
                    start: fromDate,
                    end: toDate,
                    product_id:$(this).attr('data-value')
                }
            );  
        });
        postData = {"data": postData}
        this.setState({
            cal_events: this.state.cal_events.concat(hour)
        }, () => {
            var _this = this;
            axios({
                method: 'post',
                url: Path.PRODUCT_URL + Path.URL.BLOCK_ITEM_AVAIL,
                headers: {
                    'Api-Token':'Bearer '+_this.state.auth_token
                },
                data: postData
            })
            .then(function (response) {
                
            })
            .catch(function (error) {
                //console.log(error);
            });
        });
    }
    deSelectAll(){
        this.setState(prevState => ({
            selectAll: !prevState.selectAll
        }) , () => {
            if(this.state.selectAll === true){      
                $(".itemListBlock").prop("checked", false);
                this.setState({cal_events:[]})
            }
            else{
                $(".itemListBlock").prop("checked", true);
                var _this = this;
                axios({
                    method: 'get',
                    url: Path.PRODUCT_URL+Path.URL.GET_UNAVAIL_ITEMS,
                    headers: {
                        'Api-Token':'Bearer '+_this.state.auth_token
                    }
                })
                .then(response => {
                    if(response.data.success == "1"){
                        let appointments = response.data.product;
                        for (let i = 0; i < appointments.length; i++) {
                            appointments[i].start = _this.convertDate(appointments[i].start)
                            appointments[i].end = _this.convertDate(appointments[i].end)
                        }
                        _this.setState({
                            cal_events:appointments
                        })
                    }
                })
                .catch(function (error) {
                    //console.log(error);
                });
            }
        });        
    }
    componentDidMount() {
        this.loadData();
        setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    loadData(){
        var _this = this;
        axios({
            method: 'get',
            url: Path.PRODUCT_URL+Path.URL.GET_UNAVAIL_ITEMS,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(response => {
            if(response.data.success == "1"){
                let appointments = response.data.product;
                for (let i = 0; i < appointments.length; i++) {
                    appointments[i].start = _this.convertDate(appointments[i].start)
                    appointments[i].end = _this.convertDate(appointments[i].end)
                }
                _this.setState({
                    cal_events:appointments
                })
            }
        })
        .catch(function (error) {
            //console.log(error);
        });
        axios({
            method: 'get',
            url: Path.PRODUCT_URL+Path.URL.GET_USER_ITEMS,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(response => {
            if(response.data.success == "1"){
                _this.setState({
                    itemList:response.data.payload,
                    itemListWrapper:true
                })
            }
            else{
                _this.setState({
                    itemListWrapper:false
                })
            }
        })
        .catch(function (error) {
            //console.log(error);
            _this.setState({
                itemListWrapper:false
            })
        });
    }    
    render(){
        const MonthEvent = ({ event }) => (
            <div><span className="eventNameTitle">{event.title}</span><span className="eventNameRemove">x</span></div>
        );
        const { cal_events } = this.state;
        const eventStyleGetter = (event, start, end, isSelected) => {
            return { 
              style: { backgroundColor: event.color } 
            }
        } 
        const renderItemList = this.state.itemList.map(function(item, i) {
            return(
                <li key={i}>
                    <input id={'product_'+item.id} type="checkbox" onChange={e => this.handleChange(e)} defaultChecked className="Calender_Linput itemListBlock" name="items" value={item.lending_id} data-value={item.id} data-text={item.product_name}/>
                    <label htmlFor={'product_'+item.id} className="Calender_Llabel">{item.product_name}</label>
                </li>
            )
		},this);
        return(
            <div>
                <div className="main_wrapper">
                    <section className="dashboard_bg">
                        <Sidebar/>
                        <div className="content-wrapper">
                            <div className="content-height-wrapper calender_wrapper">
                                <div className="tab-content">
                                    <div className="col-sm-4 col-md-3 col-xs-12 Calender_L">
                                        <div className="Calender_Lview">View items</div>
                                        <div className="Calender_Ldesalect" onClick={e => this.deSelectAll(e)}>{this.state.selectAll === true ? 'Select All' : 'Deselect All'}</div>
                                        <ul id="itemListBlockWrapper">
                                            {this.state.itemListWrapper === true ?
                                            renderItemList
                                            :
                                            <li className="text-center m-t-20 m-b-10">There is no items</li>
                                            }
                                        </ul>                                                                              
                                    </div>
                                    <div className="col-sm-8 col-md-9 col-xs-12 Calender_R">
                                        <div className="Calender_Lview text-center">Lender calendar</div>
                                        <div className="calender_events_wrapper">
                                            <DragAndDropCalendar
                                            selectable
                                            popup
                                            localizer={this.props.localizer}
                                            events={cal_events}
                                            onEventDrop={event => this.moveEvent(event)}
                                            //resizable
                                            onEventResize={this.resizeEvent}
                                            onSelectSlot={this.newEvent}
                                            views={['month']}
                                            defaultView={BigCalendar.Views.MONTH}
                                            defaultDate={new Date()}
                                            onSelectEvent = {event => this.onSelectEvent(event)} //Fires selecting existing event
                                            eventPropGetter = {(eventStyleGetter)}
                                            components={{
                                                event: MonthEvent,
                                            }}
                                            />
                                        </div>                                        
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </section>
                </div>
            </div> 
        );
    }
}
export default Calender;