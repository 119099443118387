import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import ReactSVG from 'react-svg';
import 'react-dates/initialize';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import createFragment from 'react-addons-create-fragment';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import {Image, Video, Transformation, CloudinaryContext} from 'cloudinary-react';
import {reactLocalStorage} from 'reactjs-localstorage';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ImageGallery from 'react-image-gallery';
import {Elements, StripeProvider, CardElement, injectStripe} from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';
import 'react-perfect-scrollbar/dist/css/styles.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "react-image-gallery/styles/css/image-gallery.css";
import Slider from "react-slick";
import {TweenMax,TimelineMax,TimelineLite,TweenLite,Elastic,Sine,bbox,center,Power1,Circ} from 'gsap';
import Path from './Path';
const moment = extendMoment(Moment);
const createOptions = () => {
	return {
	  style: {
		base: {
		  fontSize: '16px',
		  color: '#424770',
		  fontFamily: 'Open Sans, sans-serif',
		  letterSpacing: '0.025em',
		  '::placeholder': {
			color: '#aab7c4',
		  },
		},
		invalid: {
		  color: '#c23d4b',
		},
	  }
	}
};
class ProductDetails extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
			auth_token : reactLocalStorage.get('auth_token'),
			product:[],
			products:[],
			product_image_slide:[],
			showError:'',
			getReview:[],
			lender_id_profile:Path.PRODUCT_ID,
			created:'',
			getReviewShow:false
        }
		this.loadData = this.loadData.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.datenth = this.datenth.bind(this);
		this.formatDate = this.formatDate.bind(this);
		this.getReviews = this.getReviews.bind(this);
		this.formatDateStar = this.formatDateStar.bind(this);
	}  
	handleChange(e) {
        this.setState({
			showError:'',
            [e.target.name]: e.target.value
        });
	}
	getReviews(id){
		var _this = this;        
        axios({
            method: 'get',
            url: Path.USER_URL + Path.URL.GET_REVIEW_DETAILS+'/'+id,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {
            _this.setState({
				getReview:response.data.reviewerDetails,
				lenderName:response.data.rating.lenderDetails.name,
				lenderImage:response.data.rating.lenderDetails.profile_image,
				lenderBioinfo:response.data.rating.lenderDetails.bio_info,
				ratingCount:response.data.rating.count,
				rating:response.data.rating.rating,
				created:response.data.rating.lenderDetails.created,
				city:response.data.rating.lenderDetails.city,
				getReviewShow:true,
				account_stripeAccount:response.data.rating.lenderDetails.stripe_account_id,
				account_email:response.data.rating.lenderDetails.email,
				account_mobile_number:response.data.rating.lenderDetails.mobile_number
			}, () => {
				if(_this.state.getReview.length <= 0){
					_this.setState({getReviewShow:false});
				}
			});
        })
        .catch(function (error) {
            //console.log(error);
		});
	}
    loadData(){
		var _this = this;   
		_this.getReviews(_this.state.lender_id_profile);		
		var _this = this;
        axios({
            method: 'get',
            url: Path.PRODUCT_URL+Path.URL.LENDER_ITEMS+'/'+_this.state.lender_id_profile,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {
            if(response.data.success == "1"){
               _this.setState({products:response.data.product})
            }
        })
        .catch(function (error) {
            //console.log(error);
        });
	}
	datenth(d) {
		if(d>3 && d<21) return 'th';
		switch (d % 10) {
				case 1:  return "st";
				case 2:  return "nd";
				case 3:  return "rd";
				default: return "th";
			}
	} 
	formatDateStar(date){
		var splitDate = date.split("-");
		var fortnightAway = new Date(splitDate[0], splitDate[1] - 1, splitDate[2]), dateNew = fortnightAway.getDate(),
		month = "January,February,March,April,May,June,July,August,September,October,November,December".split(",")[fortnightAway.getMonth()];
		return dateNew+this.datenth(dateNew)+" "+month+" "+splitDate[0];
	}
	formatDate(date){
		var splitDate = date.split("/");
		var fortnightAway = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]), dateNew = fortnightAway.getDate(),
		month = "January,February,March,April,May,June,July,August,September,October,November,December".split(",")[fortnightAway.getMonth()];
		return dateNew+this.datenth(dateNew)+" "+month;
	}
    componentDidMount() {
		this.loadData();
		setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    render(){
		const reviewWrapper = this.state.getReview.map(function(item,o){
			return(
				<div className="col-xs-12 col-sm-6 col-md-4" key={o}>
					<div className="reviewWrapOuter">
						<div className="menu_pro_reviews_info">
							<div className="menu_pro_reviews_infoUser">
								<img src={item.reviewer.profile_image} alt="profile image"/>
							</div>
							<div className="menu_pro_reviews_infoHead">
								<h4>{item.reviewer.name}</h4>
								<p>{this.formatDateStar(item.created.split(" ")[0])}</p>
								<span className="star_rating_wrap">
									<span className="star_rating_active" style={{width:(item.rating*20)+'%'}}></span>
								</span>
							</div>							
							<div className="menu_pro_reviews_infoText">
								{item.comments}
							</div>
						</div>
					</div>
				</div>
			)
		},this);
        const renderMyItems = this.state.products.slice(0).reverse().map(function(product, i) {
            return(
                <div className="profileItems col-sm-6 col-md-3 col-xs-12 myitems_propadd" key={i}>
                    <a className="myitems_proBox" href={Path.BASE_URL+Path.PAGE_NAME.ProductDetails+'/'+product.lending_id} >
                        <div className="myitems_proImg">
                            {product.product_image.slice(0,1).map(function(images, j) {
                                return(
                                <img key={j} src={Path.CLOUDINARY.url+'/c_scale,h_270,w_360/'+Path.CLOUDINARY.product_image+images.image_url.replace(/^.*[\\/]/, '')} onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_IMAGE}} />
                                )
                            })}
                        </div>
                        <div className="myitems_proCont">
                            <div className="myitems_proContHead">{product.product_name}</div>
                            <div className="myitems_proContPrice">£{(product.rent === 'Yes') ? product.price_day.toFixed(2) + ' a day' : product.product_worth.toFixed(2)  + ' to buy'}</div>
                            {/*<div className="myitems_proContPrice">£{product.price_day} a day</div>*/}
                            <div className="myitems_proContPostday">Posted {product.created}</div>
                        </div>
                    </a>
                </div>
            )
        },this);
        return(
            <div className="main_wrapper">
				<section className="view_prof">
					<div className="container">
						<div className="view_prof_dea">
							<div className="view_prof_deaImg">
								<img src={this.state.lenderImage}/>
							</div>
							<div className="view_prof_deaName">
								<h4>{this.state.lenderName}</h4>
								<p>
									{this.state.city} - Joined {this.formatDateStar(this.state.created.split(" ")[0])}
									<span className="star_rating_wrap m-r-10">
										<span className="star_rating_active" style={{width:(this.state.rating*20)+'%'}}></span>
									</span>
								</p>
								<div className="no_verif">{this.state.lenderBioinfo}</div>
								<div className="verif_info">Verified Information</div>
									{this.state.account_stripeAccount !== undefined && <div className="no_verif_newline"><i className="fa fa-star"></i> Stripe Account Verfied</div> } 
									{this.state.account_email !== undefined && <div className="no_verif_newline"><i className="fa fa-star"></i> Email ID Verfied</div>}
									{this.state.account_mobile_number !== undefined && <div className="no_verif_newline"><i className="fa fa-star"></i> Mobile Number Verfied</div>}
							</div>
							<div className="view_prof_deaProfile hide" data-toggle="modal" data-target="#share_profile"><i className="fa fa-share-alt"></i>Share Profile	</div>
						</div>
						<div className="col-xs-12 no-padding">
							<h3 className="ho_renshop_sH">Reviews for {this.state.lenderName}</h3>
							{this.state.getReviewShow === true ?
							<div className="col-xs-12 no-padding">
								<div className="row">
									{reviewWrapper}
								</div>
							</div>
							:
							reviewWrapper.length <= 0 &&
								<div className="no_verif">There is no reviews.</div>
							}
							<h3 className="ho_renshop_sH">{this.state.lenderName}'s available items</h3>
							<div className="col-xs-12 no-padding">
								<div className="row">
									{renderMyItems.length > 0 ? renderMyItems : 
										<div className="no_verif">There is no available items.</div>
									}
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
        );
    }
}
export default ProductDetails;