import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import {reactLocalStorage} from 'reactjs-localstorage';
import Path from './Path';
class LoginBtn extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
			auth_token : reactLocalStorage.get('auth_token')
        }
		this.goToLogin = this.goToLogin.bind(this);
	}  
	goToLogin(){
		this.props.goLogin(this);
	}	
    componentDidMount() {

    }
    render(){
        return(
			<div className="col-xs-12 no-padding">
				<a className="menu_bg_right_rentBtn" onClick={e => this.goToLogin(e)}>Login to Rent</a>
			</div>
		);
    }
}
export default LoginBtn;