module.exports = {

    //Local Link
    /*PRODUCT_URL:"http://localhost/labs/cms/weborro-live/admin/weborro-product/public/app/v1/",
    USER_URL:"http://localhost/labs/cms/weborro-live/admin/weborro-user/public/app/v1/",*/

    //Live Link
    PRODUCT_URL:"https://www.weborro.com/admin/weborro-product/public/app/v1/",
    USER_URL:"https://www.weborro.com/admin/weborro-user/public/app/v1/",
    API_LIST: {
        GOOGLE_API_KEY: 'AIzaSyAkiOANoJaDdlupPEhvBS5YxndHK9j-Wi8',
        //GOOGLE_API_KEY: 'AIzaSyAT5J4g926rChrwU3ti6luNv5338Fb3mwE',
        //STRIPE_API_KEY: 'pk_live_wWCtGZ3Snn2VNGAWpJozGwYg' // Live
        STRIPE_API_KEY: 'pk_test_DnL8Z7DvLVo4w90tlE95YTga' // Demo
    },
    SITE_URL:'https://www.weborro.com',
    CLOUDINARY: {
        cloudName : 'daaopdqgb',
        apiKey : '545118744681935',
        uploadPreset : 'n0gvjln7',
        folder :'weborro',
        url:'https://res.cloudinary.com/daaopdqgb/image/upload',
        product_image:'v1555059918/weborro/'
    },   
    FACEBOOK:{
        APP_ID:'570507136691634',
        APP_SECRET_KEY:'ccdd231716418aa680a2281949fe84a5'
    },
    GOOGLE:{
        APP_ID:'51917936721-7n38fcdnpc7dce9o8c4f82e8mnk3vd4l.apps.googleusercontent.com',
        APP_SECRET_KEY:'pTY5R1xMEiJQeB_0Bh45bKAS'
    },
    CURRENCY:{
        CODE : ' ' 
    },
    STATIC_PAGE:{
        About : '0',
        Help : '1',
        Terms : '2',
        Privacy : '3'
    },
    PAGE_NAME:{
        Root : '/',
        Home : '/Home',
        SubCategoryList : '/categories',
        SiblingCategoryList:'/categories',
        Products : '/categories',
        Login : '/myaccount/login',
        Register : '/myaccount/register',
        MyAccount : '/myaccount/profile',
        ContactUs : '/ContactUs',
        ProductDetails : '/products/view',
        ProductAdd : '/product/add',
        ProductEdit : '/product/edit',
        PostHistory : '/myaccount/myposts',
        ChatHistory : '/myaccount/sellermessages',
        PostDetailsEdit : '/post/view',
        About : '/about-us',
        PrivacyPolicy : '/privacy-policy',
        Help : '/help',
        Terms : '/terms-conditions',
        NotFound : '/not-found',
        ChangePassword: '/myaccount/changepassword',
        Messages: '/myaccount/messages',
        MessagesWrapper: '/myaccount/messages',
        SellerChat: '/myaccount/sellermessages',
        MyItems:'/myaccount/myitems',
        Search:'/product/search',
        MyRentals:'/myaccount/myrentals',
        Watchlist:'/myaccount/watchlist',
        BorrowProductDetails : '/products/borrowview',
        Calender:'/myaccount/calender',
        Toolkit:'/myaccount/toolkit',
        Analytics:'/myaccount/analytics',
        Notifications:'/myaccount/notifications',
        Verifications:'/myaccount/verifications',
        MessageToLender:'/message-lender',
        PaymentLoader:'/redirecttosttripe',
        BuyProduct:'/products/buy',
        MyCards:'/myaccount/mycards',
        Invoice:'/myaccount/details',
        LendInvoice:'/myaccount/lendingdetails',
        ViewProfile:'/view/profile',
        Careers : '/careers',
        Contactus : '/contact-us',
        Faq : '/faq',
        Howitworks : '/how-it-works',
        Blog:'/blog',
        Sitemap:'/sitemap',
        CheckoutForm:'/CheckoutForm'
    },
    //BASE_URL : "/frontend",
    BASE_URL : "",
    LOCATION : "London",
    MOBILE_NO_LENGTH : "11",
    MOBILE_NO_NOT_EXIST : "0",
    MOBILE_NO_INVAILD_LENGTH : "10",
    MOBILE_COUNTRY_CODE : "(+88)",
    HOME_PRODUCT_LIMIT : "8",
    Page : window.location.pathname,
    Category_SEO : window.location.pathname.split("/").pop(),
    Category_Inner_SEO : window.location.pathname.split("/")[2],
    SubCategory_SEO : window.location.pathname.split("/").pop(),
    PRODUCT_ID : window.location.pathname.split("/").pop(),
    POST_ID : window.location.pathname.split("/").pop(),
    CONVERSATION_ID : window.location.pathname.split("/").pop(),
    CONVERSATION_CODE : '090',
    ITEM_LENGTH : '14',

    //server
    NO_PRODUCT_IMAGE:'https://www.weborro.com/images/no-image-preview.png',
    NO_PROFILE_IMAGE:'https://www.weborro.com/images/profile.jpg',
    IMAGE_PATH:'https://www.weborro.com/images',
    NO_PRODUCT_NEW_IMAGE:'https://www.weborro.com/images/noimagepreview.png',


    //Local
    /*NO_PRODUCT_IMAGE:'http://localhost/labs/cms/weborro-live/frontend/images/no-image-preview.png',
    NO_PROFILE_IMAGE:'http://localhost/labs/cms/weborro-live/frontend/images/profile.jpg',
    IMAGE_PATH:'http://localhost/labs/cms/weborro-live/frontend/images',
    NO_PRODUCT_NEW_IMAGE:'http://localhost/labs/cms/weborro-live/frontend/images/noimagepreview.png',*/

    //IMAGE_PATH:'https://localhost:3000/images',
    //IMAGE_PATH:'http://192.168.1.254:3000/images',

    PROFILE:'profile',
    MYITEMS:'myitems',
    PRODUCTADD:'add',
    ProductEdit:'edit',
    PRODUCTVIEW:'products',
    SEARCH:'search',
    WATCHLIST:'watchlist',
    RENTALS:'myrentals',
    TEST:'test',
    BORROWVIEW:'borrowview',
    CALENDER:'calender',
    TOOLKIT:'toolkit',
    ANALYTICS:'analytics',
    NOTIFICATIONS:'notifications',
    MESSAGES:'messages',
    VERIFICATIONS:'verifications',
    MESSAGE_LENDER:'message-lender',
    PAYMENT_LOADER:'redirecttosttripe',
    PAYMENT_CARDS:'mycards',
    INVOICE:'details',
    LENDER_INVOICE:'lendingdetails',
    DEFAULT_MSG:'Hello',
    ROOT:'',
    HOME:'Home',
    URL:{
        TOP_SUBCAT_LIST:'get-favoritr-subcategories',
        GET_USER_DETAILS:'get-customer-detail',
        GET_CAT_BASED_SUBCAT_LIST:'category-with-subcat-list',
        LOGOUT:'logout',
        SITE_SETTINGS:'get-settings',
        LOGIN:'login',
        REGISTER:'signup',
        MY_ITEMS:'get-customer-product',
        PRODUCT_SEARCH:'product-search',
        CAT_BASED_LIST:'category-with-subcat-list',
        UPDATE_PROFILE:'update-customer-detail',
        UPDATE_BANK_DETAILS:'update-bank-detail',
        PRODUCT_ADD:'product-add',
        PRODUCT_EDIT:'product-edit',
        GET_CAT_LIST:'category-list',
        GET_SUB_CAT_LIST:'sub-category-list',
        GET_SIBLING_CAT_LIST:'sibling-category-list',
        GET_PRODUCT_DETAILS:'get-product-details',
        BORROW_PRODUCT:'to-borrow-product',
        BORROW_CLIENT_SECRET_KEY:'borrow-product',
        GET_STRIPE_CUSTOMER:'get-stripe-customer',
        UPDATE_WATCHLIST:'update-product-watchlist',
        GET_WATCHLIST:'get-my-watch-list',
        GET_BORROWED_ITEMS:'get-borrowed-product',
        GET_LENDED_ITEMS:'get-my-lending-products',
        GET_BORROWED_ITEM_DETAILS:'get-borrowed-product-details',
        CREATE_MESSAGE:'add-chat-message',
        GET_CUSTOMER_CHAT_PRODUCTS:'get-customer-chat-products',
        GET_CUSTOMER_CHAT_PRODUCT_DETAILS:'get-chat-product-details',
        GET_USER_ITEMS:'get-product-list',
        GET_UNAVAIL_ITEMS:'get-available-products',
        BLOCK_ITEM_AVAIL:'block-product-availability',
        ANALYTICS_COUNT:'get-analytics-count',
        HOLIDAY_STATUS:'update-holiday-status',
        REMOVE_EVENTS:'remove-booked-date',
        INITIATE_CHAT:'initiate-conversation',
        GET_CHAT_HISTORY:'get-chat-history',
        GET_STRIPE_ACCOUNT_ID:'update-stripe-account-id',
        DISCONNECT_STRIPE:'unlink-stripe-account-id',
        BUY_PRODUCT:'to-buy-product',
        BUY_CLIENT_SECRET_KEY:'buy-product',
        GET_SOLD_PRODUCTS:'get-sold-product',
        GIVE_USER_REVIEWS:'add-user-review',
        SAVED_CARDS:'get-saved-cards',
        REMOVE_SAVED_CARDS:'remove-saved-card',
        EMAIL_EXIST:'email-exist',
        AUTH_TOKEN_VERFIY:'auth-token-verify',
        GET_REVIEW_DETAILS:'get-review-details',
        DELETE_PRODUCT:'delete-product',
        MOBILE_VERIFY:'mobile-number-verify',
        OTP_VERIFY:'otp-verify',
        EMAIL_VERIFY:'email-verify',
        EMAIL_OTP_VERIFY:'email-status-verify',
        CAREERS:'careers',
        CONTACT_US:'contact-us',
        BOUGHT_HISTORY:'get-bought-history',
        LENDER_ITEMS:'get-lender-product',
        FORGOT_PASSWORD:'forgot-password',
        HOME_VIEW_CATEGORY:'category-with-subcat-list-fav',
        CHANGE_PASSWORD:'change-password',
        CREATE_PASSWORD:'choose-password'
    },
    POST_CODE_API:'https://api.postcodes.io/postcodes/',
    CONNECT_STRIPE:'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=',
    PUSHER_API_KEY:'a365d5687ed7c2b434ad',
    UPLOAD_CV:'upload.php',
    YOUTUBE_ID:'1hii249Wvh0'
}