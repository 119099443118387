import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import Geocode from "react-geocode";
import Autocomplete from 'react-google-autocomplete';
import {Image, Video, Transformation, CloudinaryContext} from 'cloudinary-react';
import {reactLocalStorage} from 'reactjs-localstorage';
import Path from './Path';
import Sidebar from './Sidebar';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';

const { Parser } = require('json2csv');
const { createReadStream, createWriteStream } = require('fs');
const { AsyncParser } = require('json2csv');




Geocode.setApiKey(Path.API_LIST.GOOGLE_API_KEY);
Geocode.enableDebug();
class MyAccount extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
            auth_token : reactLocalStorage.get('auth_token'),
            products:[],
            product_images:[]
        }
        this.loadData = this.loadData.bind(this);
        this.deleteProduct = this.deleteProduct.bind(this);
    }
    componentDidMount() {
        this.loadData();
        setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    deleteProduct(e,id){
        var _this = this;
        confirmAlert({
            title: 'Are you sure want to delete an item.',
            buttons: [
                {
                    label: 'OK',
                    onClick: () =>{
                        axios({
                            method: 'get',
                            url: Path.PRODUCT_URL+Path.URL.DELETE_PRODUCT+'/'+id,
                            headers: {
                                'Api-Token':'Bearer '+_this.state.auth_token
                            }
                        })
                        .then(function (response) {
                            $("#product_"+id).remove();
                        })
                        .catch(function (error) {
                            //console.log(error);
                        });
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () =>{
                        
                    }
                },
            ]
        });
    }
    loadData(){
        var _this = this;
        axios({
            method: 'get',
            url: Path.PRODUCT_URL+Path.URL.MY_ITEMS,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {
            if(response.data.success == "1"){
               _this.setState({products:response.data.product})
            }
        })
        .catch(function (error) {
            //console.log(error);
        });
    }
    render(){
        const renderMyItems = this.state.products.slice(0).reverse().map(function(product, i) {
            return(
                <div id={'product_'+product.id} className="col-sm-6 col-md-4 col-xs-12 myitems_propadd" key={i}>
                    <div className="myitems_proBox">
                        <div className="myitems_proImg myitemslist">
                            {product.product_image.slice(0,1).map(function(images, j) {
                                return(
                                <img key={j} src={Path.CLOUDINARY.url+'/c_scale,h_270,w_360/'+Path.CLOUDINARY.product_image+images.image_url.replace(/^.*[\\/]/, '')} onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_IMAGE}} />
                                )
                            })}
                            <div className="myitems_proImg_overlay">
                                <div className="col-xs-12 text-center">
                                    <a href={Path.BASE_URL+Path.PAGE_NAME.ProductEdit+'/'+product.lending_id} className="edit_item">Edit / View Item</a>
                                </div>
                                <div className="col-xs-12 text-center m-t-20">
                                    <span onClick={e => this.deleteProduct(e,product.id)} className="pointer edit_item">Delete item</span>
                                </div>
                            </div>
                        </div>
                        <div className="myitems_proCont">
                            <div className="myitems_proContHead">{product.product_name}</div>
                            {/*<div className="myitems_proContPrice">£{product.price_day} a day</div>*/}


                            <div className="myitems_proContPrice">
                                £{(product.rent === 'Yes') ? product.price_day.toFixed(2) + ' a day' : product.product_worth.toFixed(2)  + ' to buy'}
                            </div>
                            <div className="myitems_proContPostday">Posted {product.created}</div>
                        </div>
                    </div>



                </div>
            )
        },this);
        return(
            <div>
                <div className="main_wrapper">
                    <section className="dashboard_bg">
                        <Sidebar/>
                        <div className="content-wrapper">
                            <div className="content-height-wrapper">
                                <div id="myitems">
                                    <div className="col-xs-12">
                                        <div className="col-xs-12 no-padding m-t-40">
                                            <div className="row">
                                                <div className="col-sm-6 col-md-4 col-xs-12 myitems_propadd">
                                                    <div className="myitems_listItemBox">
                                                        <div className="myitems_listItemBox_Cont">
                                                            <div className="myitems_listItemBox_ContH">Start building your rental store!</div>
                                                            <div className="myitems_listItemBox_ContP">You can list each of your item with a <a>daily</a>, <a>weekly</a> rate and an option to <a>buy</a>. </div>
                                                            <a href={Path.BASE_URL+Path.PAGE_NAME.ProductAdd} className="myitems_listItemBox_ContB">List an item</a>
                                                        </div>
                                                    </div>
                                                </div>	
                                                {renderMyItems}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div> 
        );
    }
}
export default MyAccount;