import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Path from './Path';
import { Link } from 'react-router-dom';
class NotFound extends Component {
    render() {
        return (
            <div className="aboutus-banner">
            <div className="container">
                <div className="aboutHead">404 Not Found</div>
                <p><Link to={Path.BASE_URL+Path.PAGE_NAME.Root}>Go to Home</Link></p>
            </div>
        </div>
        );
    }
}

export default NotFound;