import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Accordion,AccordionItem,AccordionItemHeading,AccordionItemPanel,AccordionItemButton} from 'react-accessible-accordion';
import $ from 'jquery';
import Path from './Path';
import 'react-accessible-accordion/dist/fancy-example.css';
class Faq extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {

        }
        this.loadData = this.loadData.bind(this);
    }
    loadData(){
    }
    componentDidMount() {
        this.loadData();   
        setTimeout(() =>
            this.props.handler(false)
        , 2000);   
    }
    render() {
        return (
                <div className="contactBg">
               <div className="container">
                    <div className="innerhead">FAQ </div>
                    <div className="innersubhead">Ask a Borrower</div>
                    <p>Weborro is all about making your life easier. Take a look at our quick and easy FAQs and you’ll find everything you need. </p>
                    <Accordion>
                      <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Can I lend and borrow anything?  
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                The only restrictions we have, are that you must be offering safe and legal items. Other than that, you go for it! 
                            </p>
                        </AccordionItemPanel>
                      </AccordionItem>    
                      <AccordionItem>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                                  Will my stuff be safe? 
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                              <p>
                                  Absolutely, because we’ve created a watertight set of terms and conditions, and all sorts of other clever legal stuff that looks after your items. Everyone who uses Weborro abides by them, and that means that everyone looks after what they borrow. It’s just better that way.   
                              </p>
                          </AccordionItemPanel>
                      </AccordionItem>    
                      <AccordionItem>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                                  How much can I charge?  
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                              <p>
                                  You can charge as much or as little as you like. One thing to bear in mind though; you want to make your price competitive if you want your items to earn you money!              
                              </p>
                          </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                                  How long can I borrow stuff for?  
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                              <p>
                                  You can borrow for as long as you like, and it’s all between you and the other party. Send a couple of quick messages to arrange things and then get started.   
                              </p>
                          </AccordionItemPanel>
                      </AccordionItem>
                  </Accordion>
               </div>
            </div>
        );
    }
}
export default Faq;