import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import {Map, InfoWindow, GoogleApiWrapper} from 'google-maps-react';
import ReactSVG from 'react-svg';
import 'react-dates/initialize';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import createFragment from 'react-addons-create-fragment';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import {Image, Video, Transformation, CloudinaryContext} from 'cloudinary-react';
import {reactLocalStorage} from 'reactjs-localstorage';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ImageGallery from 'react-image-gallery';
import {Elements, StripeProvider, CardElement, injectStripe} from 'react-stripe-elements';
import MapGL, { Marker, Popup, NavigationControl, FullscreenControl, FlyToInterpolator} from 'react-map-gl';
import CheckoutForm from './CheckoutForm';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "react-image-gallery/styles/css/image-gallery.css";
import Slider from "react-slick";
import {TweenMax,TimelineMax,TimelineLite,TweenLite,Elastic,Sine,bbox,center,Power1,Circ} from 'gsap';
import Path from './Path';
import Responsive from "react-responsive";
import {VERTICAL_ORIENTATION, HORIZONTAL_ORIENTATION} from 'react-dates/constants';
export const Mobile = props => <Responsive {...props} maxWidth={767} />;
export const Default = props => <Responsive {...props} minWidth={768} />;
const TOKEN = 'pk.eyJ1IjoiY2hhbmRyYXByYWJhIiwiYSI6ImNqdXRmMnltdjA2dGc0YW52cjJtYThiOTkifQ.d9Paj8mjXFC6jhjWcskTFA';
const google = window.google = window.google ? window.google : {}
const moment = extendMoment(Moment);
const createOptions = () => {
	return {
	  style: {
		base: {
		  fontSize: '16px',
		  color: '#424770',
		  fontFamily: 'Open Sans, sans-serif',
		  letterSpacing: '0.025em',
		  '::placeholder': {
			color: '#aab7c4',
		  },
		},
		invalid: {
		  color: '#c23d4b',
		},
	  }
	}
};
const fullscreenControlStyle = {
	position: 'absolute',
	top: 0,
	left: 0,
	padding: '10px'
};
const navStyle = {
	position: 'absolute',
	top: 36,
	left: 0,
	padding: '10px'
};
class ProductDetails extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
			auth_token : reactLocalStorage.get('auth_token'),
			product:[],
			product_image_slide:[],
			days:'',
			checkAvail:true,
			available_date:[],			
			deliveryEnable:false,
			deliveryCharge:'0',
			editUserInfo:false,
			borro_firstname : '',
			borro_lastname:'', 
			borro_address_line1:'',
			borro_address_line2:'',
			pickupNew:'pickup',
			deliveryNew:'delivery',
			city:'',
			userPostcode:'',
			your_details:true,
			payment_details:false,
			verification:false,
			showError:'',
			product_type:'pickup',
			deliveryAvailable:false,
			buymode:'No',
			deliveryAreaNotAvailable:false,
			productIdVal:Path.PRODUCT_ID,
			getReview:[],
			getReviewShow:false,
			showLogin:false,
			allowUser:false,
			readmoreClass:false,
			delivery_area:[],
			description:'',
			cancellation_rules:false,
			liability:false,
			verifiedUser:'0',
			dataSuccess:true,
			stripe_secret_key:this.props.stripe_secret_key,
			viewport: {     
				latitude:  reactLocalStorage.get("getLat") !== undefined ? parseFloat(reactLocalStorage.get("getLat")) : 51.509865,
				longitude : reactLocalStorage.get("getLng") !== undefined ? parseFloat(reactLocalStorage.get("getLng")) : -0.118092,        
                zoom:12,
                bearing: 0,
                pitch: 0
            }
        }
		this.loadData = this.loadData.bind(this);
		this.productSearch = this.productSearch.bind(this);
		this.daysLeft = this.daysLeft.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handlePostCodeChange = this.handlePostCodeChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.deliveryCharge = this.deliveryCharge.bind(this);
		this.requestRent = this.requestRent.bind(this);
		this.datenth = this.datenth.bind(this);
		this.formatDate = this.formatDate.bind(this);
		this.handlePickupDeliveryChange = this.handlePickupDeliveryChange.bind(this);
		this.stepsToDetails = this.stepsToDetails.bind(this);
		this.stepsToPay = this.stepsToPay.bind(this);
		this.stepsToVerify = this.stepsToVerify.bind(this);
		this.getReviews = this.getReviews.bind(this);
		this.formatDateStar = this.formatDateStar.bind(this);
		this.goToLogin = this.goToLogin.bind(this);
		this.toggle = this.toggle.bind(this);
		this.updateViewport = this.updateViewport.bind(this);
		this.cancellationModal = this.cancellationModal.bind(this);
		this.liabilityModal = this.liabilityModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
	} 
	cancellationModal(){
        this.setState({cancellation_rules: true});
	}
	liabilityModal(){
        this.setState({liability: true});
    }
    hideModal(id){
        if(id == 'cancellation_rules'){
            this.setState({cancellation_rules: false});
        }
        if(id == 'liability'){
            this.setState({liability: false});
        }
    }
	updateViewport = (viewport) => {
        this.setState({viewport});
    }
	toggle() {
		this.setState({readmoreClass: !this.state.readmoreClass});
	}
	handleChange(e) {
        this.setState({
			showError:'',
            [e.target.name]: e.target.value
        });
	}
	handlePostCodeChange(e){
        this.setState({
			showError:'',
            [e.target.name]: e.target.value
        });
        let postcode = e.target.value;
        if(postcode.replace(/\s/g,'').length >=3){
            var _this = this;
            axios({
                method: 'get',
                url: Path.POST_CODE_API+postcode+'/validate'
            })
            .then(function (response) {
                if(response.data.result){
                    _this.setState({
                        postcode:postcode
                    });
                    axios({
                        method: 'get',
                        url: Path.POST_CODE_API+postcode
                    })
                    .then(function (response) {
						_this.setState({postcodeCheck:true,latitude:response.data.result.latitude,longitude:response.data.result.longitude,location:response.data.result.region}, () => {
							if(_this.state.product_type == 'delivery'){
								_this.deliveryCharge();
							}							
						});						
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
                }
                else{
                    $("#add_item_message").addClass('error_msg').html('Invalid Postcode');
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
        }
    }    
	productSearch(){
        var _this = this;
        axios({
            method: 'get',
            url: Path.PRODUCT_URL+Path.URL.PRODUCT_SEARCH+'?product=&cat='+this.state.cat_id+'&subCat='+this.state.subcat_id+'&recent=1&cheapest=&limit=&lat='+this.state.latitude+'&long='+this.state.longitude+'&nearest=&similar=&datefrom=&dateto=&miles='
        })
        .then(function (response) {
            if(response.data.success == "1"){
                _this.setState({product:response.data.product})
            }
        })
        .catch(function (error) {
            //console.log(error);
        });
	}  
	getReviews(id){		
		var _this = this;        
        axios({
            method: 'get',
            url: Path.USER_URL + Path.URL.GET_REVIEW_DETAILS+'/'+id,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {
            _this.setState({
				getReview:response.data.reviewerDetails,
				ratingCount:response.data.rating.count,
				rating:response.data.rating.rating,
				getReviewShow:true
			}, () => {
				if(_this.state.getReview.length <= 0){
					_this.setState({getReviewShow:false});
				}
			});
        })
        .catch(function (error) {
            //console.log(error);
		});
	}
    loadData(){
        var _this = this;        
        axios({
            method: 'get',
            url: Path.PRODUCT_URL + Path.URL.GET_PRODUCT_DETAILS+'/'+Path.PRODUCT_ID,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {
			if(response.data.success == '1'){
				_this.setState({dataSuccess:true}, () => {		
					_this.setState({
						product_name:response.data.product.product_name,
						description:response.data.product.description,
						price_day:response.data.product.price_day,
						price_week:response.data.product.price_week,
						pickup:response.data.product.pickup,
						product_worth:response.data.product.product_worth,
						delivery:response.data.product.delivery,
						postcode:response.data.product.postcode,
						cat_id:response.data.product.cat_id,
						subcat_id:response.data.product.subcat_id,
						sibling_id:response.data.product.sibling_id,
						product_image:response.data.product.product_image,
						product_image_view:response.data.product.product_image[0],
						delivery_area:response.data.product.delivery_area,
						category_name:response.data.product.category.category_name,
						sub_category_name:response.data.product.sub_category.sub_category_name,
						product_image_slide:response.data.product.product_image_slide,
						location:response.data.product.location,
						available_date_status:response.data.product.available_date_status,
						available_date:response.data.product.available_date,
						userName:response.data.product.user.name,
						userLastName:response.data.product.user.lastname,
						userImage:response.data.product.user.profile_image,
						buymode:response.data.product.buy,
						rentmode:response.data.product.rent,
						lending_product_id:response.data.product.lending_id,
						lender_id:response.data.product.user_id,
						previewlatitude:response.data.product.latitude,
						previewlongitude:response.data.product.longitude,
						user_stripe_account_id:response.data.product.user.stripe_account_id
					}, () => {				
						_this.getReviews(_this.state.lender_id);			
						if(_this.state.buymode == 'Yes' && _this.state.available_date_status == 'No'){
							_this.setState({allowBuymode:true})
						}
						else{
							_this.setState({allowBuymode:false}) 
						}
						_this.setState({
							viewport: {     
								latitude: _this.state.previewlatitude,
								longitude : _this.state.previewlongitude,           
								zoom:12,
								bearing: 0,
								pitch: 0
							}
						});
					});
				});
			}
			else{
				_this.setState({dataSuccess:false});
			}
        })
        .catch(function (error) {
            //console.log(error);
		});
	}
	daysLeft(startDate,endDate){
		this.setState({ startDate});
		if(endDate !== undefined && endDate !== null && endDate !== ''){
			this.setState({ endDate }, () => {
				let {startDate, endDate} = this.state;
				if(this.state.available_date_status === 'Yes'){
					let noOfDays = endDate.diff(startDate, 'days');
					this.setState({
						days: noOfDays
					});
					let selecteddateArr = [];
					var selectedstart = new Date(startDate);
					var selectedend = new Date(endDate);				
					while(selectedstart <= selectedend){
						selecteddateArr.push(moment(selectedstart).format('MM/DD/YYYY'));
						var selectednewDate = selectedstart.setDate(selectedstart.getDate() + 1);
						selectedstart = new Date(selectednewDate);  
					}
					let dateArr = [];
					this.state.available_date.map(function(availdates, i) {
						var start = new Date(availdates.fromDate);
						var end = new Date(availdates.toDate);				
						while(start <= end){
							dateArr.push(moment(start).format('MM/DD/YYYY'));
							var newDate = start.setDate(start.getDate() + 1);
							start = new Date(newDate);  
						}
					},this);
					if(dateArr.some(v => selecteddateArr.includes(v)) !== true){
						this.setState({
							checkAvail:false						
						});
					}
					else{
						this.setState({							
							checkAvail:true,
							startDate:null,
							endDate:null
						});
						alert("No Availability on those days");
					}
				}
				else{
					let noOfDays = endDate.diff(startDate, 'days');
					this.setState({
						days: noOfDays,
						checkAvail:false						
					});
				}
			});
		}
	}
	deliveryCharge(){
		var _this = this;
		axios({
            method: 'get',
            url: Path.PRODUCT_URL+'get-delivery-charge?product_id='+Path.PRODUCT_ID+'&lat='+this.state.latitude+'&long='+this.state.longitude
        })
        .then(function (response) {
			if(response.data.success == "1"){
				_this.setState({
					deliveryCharge:response.data.deliveryCharge,
					deliveryEnable:true,
					deliveryAreaNotAvailable:false
				});
			}
			else{
				_this.setState({
					deliveryCharge:'0',
					deliveryEnable:false,
					deliveryAreaNotAvailable:true
				});
			}
        })
        .catch(function (error) {
            //console.log(error);
        });
	}
	handleSubmit(event) {
        /*event.preventDefault();
		const data = new FormData();
		data.append("rent_from", this.state.rent_from);
		data.append("rent_to", this.state.rent_to);
		data.append("productId", Path.PRODUCT_ID);
		data.append("product_type", this.state.product_type);
		data.append("delivery_charge", this.state.deliveryCharge);
		data.append("stripe_customer", reactLocalStorage.get('stripe_customer'));
		data.append("borro_firstname", this.state.borro_firstname);
		data.append("borro_lastname", this.state.borro_lastname);
		data.append("borro_address_line1", this.state.borro_address_line1);
		data.append("borro_address_line2", this.state.borro_address_line2);
		data.append("city", this.state.city);
		data.append("postcode", this.state.postcode);
        var _this = this;
        axios({
            method: 'post',
            url: Path.PRODUCT_URL + Path.URL.BORROW_PRODUCT,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            },
            data:data
        })
        .then(function (response) {
			_this.stepsToVerify();
        })
        .catch(function (error) {
            //console.log(error);
        });*/
    }
	requestRent(){
		var _this = this;		
		window.scrollTo(0, 0);
		_this.setState({auth_token:reactLocalStorage.get('auth_token')}, () => {
			axios({
				method: 'get',
				url: Path.USER_URL+Path.URL.GET_USER_DETAILS,
				headers: {
					'Api-Token':'Bearer '+_this.state.auth_token
				}
			})
			.then(function (response) {
				if(response.data.success == "1"){
					_this.setState({
						name:response.data.customer.name,
						profile_image:response.data.customer.profile_image,
						borro_firstname:response.data.customer.name,
						city:response.data.customer.city,
						borrower_id:response.data.customer.id,
						borro_lastname:response.data.customer.lastname,
						borro_address_line1:response.data.customer.address_line1,
						borro_address_line2:response.data.customer.address_line2,
						userPostcode:response.data.customer.postcode,
						verifiedUser:response.data.customer.profile_status						
					}, () => {
						/*if(_this.state.verifiedUser == '0'){
							confirmAlert({
								title: 'Please verify your profile to start renting and selling. Click here to verify your Profile.',
								buttons: [
								{
									label: 'Click Here',
									onClick: () =>{
										setTimeout(() => {
											window.location = Path.BASE_URL+Path.PAGE_NAME.MyAccount;
										},1000);
									}
								}
								]
							});
						}
						if(_this.state.verifiedUser == '1'){*/
						_this.setState({
							editUserInfo:true,
							rent_from:document.getElementById("startDateId").value,
							rent_to:document.getElementById("endDateId").value
						});
						//}
						var postcode = _this.state.userPostcode;
						axios({
							method: 'get',
							url: Path.POST_CODE_API+postcode+'/validate'
						})
						.then(function (response) {
							if(response.data.result){
								axios({
									method: 'get',
									url: Path.POST_CODE_API+postcode
								})
								.then(function (response) {
									_this.setState({postcodeCheck:true,latitude:response.data.result.latitude,longitude:response.data.result.longitude}, () => {
										if(_this.state.delivery == 'Yes'){
											_this.setState({deliveryAvailable:true});
										}							
									});						
								})
								.catch(function (error) {
									//console.log(error);
								});
							}
							else{
								$("#add_item_message").addClass('error_msg').html('Invalid Postcode');
							}
						})
						.catch(function (error) {
							//console.log(error);
						});
					});
				}
			})
			.catch(function (error) {
				//console.log(error);
			});
		});
	}
	datenth(d) {
		if(d>3 && d<21) return 'th';
		switch (d % 10) {
				case 1:  return "st";
				case 2:  return "nd";
				case 3:  return "rd";
				default: return "th";
			}
	} 
	formatDateStar(date){
		var splitDate = date.split("-");
		var fortnightAway = new Date(splitDate[0], splitDate[1] - 1, splitDate[2]), dateNew = fortnightAway.getDate(),
		month = "January,February,March,April,May,June,July,August,September,October,November,December".split(",")[fortnightAway.getMonth()];
		return dateNew+this.datenth(dateNew)+" "+month+" "+splitDate[0];
	}
	formatDate(date){
		var splitDate = date.split("/");
		var fortnightAway = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]), dateNew = fortnightAway.getDate(),
		month = "January,February,March,April,May,June,July,August,September,October,November,December".split(",")[fortnightAway.getMonth()];
		return dateNew+this.datenth(dateNew)+" "+month;
	}
	handlePickupDeliveryChange(e) {
		if(e.target.value === 'pickup'){
			this.setState({product_type:'pickup',deliveryAreaNotAvailable:false});
		}
		if(e.target.value === 'delivery'){
			this.setState({product_type:'delivery'}, () => {
				this.deliveryCharge();
			});			
		}
	}	 
	stepsToDetails(){
		this.setState({your_details:true,payment_details:false,verification:false});
	}
	stepsToPay(){       
		if(this.state.borro_firstname === undefined || this.state.borro_firstname === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Enter First Name');               
            });
            return false;
		}
		if(this.state.borro_lastname === undefined || this.state.borro_lastname === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Enter Last Name');               
            });
            return false;
		}
		if(this.state.pickupNew === undefined || this.state.pickupNew === '' || this.state.deliveryNew === undefined || this.state.deliveryNew === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Choose Pickup or Delivery');               
            });
            return false;
		}
		if(this.state.borro_address_line1 === undefined || this.state.borro_address_line1 === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Enter Address Line 1');               
            });
            return false;
		}
		/*if(this.state.borro_address_line2 === undefined || this.state.borro_address_line2 === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Enter Address Line 2');
            });
            return false;
		}*/
		if(this.state.city === undefined || this.state.city === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Enter City');               
            });
            return false;
		}
		if(this.state.userPostcode === undefined || this.state.userPostcode === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Enter Postcode');               
            });
            return false;
		}
		if(this.state.borro_firstname && this.state.borro_lastname && this.state.borro_address_line1 && this.state.city && this.state.userPostcode){
			
			var _this = this;
			axios({
				method: 'post',
				url: Path.PRODUCT_URL + Path.URL.BORROW_CLIENT_SECRET_KEY,
				headers: {
					'Api-Token':'Bearer '+_this.state.auth_token
				},
				data:{
					rent_from:_this.state.rent_from,
					rent_to:_this.state.rent_to,
					productId:_this.state.lending_product_id,
					product_type:_this.state.product_type,
					delivery_charge:_this.state.deliveryCharge,
					borro_firstname:_this.state.borro_firstname,
					borro_lastname:_this.state.borro_lastname,
					borro_address_line1:_this.state.borro_address_line1,
					borro_address_line2:_this.state.borro_address_line2,
					city:_this.state.city,
					postcode:_this.state.postcode
				}
			})
			.then(function (response) {
				//console.log(response.data.intent_client_secret)
				_this.setState({intent_client_secret:response.data.intent_client_secret,your_details:false,payment_details:true,verification:false,showAddCard:true});
				window.scrollTo(0, 0);
			})
			.catch(function (error) {
				//console.log(error);
			});

		}
	}
	stepsToVerify(){
		this.setState({your_details:false,payment_details:false,verification:true});
	}
	goToLogin(){
		$(".weborro_modal").removeClass('display-none').addClass("display-block");
	}
    componentDidMount() {
		if(reactLocalStorage.get('user_id') === undefined || reactLocalStorage.get('user_id') === ''){
			this.setState({showLogin:true});
			$("#login_check_to_allow #showLogin").removeClass("hide");
		}
		else{
			$("#login_check_to_allow #showLogin").addClass('hide')
			$("#login_check_to_allow #allowUser").removeClass("hide");
		}
		this.loadData();
		setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    render(){
		const {viewport} = this.state;
		const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
			slidesToScroll: 1
		};
		const reviewWrapper = this.state.getReview.map(function(item,o){
			return(
				<div className="menu_pro_reviews_info" key={o}>
					<div className="menu_pro_reviews_infoUser">
						<img className="" src={item.reviewer.profile_image} alt="profile image" onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PROFILE_IMAGE}}/>
					</div>
					<div className="menu_pro_reviews_infoHead">
						<h4>{item.reviewer.name}</h4>
						<p>{this.formatDateStar(item.created.split(" ")[0])}</p>
					</div>
					<div className="menu_pro_reviews_infoStar">
						<span className="star_rating_wrap">
							<span className="star_rating_active" style={{width:(item.rating*20)+'%'}}></span>
						</span>
					</div>
					<div className="menu_pro_reviews_infoText">
						{item.comments}
					</div>
				</div>
			)
		},this);
        const renderTopCategoryList = this.state.product.map(function(item, i) {
            return(
                <div className="col-xs-12 no-padding" key={i}>
                    <h3 className="ho_renshop_sH">{item.sub_category_name}</h3>
                    <div className="col-xs-12 no-padding">
                        <div className="row">
                            <Slider {...settings}>
                                {item.product.map(function(subitem, j) {
                                    return(
                                        <div key={j} className="col-sm-4 col-md-3">
                                            <div className="ho_renshop_p">
                                                <div className="ho_renshop_pB">
                                                    {subitem.product_images.slice(0,1).map(function(images, m) {
                                                        return(
                                                        <img key={m} src={Path.CLOUDINARY.url+'/c_scale,h_270,w_360/'+images.image_url.replace(/^.*[\\/]/, '')} onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_IMAGE}} />
                                                        )
                                                    })}
                                                    <span className="ho_renshop_pB_amount">
                                                        <span className="ho_renshop_pB_amountCo">
                                                        <div>from only </div>
                                                        <span className="currency"><i className="fa fa-gbp"></i>{subitem.price_day}<br/></span>
                                                        <div>per day</div>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="ho_renshop_c">
                                                    <div className="ho_renshop_cH">{subitem.product_name}</div>
                                                    <div className="ho_renshop_cM">£{subitem.price_day} a day <a>or see offers</a></div>
                                                    <div className="ho_renshop_cS">{subitem.postcode}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })} 
                            </Slider>  
                        </div>
                    </div>
                </div>
            )
		},this);
		const isDayBlocked = momentDate => {
			const dayString = momentDate.format('MM/DD/YYYY');
			let dateArr = [];
			this.state.available_date.map(function(availdates, i) {
				var start = new Date(availdates.fromDate);
				var end = new Date(availdates.toDate);				
				while(start <= end){
					dateArr.push(moment(start).format('MM/DD/YYYY'));
					var newDate = start.setDate(start.getDate() + 1);
					start = new Date(newDate);  
				}
			},this);
			if(dateArr.includes(dayString)){
				return dayString;
			}
		}
        return(
			this.state.dataSuccess === true ?
            <div className="main_wrapper">
				{this.state.showError === 'success' &&
                <div className="flashmessage show"><i className="fa fa-check"></i><span id="success_msg"></span></div>}
                {this.state.showError === 'error' &&
                <div className="flashmessage-error show-error"><i className="fa fa-close"></i><span id="error_msg"></span></div>
                }   
				{this.state.editUserInfo !== true ?
				<section className="menu_bg">
					<div className="container">
						<div className="col-md-6 col-xs-12 menu_bg_right" id="login_check_to_allow">
							<Mobile>
								<div className="col-md-6 col-xs-12 menu_bg_left">
									<ul className="menu_pro_steps">
										<li><a className="active">{this.state.category_name}</a></li>
										<li>></li>
										<li><a>{this.state.sub_category_name}</a></li>
										<li>></li>
										<li><a>{this.state.sibling_category_name}</a></li>
									</ul>
								</div>	
							</Mobile>
							<h2>{this.state.product_name}</h2>
							<Mobile>
								<div className="col-md-6 col-xs-12 menu_bg_left">
									<div className="col-xs-12 no-padding">
										<div className="menu_pro_tabCont">
											<div className="col-xs-12 no-padding">										
												<ImageGallery 
													items={this.state.product_image_slide}
													showPlayButton={false}
													showIndex={true}
													indexSeparator=" of "
													showFullscreenButton={true}
													useTranslate3D={true}
													thumbnailPosition="right"
												/>
												<span className="ho_renshop_pB_amountNew item_price_on_image">
													{this.state.rentmode == 'Yes' ?
													<span className="ho_renshop_pB_amountCoNew">
														<div>from only </div>
														<span className="currency"><i className="fa fa-gbp"></i>{this.state.price_day}<br/></span>
														<div>per day</div>
													</span>
													:
													<span className="ho_renshop_pB_amountCoNew">
														<div>only</div>
														<span className="currency"><i className="fa fa-gbp"></i>{this.state.product_worth}<br/></span>
														<div>worth</div>
													</span>
													}
												</span>
											</div>
										</div>
									</div>
								</div>	
							</Mobile>
							<div className="menu_bg_right_plan">
								<div className="menu_bg_right_planS">
									<h4>{this.state.postcode}</h4>
									<p>{this.state.location}</p>
								</div>
								{this.state.rentmode == 'Yes' ?
								<div className="menu_bg_right_planS">									
									<h4>£ {this.state.price_day}</h4>
									<p>a day</p>
								</div>
								:
								<div className="menu_bg_right_planS">									
									<h4>£ {this.state.product_worth}</h4>
									<p>Price</p>
								</div>
								}
							</div>
							<div className="col-xs-12 no-padding hide" id="showLogin">
								<a className="menu_bg_right_rentBtn" onClick={e => this.goToLogin(e)}>Login to Rent</a>
							</div>	
							<div id="allowUser" className="hide">								
								{reactLocalStorage.get('user_id') != this.state.lender_id ?													
								<div className="menu_bg_right_rentBor relative">
									{this.state.rentmode == 'Yes' ?
									<div className="col-xs-12 no-padding"> 
										<h3 className="ho_renshop_sH line_height_50"><img className="check_availablity_wrapper_left" src={Path.IMAGE_PATH+"/calendar.png"}/> When do you want it?</h3>
										<div className="col-xs-12 no-padding">
											<Mobile>
											{this.state.available_date_status === 'Yes' ? 
												<DateRangePicker 
												startDate={this.state.startDate} // momentPropTypes.momentObj or null,
												startDateId="startDateId" // PropTypes.string.isRequired,
												endDate={this.state.endDate} // momentPropTypes.momentObj or null,
												endDateId="endDateId" // PropTypes.string.isRequired,
												onDatesChange={({ startDate, endDate }) => this.daysLeft(startDate, endDate)} // PropTypes.func.isRequired,
												focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
												onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
												customArrowIcon={<img src={Path.IMAGE_PATH+"/date_arrow.png"}/>}
												isDayBlocked={isDayBlocked}		
												displayFormat="DD/MM/YYYY"
												orientation={VERTICAL_ORIENTATION}
												withFullScreenPortal={true}
												/>
												:
												<DateRangePicker 
												startDate={this.state.startDate} // momentPropTypes.momentObj or null,
												startDateId="startDateId" // PropTypes.string.isRequired,
												endDate={this.state.endDate} // momentPropTypes.momentObj or null,
												endDateId="endDateId" // PropTypes.string.isRequired,
												onDatesChange={({ startDate, endDate }) => this.daysLeft(startDate, endDate)} // PropTypes.func.isRequired,
												focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
												onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
												customArrowIcon={<img src={Path.IMAGE_PATH+"/date_arrow.png"}/>}
												displayFormat="DD/MM/YYYY"
												orientation={VERTICAL_ORIENTATION}
												withFullScreenPortal={true}
												/>
											}										
											</Mobile>
											<Default>
											{this.state.available_date_status === 'Yes' ? 
												<DateRangePicker 
												startDate={this.state.startDate} // momentPropTypes.momentObj or null,
												startDateId="startDateId" // PropTypes.string.isRequired,
												endDate={this.state.endDate} // momentPropTypes.momentObj or null,
												endDateId="endDateId" // PropTypes.string.isRequired,
												onDatesChange={({ startDate, endDate }) => this.daysLeft(startDate, endDate)} // PropTypes.func.isRequired,
												focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
												onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
												customArrowIcon={<img src={Path.IMAGE_PATH+"/date_arrow.png"}/>}
												isDayBlocked={isDayBlocked}		
												displayFormat="DD/MM/YYYY"
												/>
												:
												<DateRangePicker 
												startDate={this.state.startDate} // momentPropTypes.momentObj or null,
												startDateId="startDateId" // PropTypes.string.isRequired,
												endDate={this.state.endDate} // momentPropTypes.momentObj or null,
												endDateId="endDateId" // PropTypes.string.isRequired,
												onDatesChange={({ startDate, endDate }) => this.daysLeft(startDate, endDate)} // PropTypes.func.isRequired,
												focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
												onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
												customArrowIcon={<img src={Path.IMAGE_PATH+"/date_arrow.png"}/>}
												displayFormat="DD/MM/YYYY"
												/>
											}										
											</Default>
										</div>
										<div className="menu_pro_plan">
											<div className="menu_pro_planSNewRight">
												<span className="ho_renshop_pB_amountNew">
													<span className="ho_renshop_pB_amountCoNew">
														<div>from only </div>
														<span className="currency"><i className="fa fa-gbp"></i>{this.state.price_day}<br/></span>
														<div>per day</div>
													</span>
												</span>
											</div>
											<div className="menu_pro_planSNewRight">
												<span className="ho_renshop_pB_amountNew">
													<span className="ho_renshop_pB_amountCoNew">
														<div>from only </div>
														<span className="currency"><i className="fa fa-gbp"></i>{this.state.price_week}<br/></span>
														<div>per week</div>
													</span>
												</span>
											</div>
											<Mobile>
											<div className="menu_pro_planSNewRight buy_now_or_text">Or why not buy it now</div>
											</Mobile>
											<Default>
											{this.state.allowBuymode === true && <div className="menu_pro_planSNewRight buy_now_or_text">OR</div> }
											</Default>
											{this.state.allowBuymode === true &&									
												<div className="menu_pro_planSNewRight">
													<a href={Path.BASE_URL+Path.PAGE_NAME.BuyProduct+'/'+this.state.lending_product_id} className="text-center buy_now_btn_right m-t-20">
														<span>Buy Now</span>
														<span className="white_color"><i className="fa fa-gbp"></i> {this.state.product_worth}</span>										
													</a>
												</div>
											}
										</div>
										{this.state.checkAvail === true ?
										''
										:
										<div className="menu_bg_right_rentDea">
											<div className="menu_bg_right_rentDea_days">You are renting for {this.state.days} days</div>
											<div className="menu_bg_right_rentDea_am">
												<div className="menu_bg_right_rentDea_amL">£ {this.state.price_day} x {this.state.days} Days:</div>
												<div className="menu_bg_right_rentDea_amR">£ {(Number(this.state.price_day) * Number(this.state.days)).toFixed(2)}</div>
											</div>
											{this.state.deliveryEnable === true &&
											<div className="menu_bg_right_rentDea_am">
												<div className="menu_bg_right_rentDea_amL">Delivery Fee:</div>
												<div className="menu_bg_right_rentDea_amR">£ {Number(this.state.deliveryCharge).toFixed(2)}</div>
											</div>
											}
											<div className="menu_bg_right_rentDea_am menu_bg_right_rentDea_amT">
												<div className="menu_bg_right_rentDea_amL">Total Price:</div>
												<div className="menu_bg_right_rentDea_amR">£ {((Number(this.state.deliveryCharge) + (Number(this.state.price_day) * Number(this.state.days)))).toFixed(2)}</div>
											</div>
										</div>
										}
										{this.state.checkAvail !== true && 
										<div className="col-xs-12 no-padding">
											<a className="menu_bg_right_rentBtn" onClick={e => this.requestRent(e)}>Request to rent</a>
										</div>
										}
									</div>										
									:
									<div className="col-xs-12 no-padding">
										<a className="buy_now_btn_right col-xs-12 text-center" href={Path.BASE_URL+Path.PAGE_NAME.BuyProduct+'/'+this.state.lending_product_id}>Buy Now</a>
									</div>
									}
								</div>
								:
								<div className="menu_bg_right_rentBor">
									<a className="menu_bg_right_rentBtn no-margin-top" href={Path.BASE_URL+Path.PAGE_NAME.MyItems}>View My Items</a>
								</div>
								}
							</div>
							<div className="menu_bg_right_wholen">
								<h3 className="ho_renshop_sH">Meet {this.state.userName} {this.state.userLastName}</h3>
								<div className="menu_bg_right_wholen_info">
									<a className="menu_bg_right_wholen_infouser" href={Path.BASE_URL+Path.PAGE_NAME.ViewProfile+'/'+this.state.lender_id}>
										<img className="" src={this.state.userImage} onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_IMAGE}} alt="profile image"/>
									</a>
									<div className="menu_bg_right_wholen_infoHead">
										<h4>{this.state.userName} {this.state.userLastName}</h4>
										<div className="menu_bg_right_wholen_infostar">
											<span className="star_rating_wrap"><span className="star_rating_active" style={{width:(this.state.rating*20)+'%'}}></span></span>
											<a  href={Path.BASE_URL+Path.PAGE_NAME.ViewProfile+'/'+this.state.lender_id} className="menu_bg_right_wholen_infostar_read">Read my {this.state.ratingCount} reviews</a>
										</div>
									</div>
								</div>
								<div className="col-xs-12 no-padding menu_bg_right_wholenmessage">
									<a href={Path.BASE_URL+Path.PAGE_NAME.MessageToLender+'/'+Path.PRODUCT_ID} className="menu_bg_right_whoMsglen">Message lender</a>
								</div>								
							</div>
						</div>
						<div className="col-md-6 col-xs-12 menu_bg_left">
							<Default>	
								<ul className="menu_pro_steps">
									<li><a className="active">{this.state.category_name}</a></li>
									<li>></li>
									<li><a>{this.state.sub_category_name}</a></li>
									<li>></li>
									<li><a>{this.state.sibling_category_name}</a></li>
								</ul>												
								<div className="col-xs-12 no-padding">
									<div className="menu_pro_tabCont">
										<div className="col-xs-12 no-padding">										
											<ImageGallery 
												items={this.state.product_image_slide}
												showPlayButton={false}
												showIndex={true}
												indexSeparator=" of "
												showFullscreenButton={true}
												useTranslate3D={true}
												thumbnailPosition="right"
											/>
											<span className="ho_renshop_pB_amountNew item_price_on_image">
												{this.state.rentmode == 'Yes' ?
												<span className="ho_renshop_pB_amountCoNew">
													<div>from only </div>
													<span className="currency"><i className="fa fa-gbp"></i>{this.state.price_day}<br/></span>
													<div>per day</div>
												</span>
												:
												<span className="ho_renshop_pB_amountCoNew">
													<div>only</div>
													<span className="currency"><i className="fa fa-gbp"></i>{this.state.product_worth}<br/></span>
													<div>worth</div>
												</span>
												}
											</span>
										</div>
									</div>
								</div>	
							</Default>
							<div className="menu_pro_items">
								<h3 className="ho_renshop_sH">What's the item?</h3>
								{this.state.description.length > 1000 ?
								<Default>	
								{this.state.readmoreClass === true ?
								<p>{this.state.description}</p>
								:
								<p>{this.state.description.substring(0,1000)}...</p>
								}
								<span className="pointer no-margin menu_bg_right_wholen_infostar_read pull-right" onClick={e => this.toggle(e)}>{this.state.readmoreClass === true ? 'Read Less' : 'Read More'}</span>
								</Default>
								:
								<Default>
								<p>{this.state.description}</p>
								</Default>
								}								
								<Mobile>	
								<p className={this.state.readmoreClass === true ? "" : "text-overflow"}>{this.state.description}</p>
								<span className="pointer no-margin menu_bg_right_wholen_infostar_read pull-right" onClick={e => this.toggle(e)}>{this.state.readmoreClass === true ? 'Read Less' : 'Read More'}</span>
								</Mobile>
							</div>
							<div className="menu_pro_items">
								<h3 className="ho_renshop_sH">Location</h3>
								<p>{this.state.postcode} ({this.state.location})</p>
								<MapGL
									{...viewport}
									width="100%"
									height="300px"
									mapStyle="mapbox://styles/muthuiscse/cjutx1j9c4tlw1fph0ioh1qmt"
									mapboxApiAccessToken={TOKEN} 
									>									
									<div className="fullscreen" style={fullscreenControlStyle}>
									<FullscreenControl />
									</div>
									<div className="nav" style={navStyle}>
									<NavigationControl onViewportChange={this.updateViewport} />
									</div>
									<Marker latitude={this.state.viewport.latitude} longitude={this.state.viewport.longitude} offsetLeft={-42} offsetTop={-42} >
										<span className="marker_image"></span>
									</Marker>
								</MapGL>
							</div>
							{this.state.getReviewShow === true &&
							<div className="menu_pro_reviews">
								<h3 className="ho_renshop_sH">Top 5 Reviews for the lender</h3>								
								{reviewWrapper}
							</div>
							}
						</div>
						{renderTopCategoryList}
					</div>					
				</section>
				:
				<section className="menu_bg">
					<div className="container">
						<div className="col-xs-12 no-padding check_steps">
							<Mobile>
							<ul className="stepsListTopUl">
								<li><a>1</a></li>
								<li className={this.state.your_details === true ? "active" : '' }><a data-href="your_details">2</a></li>
								<li className={this.state.payment_details === true ? "active" : ''  }><a data-href="payment_details">3</a></li>
								<li className={this.state.verification === true ? "active" : ''  }><a data-href="verification">4</a></li>
							</ul>
							</Mobile>
							<Default>
							<ul>
								<li><a>1. Select dates</a></li>
								<li className={this.state.your_details === true ? "active" : '' }><a data-href="your_details">2. Your details</a></li>
								<li className={this.state.payment_details === true ? "active" : ''  }><a data-href="payment_details">3. Payment details</a></li>
								<li className={this.state.verification === true ? "active" : ''  }><a data-href="verification">4. Verification</a></li>
							</ul>
							</Default>
						</div>
						<div className={this.state.verification !== true ? "col-md-7 col-xs-12 check_left" : "col-xs-12 check_left"}>
							<div className="tab-content">
								{this.state.your_details === true &&
								<div id="your_details" className="tab-pane fade in active">
									<form className="col-xs-12 no-padding check_left_form">
										<div className="check_left_formHead">Your details</div>
										<div className="form-group clearfix">
											<div className="col-sm-6 col-xs-12 check_left_formPaddLeft">
												<label className="control-label">First name</label>
												<input className="form-control" readOnly type="text" onChange={e => this.handleChange(e)} name="borro_firstname" value={this.state.borro_firstname} />
											</div>
											<div className="col-sm-6 col-xs-12 check_left_formPaddRight">
												<label className="control-label">Last name</label>
												<input className="form-control" readOnly type="text" onChange={e => this.handleChange(e)} name="borro_lastname" value={this.state.borro_lastname} />
											</div>
										</div>
										{this.state.deliveryAvailable === true &&
										<div className="form-group clearfix">
                                            <input id="pickupOption" type="radio" className="create_pro_leftPriceSI" defaultChecked name="product_type" value={this.state.pickupNew} onChange={e => this.handlePickupDeliveryChange(e)}/>
                                            <label htmlFor="pickupOption" className="create_pro_leftPriceSL"><span>Pickup</span></label>
                                            <input id="deliveryOption" type="radio" className="create_pro_leftPriceSI" name="product_type" value={this.state.deliveryNew} onChange={e => this.handlePickupDeliveryChange(e)}/>
                                            <label htmlFor="deliveryOption" className="create_pro_leftPriceSL"><span>Delivery</span></label>
											{this.state.deliveryAreaNotAvailable !== false &&
												<div className="delivery_error">Due to being outside of lenders delivery range you are only allowed to collect it.</div>
											}
                                        </div>
										}
										<div className="check_left_formHead m-t-40">{this.state.product_type === 'delivery' ? 'Delivery / Billing Address' : 'Pickup / Billing Address'}</div>
										<div className="form-group clearfix">
											<div className="col-xs-12 no-padding">
												<label className="control-label">Address line 1</label>
												<input className="form-control" type="text" onChange={e => this.handleChange(e)} name="borro_address_line1" value={this.state.borro_address_line1 || ''} />
											</div>
										</div>
										<div className="form-group clearfix">
											<div className="col-xs-12 no-padding">
												<label className="control-label">Address line 2 (optional)</label>
												<input className="form-control" type="text" onChange={e => this.handleChange(e)} name="borro_address_line2" value={this.state.borro_address_line2 || ''} />
											</div>
										</div>
										<div className="form-group clearfix">
											<div className="col-xs-12 no-padding">
												<label className="control-label">City</label>
												<input className="form-control" type="text" onChange={e => this.handleChange(e)} name="city" value={this.state.city || ''} />
											</div>
										</div>
										<div className="form-group clearfix">
											<div className="col-xs-12 no-padding">
												<label className="control-label">Postcode</label>
												<input className="form-control" type="text" onChange={e => this.handlePostCodeChange(e)} name="userPostcode" value={this.state.userPostcode || ''} />
											</div>
										</div>
										<div className="form-group clearfix">
											<a className={this.state.deliveryAreaNotAvailable === true ? "check_left_form_save disabledbtn" : "check_left_form_save"} onClick={e => this.stepsToPay(e)}>Save &  Continue</a>
										</div>
									</form>
								</div>
								}
								{this.state.payment_details === true &&
								<div id="payment_details" className="tab-pane fade in active">
									<h3 className="ho_renshop_sH">Payment details</h3>
									<p>Must be the card of the person renting the item.</p>
									<div className="col-xs-12 no-padding check_left_form">
										<div className="form-group clearfix">
											<StripeProvider apiKey={this.props.stripe_api_key}>
												<Elements>
													<CheckoutForm 
														user_stripe_account_id={this.state.user_stripe_account_id}
														stripe_secret_key={this.props.stripe_secret_key}
														handleResult={this.props.handleResult} 
														rent_from={this.state.rent_from}
														rent_to={this.state.rent_to}
														productId={this.state.lending_product_id}
														product_type={this.state.product_type}
														delivery_charge={this.state.deliveryCharge}
														//stripe_customer={reactLocalStorage.get('stripe_customer')}
														intent_client_secret={this.state.intent_client_secret}
														borro_firstname={this.state.borro_firstname}
														borro_lastname={this.state.borro_lastname}
														borro_address_line1={this.state.borro_address_line1}
														borro_address_line2={this.state.borro_address_line2}
														city={this.state.city}
														postcode={this.state.postcode}
													/>
												</Elements>
											</StripeProvider>
										</div>
										<div className="form-group clearfix">
											<span className="check_left_form_lock"><i className="fa fa-lock"></i>Secure payment</span>
										</div>										
									</div>
								</div>
								}
							</div>
						</div>						
						{this.state.verification !== true &&
						<div className="col-md-5 col-xs-12 menu_bg_right">
							<div className="menu_bg_right_rentBor">
								<div className="mess_leder_pro_dea">
									<div className="mess_leder_pro_deaImg">
										{this.state.product_image_view === undefined ? 
										<img className="" src={Path.NO_PRODUCT_IMAGE} alt="profile image"/>
										:
										<img className="" src={Path.CLOUDINARY.url+'/c_scale,h_270,w_360/'+this.state.product_image_view.image_url.replace(/^.*[\\/]/, '')} onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_IMAGE}} alt="profile image"/>
										}
									</div>
									<div className="mess_leder_pro_deaName">
										<h4>{this.state.product_name}</h4>
										<span>From {this.state.userName} {this.state.userLastName}</span>
										<div className="col-xs-12 no-padding m-t-10">
											<span className="star_rating_wrap"><span className="star_rating_active" style={{width:(this.state.rating*20)+'%'}}></span></span>
										</div>
									</div>
								</div>
								<div className="col-xs-12 no-padding check_date_show">
									<div className="check_date_Startshow">
										<span>{this.formatDate(this.state.rent_from)}</span>
									</div>
									<span className="check_date_Arrowshow"><img src={Path.IMAGE_PATH+"/check_arrow.png"}/></span>
									<div className="check_date_Endshow">
										<span>{this.formatDate(this.state.rent_to)}</span>
									</div>
								</div>
								<div className="menu_bg_right_rentDea">
									<div className="menu_bg_right_rentDea_am">
										<div className="menu_bg_right_rentDea_amL">£ {this.state.price_day} x {this.state.days} Days:</div>
										<div className="menu_bg_right_rentDea_amR">£ {(Number(this.state.price_day) * Number(this.state.days)).toFixed(2)}</div>
									</div>
									{this.state.deliveryEnable === true &&
									<div className="menu_bg_right_rentDea_am">
										<div className="menu_bg_right_rentDea_amL">Delivery Fee:</div>
										<div className="menu_bg_right_rentDea_amR">£ {Number(this.state.deliveryCharge).toFixed(2)}</div>
									</div>
									}
									{this.state.deliveryAreaNotAvailable === false &&
									<div className="menu_bg_right_rentDea_am menu_bg_right_rentDea_amT check_total">
										<div className="menu_bg_right_rentDea_amL check_total_left">Total Price:</div>
										<div className="menu_bg_right_rentDea_amR check_total_right">£ {(Number(this.state.deliveryCharge) + (Number(this.state.price_day) * Number(this.state.days))).toFixed(2)}</div>
									</div>
									}
								</div>
							</div>
							<div className="check_right_text m-t-20">You are liable for any loss or damages you cause to the item. <a onClick={e => this.liabilityModal(e)}>Read more.</a></div>
							<div className="check_right_text">You cannot cancel within 48 hours of the start of the rental. <a onClick={e => this.cancellationModal(e)}>See cancellation policy.</a></div>
						</div>
						}
						<div className="col-xs-12">
							<div id="verification" className="text-center tab-pane fade">
								<h3 className="ho_renshop_sH">Verification</h3>
								<p>Order Placed successfully</p>
								<div className="col-xs-12 text-center m-t-40">
									<div className="watchlist_contact_text">Click the button below to see your order history.</div>
									<a href={Path.BASE_URL+Path.PAGE_NAME.MyRentals} className="watchlist_contact_btn">Go to My Rentals</a>
								</div>
							</div>
							<div id="verificationCancel" className="text-center tab-pane fade">
								<h3 className="ho_renshop_sH">Verification</h3>
								<p>Order Cancelled</p>
								<div className="col-xs-12 text-center m-t-40">
									<div className="watchlist_contact_text">Order Cancelled due to invalid transaction.</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				}
				<div className={this.state.cancellation_rules == true ? 'weborro_modal_inner display-block' : 'weborro_modal_inner display-none'}>
                    <section className='weborro_modal_wrapper loginDesi'>
                        <button type="button" className="close" onClick={e => this.hideModal('cancellation_rules')}>&times;</button>
						<div className="log_H">Cancellation policy</div>
						<div className="clearfix"></div>
						<p>Users that wish to cancel an item for rent, 48hours before the rental date, will lose 1 day's worth of rent according to the rental rate stipulated by that item listing page. This has been put in place to help ensure the user renting out the item has a reduced financial impact.</p>
                    </section>
                </div>
				<div className={this.state.liability == true ? 'weborro_modal_inner display-block' : 'weborro_modal_inner display-none'}>
                    <section className='weborro_modal_wrapper loginDesi'>
                        <button type="button" className="close" onClick={e => this.hideModal('liability')}>&times;</button>
						<div className="log_H">Personal Liability</div>
						<div className="clearfix"></div>
						<p>Weborro will not be held accountable for any loss or damages of rental items that will exchanged between renter and borrower.</p>
                    </section>
                </div>
			</div>
			:
			<div className="main_wrapper">
				<div className="no_product_detail_found">
					<div className="col-xs-12"><img src={Path.IMAGE_PATH+"/404-mug.jpg"} width="300"/></div>					
					<div className="col-xs-12 m-t-20">Sorry - item cannot be found</div>
				</div>
			</div>			
		);
    }
}
export default ProductDetails;