import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import {reactLocalStorage} from 'reactjs-localstorage';
import Path from './Path';
import Sidebar from './Sidebar';
class Toolkit extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
            auth_token : reactLocalStorage.get('auth_token')
        }
    }
    componentDidMount() {
        setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    render(){
        return(
            <div>               
                <div className="main_wrapper">
                    <section className="dashboard_bg">
                        <Sidebar/>
                        <div className="content-wrapper">
                            <div className="content-height-wrapper">
                                <div className="tab-content">
                                    <div className="col-xs-12 no-padding">
                                        <div className="dashboardHead">Invite a friend. Earn £20 cash.</div>
                                        <div className="dashboardCont">Give a friend £20 off their first rental by sharing your link. We’ll send you £10 cash when they borrow for the first time and another £10 when they lend.</div>
                                        <div className="toolkit_share">
                                            <span className="toolkit_share_url">weborro.com/r/prab-b77ff</span>
                                            <span className="toolkit_share_copy">Copy</span>
                                            <span  data-toggle="modal" data-target="#share_dash" className="toolkit_share_btn">Share</span>
                                        </div>
                                        <div className="dashboardHead">Generate promocodes...</div>
                                        <div className="dashboardCont">You can generate your own promocodes to offer to your borrowers. The discount will come from your profit, but is great for persuading people to request who otherwise might not.</div>
                                        <div className="toolkit_dis">
                                            <div className="toolkit_disTitle1">
                                                <label className="control-label">% Discount</label>
                                                <input type="text" className="form-control"/>
                                            </div>
                                            <div className="toolkit_disTitle2">
                                                <label className="control-label">Valid until</label>
                                                <button type="button" className="toolkit_dis_valid">Forever</button>
                                            </div>
                                            <div className="toolkit_disTitle3">
                                                <button type="button" className="toolkit_dis_code">Generate code</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ho_footer inner_footer myaccount_footer m-t-40">
                                <div className="col-xs-12">
                                    <div className="ho_foot_b">
                                        <div className="ho_foot_b_L">weborro limited 2018</div>
                                        <div className="ho_foot_b_WT">Made withlove by the weborro team</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div> 
        );
    }
}
export default Toolkit;