import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import Geocode from "react-geocode";
import Autocomplete from 'react-google-autocomplete';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {Image, Video, Transformation, CloudinaryContext} from 'cloudinary-react';
import {reactLocalStorage} from 'reactjs-localstorage';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import Path from './Path';



const google = window.google = window.google ? window.google : {}
let radiusKey = 1;
class ProductAdd extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
            auth_token : reactLocalStorage.get('auth_token'),
            category:[],
            sub_category:[],
            sibling_category:[],
            pickup:false,
            delivery:false,
            product_name:'',
            delivery_area:[],
            description:'',
            price_day:'',
            price_week:'',
            postcode:'',
            // pickup:'',
            // delivery:'',
            cat_id:'',
            subcat_id:'',
            sibling_id:'',
            product_image:[],
            latitude:'',
            longitude:'',
            image_url_link:'',
            addRadiusBtn:true,
            miles_0:'',
            charge_0:'',
            miles_1:'',
            charge_1:''
        }
        this.goToNextScreen = this.goToNextScreen.bind(this);
        this.productImageUpload = this.productImageUpload.bind(this);
        this.handleChange = this.handleChange.bind(this);    
        this.getCategoryList = this.getCategoryList.bind(this);
        this.getSubCategoryList = this.getSubCategoryList.bind(this);
        this.getSiblingCategoryList = this.getSiblingCategoryList.bind(this);
        this.getCategory = this.getCategory.bind(this);
        this.getSubCategory = this.getSubCategory.bind(this);
        this.getSiblingCategory = this.getSiblingCategory.bind(this);
        this.stepFirst = this.stepFirst.bind(this);
        this.stepSecond = this.stepSecond.bind(this); 
        this.goToPrevScreen = this.goToPrevScreen.bind(this);
        this.handleDayPriceChange = this.handleDayPriceChange.bind(this);
        this.handleWeekPriceChange = this.handleWeekPriceChange.bind(this);
        this.handlePostCodeChange = this.handlePostCodeChange.bind(this);
        this.handlePickupDeliveryChange = this.handlePickupDeliveryChange.bind(this);
        this.initMap = this.initMap.bind(this);
        this.setRadius = this.setRadius.bind(this);
        this.addRadius = this.addRadius.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadData = this.loadData.bind(this);
        this.handleNumberChange = this.handleNumberChange.bind(this);
        this.handleMilesChange = this.handleMilesChange.bind(this);
        this.deleteItemImage = this.deleteItemImage.bind(this);
        this.recieveCheckbox = this.recieveCheckbox.bind(this);
    }

    recieveCheckbox(e){
        var _this = this;
        _this.setState({
            [e.target.name]: e.target.value
        });
        if(e.target.checked){
            if(e.target.name == 'rent'){
                _this.setState({rent:'Yes'});
            }
            if(e.target.name == 'buy'){
                _this.setState({buy:'Yes'});
            }
        }
        else{
            if(e.target.name == 'rent'){
                _this.setState({rent:'No'});
            }
            if(e.target.name == 'buy'){
                _this.setState({buy:'No'});
            }
        }
    }

    handleChange(e) {
        this.setState({showError:''});
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleMilesChange(e) {
        this.setState({showError:''});
        if(e.target.name == 'delivery_area[0][radius]'){
            this.setState({
                miles_0 : e.target.value
            });
        }
        if(e.target.name == 'delivery_area[1][radius]'){
            this.setState({
                miles_1 : e.target.value
            });
        }
        if(e.target.name == 'delivery_area[0][delivery_charge]'){
            this.setState({
                charge_0 : e.target.value
            });
        }
        if(e.target.name == 'delivery_area[1][delivery_charge]'){
            this.setState({
                charge_1 : e.target.value
            });
        }
    }
    handleNumberChange(e){      
        var theEvent = e || window.event;
        if (theEvent.type === 'paste') {
        key = e.clipboardData.getData('text/plain');
        } else {
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;
        if( !regex.test(key) ) {
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }  
    goToNextScreen(a,b){
        $('#'+a).removeClass('in').removeClass('active');
        $('#'+b).addClass('in').addClass('active');
    }
    goToPrevScreen(a,b){
        $('#'+a).addClass('in').addClass('active');
        $('#'+b).removeClass('in').removeClass('active');
    }
    componentDidMount() {
        this.getCategoryList();
        this.loadData();
        if(reactLocalStorage.get('auth_token') === undefined){
            window.location = Path.BASE_URL+Path.PAGE_NAME.Home;
        }
        if($(".radius_wrapper").length >= 1){
            this.setState({addRadiusBtn:false});
        }
        setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    productImageUpload(){
        var images = ''; 
        var imagesLink = $("#product_image").val()+',';
        var widget = window.cloudinary.openUploadWidget({
            cloudName: Path.CLOUDINARY.cloudName,
            apiKey: Path.CLOUDINARY.apiKey,
            uploadPreset: Path.CLOUDINARY.uploadPreset,
            cropping: false,
            folder:Path.CLOUDINARY.folder,
            sources:['local','url'],
            croppingAspectRatio:'1',
            croppingDefaultSelectionRatio:'1.0',
            croppingShowDimensions:true,
            multiple: true,
            maxFileSize:'10000000',
            croppingValidateDimensions:true,
            //thumbnails:"#product_image_view",
            //thumbnailTransformation:{ width: 90, height: 90, crop: 'fill' },
            maxFiles: '5',
            resourceType: 'image',
            styles:{
                palette: {
                    window: "#FFF",
                    windowBorder: "#90A0B3",
                    tabIcon: "#0E2F5A",
                    menuIcons: "#5A616A",
                    textDark: "#000000",
                    textLight: "#FFFFFF",
                    link:  "#0078FF",
                    action:  "#FF620C",
                    inactiveTabIcon: "#0E2F5A",
                    error: "#F44235",
                    inProgress: "#0078FF",
                    complete: "#20B832",
                    sourceBg: "#E4EBF1"
                },
                fonts: {
                    "Lato": "https://fonts.googleapis.com/css?family=Lato:400,700,900,900i",
                }
            }
        }, (error, result) => {
            if (result && result.event === "success") {
                images = result.info.secure_url;
                var imageList = $("#product_image").val();
                if(imageList !== undefined && imageList !== ''){
                    imagesLink += result.info.secure_url+','; 
                    $("#product_image").val(imagesLink);
                    this.setState({image_url_link:imagesLink});
                }else{
                    $("#product_image").val(imagesLink);
                    this.setState({image_url_link:imagesLink});                
                }  
                $(".product_thumbnail_list").append("<li><img src='"+images+"'/><span class='delete_preview_image'>x</span></li>");
                setTimeout(function(){
                    //widget.close({quiet: true});
                },2000);
            }
        });
    }
    /*deleteItemImage(e){
        var _this = this;
        var images = [];
        if(e.target.className == 'delete_preview_image'){
            $(e.target).parent("li").remove();
            $(".product_thumbnail_list li").each(function(){
                images = $(this).find('img').attr('src');                
                var imageList = $("#product_image").val();
                if(imageList !== undefined && imageList !== ''){
                    imageList = $("#product_image").val()+',';
                    $("#product_image").val(imageList.concat(images));
                    _this.setState({image_url_link:imageList.concat(images)});
                }else{
                    $("#product_image").val(images);
                    _this.setState({image_url_link:images});
                }
            })
        }
    }*/
    deleteItemImage(e){
        var _this = this;
        var images = '';
        if(e.target.className == 'delete_preview_image'){
            $(e.target).parent("li").remove();
            $("#product_image").val('');
            $(".product_thumbnail_list li").each(function(){
                var imagesSrc = $(this).find('img').attr('src');
                //var imageList = $("#product_image_new").val();
                //imageList = $("#product_image_new").val()+',';
                images += imagesSrc+',';                
                //_this.setState({image_url:imageList.concat(images)});
            })
            $("#product_image_new").val(images);
            _this.setState({image_url_link:images});
        }
    }
    stepFirst(e){
        if(this.state.product_name === undefined || this.state.product_name === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Enter product name');               
            });
            return false;
        }
        if(this.state.image_url === undefined || this.state.image_url === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Upload your images');               
            });
            return false;
        }
        if(this.state.product_name && this.state.image_url){
            this.setState({showError:''});
            this.goToNextScreen('step1','step2');
            this.setState({step1:false,step2:true,step3:false,step4:false});
        }
    }
    stepSecond(e){
        if(this.state.getCategoryId === undefined || this.state.getCategoryId === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Choose Category');               
            });
            return false;
        }
        if(this.state.getSubCategoryId === undefined || this.state.getSubCategoryId === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Choose Sub Category');               
            });
            return false;
        }
        if(this.state.description === undefined || this.state.description === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Enter Description');               
            });
            return false;
        }
        if(this.state.product_worth === undefined || this.state.product_worth === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Enter valid worth of product');               
            });
            return false;
        }
        this.goToNextScreen('step2','step3');
        this.setState({step1:false,step2:false,step3:true,step4:false});
    }
    handleDayPriceChange(e){
        this.setState({
            showError:'',
            price_day:e.target.value,
            price_week:e.target.value*7
        });  
    }
    handleWeekPriceChange(e){
        this.setState({
            price_week:e.target.value,
            showError:''
        });
    }
    handlePostCodeChange(e){
        this.setState({
            showError:'',
            [e.target.name]: e.target.value
        });
        let postcode = e.target.value;
        if(postcode.replace(/\s/g,'').length >=3){
            var _this = this;
            axios({
                method: 'get',
                url: Path.POST_CODE_API+postcode+'/validate'
            })
            .then(function (response) {
                if(response.data.result){
                    _this.setState({
                        postcode:postcode
                    });
                    axios({
                        method: 'get',
                        url: Path.POST_CODE_API+postcode
                    })
                    .then(function (response) {
                        _this.setState({postcodeCheck:true,latitude:response.data.result.latitude,longitude:response.data.result.longitude,location:response.data.result.region});
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
                }
                else{
                    _this.setState({showError:'error'}, () => {
                        $("#error_msg").html('Invalid Postcode');               
                    });
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
        }
    } 
    getCategoryList(){
        var _this = this;
        axios({
            method: 'get',
            url: Path.USER_URL + Path.URL.GET_CAT_LIST
        })
        .then(function (response) {
            _this.setState({category:response.data.category});
        })
        .catch(function (error) {
            //console.log(error);
        });
    }
    getSubCategoryList(id){
        var _this = this;
        axios({
            method: 'get',
            url: Path.USER_URL + Path.URL.GET_SUB_CAT_LIST+'/'+id
        })
        .then(function (response) {
            _this.setState({sub_category:response.data.subCategory});
        })
        .catch(function (error) {
            //console.log(error);
        });
    }
    getSiblingCategoryList(id){
        var _this = this;
        axios({
            method: 'get',
            url: Path.USER_URL + Path.URL.GET_SIBLING_CAT_LIST +'/'+id
        })
        .then(function (response) {
            _this.setState({sibling_category:response.data.siblingCategory});
        })
        .catch(function (error) {
        });
    }
    handleSubmit(event) {
        event.preventDefault();
        if(this.state.price_day === undefined || this.state.price_day === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Enter valid daily price');               
            });
            return false;
        }
        if(this.state.price_week === undefined || this.state.price_week === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Enter valid weekly price');               
            });
            return false;
        }
        if(this.state.postcode === undefined || this.state.postcode === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Enter valid postcode');               
            });
            return false;
        }
        if(this.state.price_day && this.state.price_week && this.state.postcode){
            $("#submitItemData").attr("disabled","disabled");
            const data = new FormData(event.target);
            if(this.state.pickup == 'No'){
                data.append("pickup", 'No');
            }
            if(this.state.delivery == 'No'){
                data.append("delivery", 'No');
            }
            if(this.state.image_url_link.slice(-1) == ','){
                this.setState({image_url_link:this.state.image_url_link.substring(0, this.state.image_url_link.length-1)}, () => {
                    data.append("image_url", this.state.image_url_link);
                });
            }
            else{
                data.append("image_url", this.state.image_url_link);
            }
            data.append("lending_id",Path.PRODUCT_ID);
            data.append("buy", this.state.buy);
            data.append("rent", this.state.rent);
            data.append("cat_id", this.state.cat_id);
            data.append("subcat_id", this.state.subcat_id);
            data.append("sibling_id", this.state.sibling_id);
            data.append("location", this.state.location);
            data.append("latitude", this.state.latitude);
            data.append("longitude", this.state.longitude);
            data.append("longitude", this.state.longitude);           
            data.append("product_worth", this.state.product_worth);
            var _this = this;
            axios({
                method: 'post',
                url: Path.PRODUCT_URL + Path.URL.PRODUCT_EDIT,
                headers: {
                    'Api-Token':'Bearer '+_this.state.auth_token
                },
                data:data
            })
            .then(function (response) {
                confirmAlert({
                    title: 'Your item has been successfully edited.',
                    buttons: [
                    {
                        label: 'OK',
                        onClick: () =>{
                            setTimeout(() => {
                                window.location = Path.BASE_URL+Path.PAGE_NAME.MyItems;
                            },1000);
                        }
                    }
                    ]
                });
                
            })
            .catch(function (error) {
                //console.log(error);
                _this.setState({showError:'error'}, () => {
                    $("#error_msg").html('Check your details');               
                });
                $("#submitItemData").removeAttr("disabled");
            });
        }
    }
    getCategory(e){
        this.setState({
            getCategoryValue: e.target.value,
            getCategoryId: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].getAttribute("name"),
            cat_id:e.target.value
        }, () => {            
            this.getSubCategoryList(this.state.getCategoryId); 
        });  
    }
    getSubCategory(e){
        this.setState({
            getSubCategoryValue: e.target.value,
            getSubCategoryId: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].getAttribute("name"),
            subcat_id:e.target.value
        }, () => {            
            this.getSiblingCategoryList(this.state.getSubCategoryId);
        });  
    }   
    getSiblingCategory(e){
        this.setState({
            getSiblingCategoryValue: e.target.value,
            getSiblingCategoryId: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].getAttribute("name"),
            sibling_id:e.target.value
        }, () => {            
        });  
    }    
    initMap() {        
        var map = new google.maps.Map(document.getElementById('map'), {
            zoom: 8,
            center: {lat: this.state.latitude, lng: this.state.longitude},
            mapTypeId: 'terrain'
        }); 
        var marker = new google.maps.Marker({
            position: {lat: this.state.latitude, lng: this.state.longitude},
            map: map,
            title: 'Hello World!'
        });
    }
    addRadius(){
        if(radiusKey<2){
            $("#radiusAddData").append('<div class="no-padding col-xs-12 radius_wrapper" id="radius_wrapper['+radiusKey+']"><div class="col-sm-3 col-xs-12"><input class="create_pro_leftInput" type="text" placeholder="" name="delivery_area['+radiusKey+'][radius]" value="" /></div><div class="col-sm-3 col-xs-12 "><input class="create_pro_leftInput" type="text" placeholder="" name="delivery_area['+radiusKey+'][delivery_charge]" value="" /></div><div class="col-sm-3 col-xs-12 hide"><input class="radiuskeypress" type="text" placeholder="" name="delivery_area['+radiusKey+'][color_code]" value="" /></div></div>');
            radiusKey++;
        }
    }
    setRadius() {
        var len = $('.radius_wrapper').length;
        var mapData = [];
        var i = 0;
        for (i = 0; i < len ;i++) {
            mapData.push({'radius':$('input[name="delivery_area['+i+'][radius]"]').val(),'fillColor':'#4f8515'});
        }
        var citymap = mapData;
        var map = new google.maps.Map(document.getElementById('map'), {
            zoom: 10,
            center: {lat: this.state.latitude, lng: this.state.longitude},
            mapTypeId: 'terrain'
        });   
        for (var city in citymap) {
            // Add the circle for this city to the map.
            var cityCircle = new google.maps.Circle({
                strokeColor: citymap[city].fillColor,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: citymap[city].fillColor,
                fillOpacity: 0.35,
                map: map,
                center: {lat: this.state.latitude, lng: this.state.longitude},
                radius: citymap[city].radius * 1609.34
            });
        }
    }
    handlePickupDeliveryChange(e) {
        this.setState({
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        }, () => {         
            if(this.state.pickup === true){
                this.setState({pickupOpt:'Yes'});
            }
            if(this.state.pickup === false){
                this.setState({pickupOpt:'No'});
            }
            if(this.state.delivery === false){
                this.setState({showMap:false,deliveryOpt:'No'});
            }
            if(this.state.delivery === true){
                this.setState({showMap:true,deliveryOpt:'Yes'}, () => { 
                    this.initMap();
                });
            }
        });
    }
    loadData(){
        var _this = this;        
        axios({
            method: 'get',
            url: Path.PRODUCT_URL + Path.URL.GET_PRODUCT_DETAILS+'/'+Path.PRODUCT_ID,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {            
            _this.setState({
                product_name:response.data.product.product_name,
                description:response.data.product.description,
                price_day:response.data.product.price_day,
                price_week:response.data.product.price_week,
                postcode:response.data.product.postcode,
                pickup:response.data.product.pickup,
                product_worth:response.data.product.product_worth,
                delivery:response.data.product.delivery,
                deliveryStatus:response.data.product.delivery,
                cat_id:response.data.product.cat_id,
                subcat_id:response.data.product.subcat_id,
                sibling_id:response.data.product.sibling_id,
                product_image:response.data.product.product_image,
                delivery_area:response.data.product.delivery_area,
                rent:response.data.product.rent,
                buy:response.data.product.buy
            }, () => {
                radiusKey = _this.state.delivery_area.length;
                var postcode = _this.state.postcode;
                if(postcode.replace(/\s/g,'').length >=3){
                    axios({
                        method: 'get',
                        url: Path.POST_CODE_API+postcode+'/validate'
                    })
                    .then(function (response) {
                        if(response.data.result){
                            _this.setState({
                                postcode:postcode
                            });
                            axios({
                                method: 'get',
                                url: Path.POST_CODE_API+postcode
                            })
                            .then(function (response) {
                                _this.setState({postcodeCheck:true,latitude:response.data.result.latitude,longitude:response.data.result.longitude,location:response.data.result.region}, () => {
                                    if(_this.state.pickup === 'Yes'){
                                        _this.setState({pickupOpt:'Yes'});
                                    }
                                    if(_this.state.delivery === 'Yes'){
                                        _this.setState({showMap:true,deliveryOpt:'Yes'}, () => { 
                                            _this.initMap();
                                            _this.setRadius();
                                        });
                                    }                                    
                                });                               
                            })
                            .catch(function (error) {
                                //console.log(error);
                            });
                        }
                        else{
                            _this.setState({showError:'error'}, () => {
                                $("#error_msg").html('Invalid Postcode');               
                            });
                        }
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
                }           
                _this.getSubCategoryList(_this.state.cat_id);
                _this.getSiblingCategoryList(_this.state.subcat_id);
            });
        })
        .catch(function (error) {
            //console.log(error);
        });
    }
    render(){
        const renderCategory = this.state.category.map(function(item, i) {
            return(
                <option name={item.id} value={item.id} key={i}>{item.category_name}</option>
            )
        },this);
        const renderSubCategory = this.state.sub_category.map(function(item, j) {
            return(
                <option name={item.id} value={item.id} key={j}>{item.sub_category_name}</option>
            )
        },this);
        const renderSiblingCategory = this.state.sibling_category.map(function(item, k) {
            return(
                <option name={item.id} value={item.id} key={k}>{item.sibling_category_name}</option>
            )
        },this);
        const renderProductImages = this.state.product_image.map(function(item, m) {
            return(
                <li key={m}><img src={item.image_url}/><span className='delete_preview_image'>x</span></li>
            )
        },this);
        const renderProductImagesLink = this.state.product_image.map(function(item, m) {
            return(
                item.image_url
            )
        },this);       
        const renderMapRadius = this.state.delivery_area.map(function(item, l) {
            return(
                <div key={l} className="no-padding col-xs-12 radius_wrapper" id={"radius_wrapper["+l+"]"}>                                                
                    <div className="col-sm-4 col-xs-6">
                        <label className="control-label">Delivery Radius</label> 
                        <input className="create_pro_leftInput" type="text" placeholder="" name={"delivery_area["+l+"][radius]"} defaultValue={item.radius}/>
                    </div>
                    <div className="col-sm-4 col-xs-6">
                        <label className="control-label">Charge</label>
                        <input className="create_pro_leftInput" type="text" placeholder="" name={"delivery_area["+l+"][delivery_charge]"} defaultValue={item.delivery_charge} />
                    </div>
                    <div className="col-sm-4 col-xs-12 hide">
                        <label className="control-label">Color Code</label>
                        <input className="radiuskeypress" type="text" placeholder="" name={"delivery_area["+l+"][color_code]"} defaultValue={item.color_code}/>
                    </div>                   
                </div>
            )
        },this);
        const coords = { lat: this.state.latitude, lng: this.state.longitude };
        return(
            <div>
                {this.state.showError === 'success' &&
                <div className="flashmessage show"><i className="fa fa-check"></i><span id="success_msg"></span></div>}
                {this.state.showError === 'error' &&
                <div className="flashmessage-error show-error"><i className="fa fa-close"></i><span id="error_msg"></span></div>
                }   
                <div className="main_wrapper">
                    <section className="create_pro">
                        <div className="container">
                            <form className="postBg" onSubmit={this.handleSubmit}>
                            <div className="tab-content">
                                <div id="add_item_message"></div>
                                <div id="step1" className="tab-pane fade in active">
                                    <div className="col-sm-6 col-xs-12 create_pro_left">
                                        <div className="create_pro_leftHead">Item Name</div>
                                        <input className="create_pro_leftInput" type="text" name="product_name" value={this.state.product_name} onChange={e => this.handleChange(e)}/>
                                        <div className="create_pro_leftHead">Product Photos</div>
                                        <input type="hidden" id="product_image_new" value={this.state.image_url_link} />
                                        <div id="product_image_view" className="col-xs-12 col-sm-8"><ul onClick={e =>this.deleteItemImage(e)} className="product_thumbnail_list">{renderProductImages}</ul></div>
                                        <input type="hidden" value={renderProductImagesLink} id="product_image"/>
                                        <label onClick={this.productImageUpload} className="create_pro_left_step1UP" htmlFor="create_pro_left_step1UPIn">
                                            <i className="fa fa-file-image-o"></i>
                                            <span className="create_pro_left_step1UP_tex">Click to upload images</span>
                                        </label>
                                        <div className="create_pro_leftHead">Category 1</div>
                                        <div className="col-xs-12 no-padding" data-error="true">
                                            <select className="create_pro_leftInput" onChange={e => this.getCategory(e)} value={this.state.cat_id}>
                                                <option>Select Category</option>
                                                {renderCategory}
                                            </select>
                                        </div>
                                        {this.state.sub_category !== undefined && this.state.sub_category != '' ?
                                        <div className="col-xs-12 no-padding">
                                            <div className="create_pro_leftHead">Category 2</div>
                                            <select className="create_pro_leftInput" onChange={e => this.getSubCategory(e)} value={this.state.subcat_id}>
                                                <option>Select Sub Category</option>
                                                {renderSubCategory}
                                            </select>
                                        </div>
                                        :
                                        ''}
                                        {this.state.sibling_category !== undefined && this.state.sibling_category != '' ?
                                        <div className="col-xs-12 no-padding">
                                            <div className="create_pro_leftHead">Category 3</div>
                                            <select className="create_pro_leftInput" onChange={e => this.getSiblingCategory(e)} value={this.state.sibling_id}>
                                                <option>Select Sibling Category</option>
                                                {renderSiblingCategory}
                                            </select>
                                        </div>
                                        :
                                        ''}


                                        <div>

                                            <div className="create_pro_leftHead">Describe your item...</div>
                                            <textarea
                                                rows="8"
                                                className="create_pro_left_des"
                                                placeholder="Describe your item or package"
                                                name="description" value={this.state.description}
                                                onChange={e => this.handleChange(e)}
                                            >
                                            </textarea>
                                            <div className="create_pro_leftHead">Price</div>
                                            <div className="row">
                                                <div className="col-sm-4 col-xs-12">
                                                    <label className="control-label">Daily (£)</label>
                                                    <input className="create_pro_leftInput" type="text" placeholder="" name="price_day" value={this.state.price_day} onChange={e => this.handleDayPriceChange(e)}/>
                                                </div>
                                                <div className="col-sm-4 col-xs-12 ">
                                                    <label className="control-label">Weekly  (£)</label>
                                                    <input className="create_pro_leftInput" type="text" placeholder="" name="price_week" value={this.state.price_week} onChange={e => this.handleWeekPriceChange(e)}/>
                                                </div>
                                            </div>

                                        </div>


                                        {this.state.buy == 'Yes' &&
                                            <div className="col-xs-12 create_pro_left">
                                                <div className="create_pro_leftHead">Set the sale price for your item
                                                </div>
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i
                                                        className="fa fa-gbp"></i></span>
                                                    <input className="create_pro_leftInput form-control" type="text"
                                                           placeholder="" name="product_worth"
                                                           value={this.state.product_worth}
                                                           onChange={e => this.handleChange(e)}
                                                           onKeyPress={e => this.handleNumberChange(e)}/>
                                                </div>
                                            </div>
                                        }


                                        {/*set avaliability type/mode*/}
                                        {/*==================================================*/}
                                        <div className="col-xs-12 no-padding">
                                            <div className="col-xs-6 col-sm-6 no-padding-left no-xs-padding">
                                                <div className="productsBoxWrapper">
                                                    <div className="productsBoxTop">
                                                        Lending
                                                        <div className="relative pull-right">
                                                            <input id="rent" type="checkbox" value={this.state.rent}
                                                                   checked={this.state.rent == 'Yes' && 'checked'}
                                                                   className="checkbox_swicth_input" name="rent"
                                                                   onChange={e => this.recieveCheckbox(e)}
                                                            />
                                                            <label htmlFor="rent" className="checkbox_swicth"></label>
                                                        </div>
                                                    </div>
                                                    <div className="productsBoxBottom">Available to rent</div>
                                                </div>
                                            </div>
                                            <div className="col-xs-6 col-sm-6 no-padding-right no-xs-padding">
                                                <div className="productsBoxWrapper">
                                                    <div className="productsBoxTop">
                                                        Selling
                                                        <div className="relative pull-right">
                                                            <input id="buy" type="checkbox" value={this.state.buy}
                                                                   checked={this.state.buy == 'Yes' && 'checked'}
                                                                   className="checkbox_swicth_input" name="buy"
                                                                   onChange={e => this.recieveCheckbox(e)}
                                                            />
                                                            <label htmlFor="buy" className="checkbox_swicth"></label>
                                                        </div>
                                                    </div>
                                                    <div className="productsBoxBottom">Available to buy</div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*============================================================*/}


                                        <div className="create_pro_leftHead">Item location...</div>
                                        <input className="create_pro_leftInput" type="text" placeholder="Enter a Postcode" name="postcode" value={this.state.postcode} onChange={e => this.handlePostCodeChange(e)}/>
                                        <div className={this.state.postcodeCheck === true ? "col-xs-12 no-padding m-t-20":'col-xs-12 no-padding m-t-20 disablePickDelivery'}>
                                            <input id="priceS1" type="checkbox" className="create_pro_leftPriceSI" name="pickup" value={this.state.pickupOpt} onChange={e => this.handlePickupDeliveryChange(e)} checked={this.state.pickupOpt == 'Yes' ? 'checked' : ''}/>
                                            <label htmlFor="priceS1" className="create_pro_leftPriceSL"><span>Pickup</span></label>
                                            <input id="priceS2" type="checkbox" className="create_pro_leftPriceSI" name="delivery" value={this.state.deliveryOpt} checked={this.state.deliveryOpt == 'Yes' ? 'checked' : ''} onChange={e => this.handlePickupDeliveryChange(e)}/>
                                            <label htmlFor="priceS2" className="create_pro_leftPriceSL"><span>Delivery</span></label>
                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-xs-12 create_pro_right hidden-xs">
                                        <div className="create_pro_right_bg">
                                            <div className="create_pro_rightHead">Top tips:</div>
                                            <p>1. Make sure you photograph the actual item (don't use stock images). People are more likely to request if they can see what they will actually be getting.</p>
                                            <p>2. Your photo will be cropped to a 4:3 ratio. You can click on the image to choose the crop area when it is uploading. Ideal dimensions are 1300 x 975 pixels.</p>
                                            <p>3. Pick your cover photo carefully, when uploading you can drag images to reorder. If it's a package make sure all items are shown in the cover photo.</p>
                                        </div>
                                    </div>
                                    <div className="col-xs-12">
                                        {this.state.showMap == true ?
                                            <div className="row">
                                            <div className="col-sm-6 col-xs-12 no-padding" id="radiusAddData">
                                                {this.state.deliveryStatus == 'No' &&
                                                <div className="col-xs-12 no-padding">
                                                    <div className="create_pro_leftHead">Set your Delivery charge based on Miles</div>
                                                    <div className="no-padding col-xs-12 radius_wrapper" id="radius_wrapper[0]">                                                
                                                        <div className="col-sm-4 col-xs-6 no-padding">
                                                            <label className="control-label">Delivery Radius (Miles)</label> 
                                                            <input className="create_pro_leftInput" type="text" placeholder="Enter Miles" value={this.state.miles_0} name="delivery_area[0][radius]" onChange={e => this.handleMilesChange(e)} onKeyPress={e => this.handleNumberChange(e)}/>
                                                        </div>
                                                        <div className="col-sm-4 col-xs-6">
                                                            <label className="control-label">Charge (<i className="fa fa-gbp"></i>)</label>
                                                            <input className="create_pro_leftInput" type="text" placeholder="Enter Charges" value={this.state.charge_0} name="delivery_area[0][delivery_charge]" onChange={e => this.handleMilesChange(e)}  onKeyPress={e => this.handleNumberChange(e)}/>
                                                        </div>   
                                                        <div className="col-sm-3 col-xs-12 hide">
                                                            <label className="control-label">Color Code</label>
                                                            <input className="radiuskeypress" type="text" placeholder="" name="delivery_area[0][color_code]" defaultValue="#4f8515" />
                                                        </div>
                                                    </div>
                                                    <div className="no-padding col-xs-12 radius_wrapper" id="radius_wrapper[1]">
                                                        <div className="col-sm-4 col-xs-6 no-padding">
                                                            <input className="create_pro_leftInput" type="text" placeholder="Enter Miles" value={this.state.miles_1} name="delivery_area[1][radius]" onChange={e => this.handleMilesChange(e)} onKeyPress={e => this.handleNumberChange(e)}/>
                                                        </div>
                                                        <div className="col-sm-4 col-xs-6">
                                                            <input className="create_pro_leftInput" type="text" placeholder="Enter Charges" value={this.state.charge_1} name="delivery_area[1][delivery_charge]" onChange={e => this.handleMilesChange(e)}  onKeyPress={e => this.handleNumberChange(e)}/>
                                                        </div>   
                                                        <div className="col-sm-3 col-xs-12 hide">
                                                            <input className="radiuskeypress" type="text" placeholder="" name="delivery_area[1][color_code]" defaultValue="#4f8515" />
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                {this.state.deliveryStatus == 'Yes' && renderMapRadius}
                                                <div className="col-sm-8 col-xs-12 create_pro_left">
                                                    <button id="submitItemData" className="create_prof_next">Submit</button>
                                                </div>                                            
                                            </div>
                                            <div className="col-sm-6 col-xs-12 create_pro_right">
                                                <div id="map" className="map_circle"></div>
                                                <div className="col-sm-6 col-xs-12 create_pro_left">
                                                    <a className="create_prof_next" onClick={e => this.setRadius(e)}>Click to view in Map</a>
                                                </div>
                                            </div>  
                                        </div>
                                        :''}



                                        {this.state.showMap !== true &&
                                         <button id="submitItemData" className="create_prof_next">Submit</button>
                                        }
                                    </div>
                                </div>
                            </div>
                            </form>
                        </div>
                    </section>
                </div>    
            </div> 
        );
    }
}
export default ProductAdd;