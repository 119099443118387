import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import {reactLocalStorage} from 'reactjs-localstorage';
import logo from './../logo.png';
import queryString from 'querystring';
import Path from './Path';
class PaymentLoader extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
            auth_token : reactLocalStorage.get('auth_token')
        }
        this.loadData = this.loadData.bind(this);
    }
    componentDidMount() {
        this.loadData();
        setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    loadData(){
        var _this = this;
        const parsed = queryString.parse(this.props.location.search);
        _this.setState({stripeAccountId:parsed.code}, () => {
            axios({
                method: 'post',
                url: Path.USER_URL + Path.URL.GET_STRIPE_ACCOUNT_ID,
                headers: {
                    'Api-Token':'Bearer '+_this.state.auth_token
                },
                data: {
                    "accountId":_this.state.stripeAccountId
                }
            })
            .then(function (response) {
                window.location = Path.BASE_URL+Path.PAGE_NAME.MyAccount;                
            })
            .catch(function (error) {
                //console.log(error);
            });
        });
    }
    render(){
        
        return(
            <div className="loading_image_wrapper">
                <div className="loading_image_inner">
                    <img src={logo}/>
                    <p className="loading_text_contFirst">Loading... </p>
                    <p className="loading_text_cont"> Please wait while we redirect you</p>
                </div>
            </div>
        );
    }
}
export default PaymentLoader;