import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {Image, Video, Transformation, CloudinaryContext} from 'cloudinary-react';
import {TweenMax,TimelineMax,TimelineLite,TweenLite,Elastic,Sine,bbox,center,Power1,Circ} from 'gsap';
import {reactLocalStorage} from 'reactjs-localstorage';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import Path from './Path';
import Sidebar from './Sidebar';
class Invoice extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
            auth_token : reactLocalStorage.get('auth_token'),
            product:'',
            user:'',
            productInfo:'',
            borrower:''
        }
        this.handleChange = this.handleChange.bind(this);
        this.loadData = this.loadData.bind(this);
        this.datenth = this.datenth.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.initiateChat = this.initiateChat.bind(this);
    }
    handleChange(e) {
        this.setState({
           [e.target.name]: e.target.value
        });
    }
    initiateChat(e){        
        var _this = this;
        confirmAlert({
            title: 'Message sent to Borrower',
            buttons: [
            {
                label: 'OK',
                onClick: () =>{
                    axios({
                        method: 'post',
                        url: Path.USER_URL + Path.URL.INITIATE_CHAT,
                        headers: {
                            'Api-Token':'Bearer '+_this.state.auth_token
                        },
                        data: {
                            "product_id":_this.state.product_id,
                            "lender_id":_this.state.lender_id,
                            "borrower_id":_this.state.borrower_id 
                        }
                    })
                    .then(function (response) {
                        axios({
                            method: 'post',
                            url: Path.USER_URL + Path.URL.CREATE_MESSAGE,
                            headers: {
                                'Api-Token':'Bearer '+_this.state.auth_token
                            },
                            data: {
                                "conversation_id":response.data.conversation_id,
                                "message":Path.DEFAULT_MSG
                            }
                        })
                        .then(function (response) {
                            window.location = Path.BASE_URL+Path.PAGE_NAME.MessagesWrapper+'/'+response.data.data.conversation_id;
                        })
                        .catch(function (error) {
                           // console.log(error);
                        });
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
                }
            }
            ]
        });  
    }
    datenth(d) {
		if(d>3 && d<21) return 'th';
		switch (d % 10) {
				case 1:  return "st";
				case 2:  return "nd";
				case 3:  return "rd";
				default: return "th";
			}
	} 
	formatDate(date){
		var splitDate = date.split("-");
		var fortnightAway = new Date(splitDate[0], splitDate[1] - 1, splitDate[2]), dateNew = fortnightAway.getDate(),
		month = "January,February,March,April,May,June,July,August,September,October,November,December".split(",")[fortnightAway.getMonth()];
		return dateNew+this.datenth(dateNew)+" "+month+" "+splitDate[0];
	}
    componentDidMount() {
        this.loadData();
        setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    loadData(){
        var _this = this;
        _this.setState({PRODUCT_ID:Path.PRODUCT_ID.replace("LEND", "BORR")}, () => {
            axios({
                method: 'get',
                url: Path.PRODUCT_URL+Path.URL.GET_BORROWED_ITEM_DETAILS+'/'+_this.state.PRODUCT_ID,
                headers: {
                    'Api-Token':'Bearer '+_this.state.auth_token
                }
            })
            .then(function (response) {
                if(response.data.success == "1"){
                    _this.setState({
                        product:response.data.product,
                        user:response.data.product.product.user,
                        productInfo:response.data.product.product,
                        borrower:response.data.product.borrower,
                        product_id:response.data.product.product_id,
                        lender_id:response.data.product.borrower_id,
                        borrower_id:response.data.product.product.user_id
                    }, () => {
                        _this.setState({created_date:_this.formatDate(_this.state.product.created.split(" ")[0]),rented_date:_this.formatDate(_this.state.product.rented_date),returned_date:_this.formatDate(_this.state.product.returned_date)});
                    })
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
        });
    }  
    render(){
        return(
            <div>               
                <div className="main_wrapper">
                    <section className="dashboard_bg">
                        <Sidebar/>
                        <div className="content-wrapper">
                            <div className="content-height-wrapper">
                                <div className="invoice m-t-40">
                                    <div className="invoice-logo">
                                        <div className="col-xs-6 watchlist_itemsBtn">
                                            <a href={Path.BASE_URL+Path.PAGE_NAME.MyRentals} className="watchlist_items_view auto_width m-t-20">Back to My Rentals</a>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 pull-right">
                                            <p>
                                                <span className="muted">
                                                Order Details </span>
                                                #{Path.PRODUCT_ID} / {this.state.created_date}
                                            </p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">                                        
                                        <div className="col-xs-12 col-sm-6">
                                            <h3 className="m-b-20">Borrower Information:</h3>
                                            <div className="col-xs-12 col-sm-4"><img className="img-circle" width="150" height="150" src={this.state.borrower.profile_image} src={this.state.user.profile_image} onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PROFILE_IMAGE}} /></div>      
                                            <div className="col-xs-12 col-sm-8">                                   
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <h3>{this.state.borrower.name} {this.state.borrower.lastname}</h3>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-envelope"></i> {this.state.borrower.email}
                                                    </li>
                                                    <li>
                                                    <i className="fa fa-phone"></i> {this.state.borrower.mobile_number}
                                                    </li>
                                                    <li>
                                                        <span className="message_to_lender_btn m-t-10" onClick={e => this.initiateChat(e)}>Say Hello to Chat with <span className="text-capitalize">{this.state.borrower.name}{this.state.borrower.lastname}</span></span>
                                                    </li>
                                                </ul>
                                            </div>   
                                        </div>
                                        <div className="col-xs-12 col-sm-6">
                                            <h3 className="m-b-20">Item Information:</h3>
                                            <div className="col-xs-12 col-sm-4"><img className="img-circle" width="150" height="150" src={this.state.product.map_product_image} onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_NEW_IMAGE}}/></div>      
                                            <div className="col-xs-12 col-sm-8">                                   
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <h3>{this.state.productInfo.product_name}</h3>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-calendar"></i> From {this.state.rented_date} To {this.state.returned_date}
                                                    </li>
                                                </ul>
                                            </div>   
                                        </div>
                                        <div className="col-xs-12">
                                            <div className="watchlist_items m-t-40 m-b-40">
                                                <div className="col-sm-4 col-xs-12 watchlist_itemsCont">
                                                    <div className="pro_name">Pickup / Delivery</div>
                                                    <div className="pro_amount product_type">{this.state.product.product_type}</div>
                                                </div>
                                                <div className="col-sm-4 col-xs-12 watchlist_itemsCont">
                                                    <div className="pro_name">Total Amount</div>
                                                    <div className="product_total_amount"><i className="fa fa-gbp"></i> {this.state.product.grand_total}</div>
                                                </div>  
                                                <div className="col-sm-4 col-xs-12 watchlist_itemsCont">
                                                    <div className="pro_name product_type">{this.state.product.product_type} Address</div>
                                                    <div className="pro_amount">{this.state.product.borro_address_line1 +', '+ this.state.product.borro_address_line2}</div>
                                                    <div className="pro_amount">{this.state.product.city +', '+ this.state.product.postcode}.</div>
                                                </div>                  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div> 
        );
    }
}
export default Invoice;