import React, {Component} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import axios from 'axios';
import $ from 'jquery';
import {reactLocalStorage} from 'reactjs-localstorage';
import Path from './Path';
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Open Sans, sans-serif',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    }
  }
};
class BuyCheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth_token : reactLocalStorage.get('auth_token'),
      errorMessage: '',
      card_name:'',
      stripe: window.Stripe(this.props.stripe_secret_key,{stripeAccount:this.props.user_stripe_account_id})
    };
    this.handleStripeChange = this.handleStripeChange.bind(this);
    this.handleStripeSubmit = this.handleStripeSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.removeTopBar = this.removeTopBar.bind(this);  
  }
  removeTopBar(){
      this.setState({showError:''}); 
  } 
  handleChange(e) {
    this.setState({
       [e.target.name]: e.target.value
    });
  }
	handleStripeChange({error}){
		if (error) {
		  this.setState({errorMessage: error.message});
    }    
	}
	componentDidMount(){
    var _this = this;
    const stripe = _this.state.stripe;
    var elements = stripe.elements();
    _this.setState({card:elements.create('card')}, () => {
      _this.state.card.mount('#card-element');
    });    
  }
  handleStripeSubmit(ev) {
    var _this = this;
    const stripe = _this.state.stripe;
    const clientSecret = _this.props.intent_client_secret;
    stripe.handleCardPayment(clientSecret, _this.state.card, {
      payment_method_data: {
        billing_details: {name: 'Weborro'}
      }
    }).then(function(result) {
      console.log(result);
      if (result.error) {
        _this.setState({showError:'error'}, () => {
          $("#error_msg").html('Invalid Transaction.');
          $(".menu_bg_right").hide();
          $("[data-href='payment_details']").parent("li").removeClass("active");
          $("[data-href='verification']").parent("li").addClass("active");
          $("#payment_details").removeClass("in active");
          $("#verificationCancel").addClass("in active");
          window.scrollTo(0, 0);
        });
      } else {
        $(".check_left_form_submit_request").addClass('disabledbtn');
        axios({
            method: 'post',
            url: Path.PRODUCT_URL + Path.URL.BUY_PRODUCT,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token,
            },
            data:{
              productId: _this.props.productId,
              product_type: _this.props.product_type,
              delivery_charge: _this.props.delivery_charge,        
              seller_firstname: _this.props.borro_firstname,
              seller_lastname: _this.props.borro_lastname,
              seller_address_line1: _this.props.borro_address_line1,
              seller_address_line2: _this.props.borro_address_line2,
              city: _this.props.city,
              postcode: _this.props.postcode,
              transaction_id: result.paymentIntent.id,
              intent_client_secret_key: _this.props.intent_client_secret
            }
        })
        .then(function (response) {
          $(".menu_bg_right").hide();
          $("[data-href='payment_details']").parent("li").removeClass("active");
          $("[data-href='verification']").parent("li").addClass("active");
          $("#payment_details").removeClass("in active");
          $("#verification").addClass("in active");
          window.scrollTo(0, 0);
        })
        .catch(function (error) {
            _this.setState({showError:'error'}, () => {
              $("#error_msg").html('Invalid Transaction.');
              $(".menu_bg_right").hide();
              $("[data-href='payment_details']").parent("li").removeClass("active");
              $("[data-href='verification']").parent("li").addClass("active");
              $("#payment_details").removeClass("in active");
              $("#verificationCancel").addClass("in active");
              window.scrollTo(0, 0);        
            });
        });
      }
    });
  }
  render() {
    return (
      <div>
          {this.state.showError === 'success' &&
          <div onClick={e => this.removeTopBar(e)} className="flashmessage show"><i className="fa fa-check"></i><span id="success_msg"></span></div>}
          {this.state.showError === 'error' &&
          <div onClick={e => this.removeTopBar(e)} className="flashmessage-error show-error"><i className="fa fa-close"></i><span id="error_msg"></span></div>
          }
          <h3 className="ho_renshop_sH card_details_head">Card details</h3>
          <div className="form-group clearfix hide">
            <div className="col-xs-12 no-padding">
              <label className="control-label">Card Name</label>
              <input className="form-control" type="text" value={this.state.card_name} name="card_name" id="card_name" onChange={e => this.handleChange(e)}/>
            </div>
          </div>
          <CardElement id="card-element" onChange={e => this.handleStripeChange(e)} />
        <div className="card-error" role="alert">
          {this.state.errorMessage}
        </div>
        <div className="form-group clearfix">
          {/*<button id="submit_stripe_request" className="check_left_form_save">Submit Request</button>*/}
          <button className="check_left_form_submit_request" data-secret={this.props.intent_client_secret} onClick={e => this.handleStripeSubmit(e)}>Place Order</button>
        </div>
      </div>
    );
  }
}

export default injectStripe(BuyCheckoutForm);
