import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Accordion,AccordionItem,AccordionItemHeading,AccordionItemPanel,AccordionItemButton} from 'react-accessible-accordion';
import $ from 'jquery';
import Path from './Path';
//import 'react-accessible-accordion/dist/fancy-example.css';
class Terms extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {

        }
        this.loadData = this.loadData.bind(this);
    }
    loadData(){
    }
    componentDidMount() {
        this.loadData();     
        setTimeout(() =>
            this.props.handler(false)
        , 2000); 
    }
    render() {
        return (
                <div className="contactBg">
                <div className="container">
                      <div className="innerhead">Terms of Service / T&Cs</div>
                      <p>Weborro is all about making your life easier.</p>
                      <Accordion>
                        <AccordionItem>
                          <AccordionItemHeading>
                              <AccordionItemButton>
                                  The Nature of Our Service 
                              </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                              <p>
                                  We work as an intermediary only, and act in the best interests of the both the borrower and lender at all times. We endeavour to solve any issues arising quickly and amicably.
                              </p>
                          </AccordionItemPanel>
                        </AccordionItem>    
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                        Using the Weborro Service as a Lender
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    As you a lender you consent that the information about your item(s) is accurate at the time of lending, and you agree to lend for the amount and duration agreed with the borrower. 
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>    
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Using the Weborro Service as a Borrower 
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    As borrower you agree to borrow the item(s) inline with best practices, and to make every effort to return them in their original state. You agree to pay the fee at a time of the lenders choosing, and to return the items in good time so as not to exceed the last date of return.             
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Changes to the Weborro Terms of Service 
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        We reserve the right to change our service at any time, and with no prior notice. Should this adversely impact you in anyway, please contact our team at your earliest convenience. 
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    How to Use Our Service
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    We keep things simple, and all we ask is that you’re accurate and truthful throughout. Inaccurate listings, unfair prices, and late or damaged returns all impact the experience for someone else. These are not faceless multinational companies you're borrowing from, but the people with jobs and commitments just like you. Treat everyone with respect and courtesy, and they will be able to help in when you need them to. 
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Issues With the Weborro Service     
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    We actively encourage lenders and borrowers who experience falls short of our high expectations to get in contact with us as soon as possible. That way we will be able to take the action necessary to put things right. 
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Restrictions to the Weborro Service    
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>The following items, users, and instances are all restricted from the Weborro service:</p>
                                <p>1. Users under the age of 16</p>
                                <p>2. Users currently detained at Her Majesty's pleasure</p>
                                <p>3. Items which are damaged, inaccurately listed, or in a general state of disrepair</p>
                                <p>4. Items which are illegal, dangerous, or gained illegally</p>
                                <p>5. Items which have been stolen, taken without consent, or are intended to be lent without consent of the rightful owner </p>
                                <p>6. Accounts which are being operated by an individual other than the person named on the account </p>
                            </AccordionItemPanel>
                        </AccordionItem>              
                    </Accordion>
                  </div>
            </div>
        );
    }
}
export default Terms;