import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {Image, Video, Transformation, CloudinaryContext} from 'cloudinary-react';
import {TweenMax,TimelineMax,TimelineLite,TweenLite,Elastic,Sine,bbox,center,Power1,Circ} from 'gsap';
import {reactLocalStorage} from 'reactjs-localstorage';
import Path from './Path';
import Sidebar from './Sidebar';
class MyRentals extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
            auth_token : reactLocalStorage.get('auth_token'),
            product:[],
            lendedproduct:[],
            product_image :[],
            borrowTab:true,
            lendTab:false,
            soldTab:false,
            soldproduct:[],
            profile_image:[],
            boughtproduct:[],
            boughtUser:[],
            boughtHistory:[],
            reviewshow:false,
            comments:'',
            rating_1:'1',
            rating_2:'2',
            rating_3:'3',
            rating_4:'4',
            rating_5:'5',
        }
        this.loadData = this.loadData.bind(this);
        this.openTab = this.openTab.bind(this); 
        this.datenth = this.datenth.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.reviewModal = this.reviewModal.bind(this);
        this.submitReview = this.submitReview.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    openTab(id){
        if(id == 'borrowTab'){
            this.setState({borrowTab:true,lendTab:false,soldTab:false,boughtTab:false});
        }
        if(id == 'soldTab'){
            this.setState({borrowTab:false,lendTab:false,soldTab:true,boughtTab:false});
        }
        if(id == 'lendTab'){
            this.setState({borrowTab:false,lendTab:true,soldTab:false,boughtTab:false});
        }
        if(id == 'boughtTab'){
            this.setState({borrowTab:false,lendTab:false,soldTab:false,boughtTab:true});
        }        
    }
    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
        if(e.target.checked){
            this.setState({rating:e.target.value});
        }
    }
    reviewModal(productid,lenderid){
        this.setState({reviewshow: true,id_product:productid,id_lender:lenderid});
    }
    hideModal(){
        this.setState({reviewshow:false});
    }
    submitReview(){
        this.setState({reviewshow:false});
        var _this = this;
        axios({
            method: 'post',
            url: Path.USER_URL + Path.URL.GIVE_USER_REVIEWS,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            },
            data: {
                "rating":_this.state.rating,
                "comments":_this.state.comments,
                "product_id":_this.state.id_product,
                "lender_id":_this.state.id_lender
            }
        })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            //console.log(error);
        });
    }
    datenth(d) {
		if(d>3 && d<21) return 'th';
		switch (d % 10) {
				case 1:  return "st";
				case 2:  return "nd";
				case 3:  return "rd";
				default: return "th";
			}
	} 
	formatDate(date){
		var splitDate = date.split("-");
		var fortnightAway = new Date(splitDate[0], splitDate[1] - 1, splitDate[2]), dateNew = fortnightAway.getDate(),
        month = "Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec".split(",")[fortnightAway.getMonth()];
		return dateNew+this.datenth(dateNew)+" "+month;
	}
    componentDidMount() {
        this.loadData();
        setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    loadData(){
        var _this = this;
        axios({
            method: 'get',
            url: Path.PRODUCT_URL+Path.URL.GET_BORROWED_ITEMS,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {
            if(response.data.success == "1"){
                _this.setState({product:response.data.product})
            }
        })
        .catch(function (error) {
            //console.log(error);
        });
        axios({
            method: 'get',
            url: Path.PRODUCT_URL+Path.URL.GET_LENDED_ITEMS,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {
            if(response.data.success == "1"){
                _this.setState({lendedproduct:response.data.borrow})
            }
        })
        .catch(function (error) {
            //console.log(error);
        });
        axios({
            method: 'get',
            url: Path.PRODUCT_URL+Path.URL.GET_SOLD_PRODUCTS,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {
            if(response.data.success == "1"){
                _this.setState({soldproduct:response.data.Product})
            }
        })
        .catch(function (error) {
            //console.log(error);
        });
        axios({
            method: 'get',
            url: Path.PRODUCT_URL+Path.URL.BOUGHT_HISTORY,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {
            if(response.data.success == "1"){
                _this.setState({boughtproduct:response.data.Product,boughtUser:response.data.User}, () => {
                    let arr1 = [
                        { id: "abdc4051", date: "2017-01-24" },
                        { id: "abdc4052", date: "2017-01-22" }
                    ];
                    let arr2 = [
                        { id: "abdc4051", name: "ab" },
                        { id: "abdc4052", name: "abc" }
                    ];
                    let arr3 = [];
                    _this.state.boughtproduct.forEach((itm, i) => {
                        arr3.push(Object.assign({}, itm, _this.state.boughtUser[i]));
                    });
                    _this.setState({boughtHistory:arr3});
                })
            }
        })
        .catch(function (error) {
            //console.log(error);
        });
    }  
    render(){
        const renderLendedItemList = this.state.lendedproduct.reduce((r, e) => (r.push(...e), r), []);
        const renderProductListFilter = this.state.lendedproduct.filter(function (el) {
            return el != '';
        });
        const renderSoldProductListFilter = this.state.soldproduct.filter(function (el) {
            return el != '';
        });
        const renderBorrowedProductListFilter = this.state.product.filter(function (el) {
            return el != '';
        });
        const renderBoughtProductListFilter  = this.state.boughtHistory.filter(function (el) {
            return el != '';
        });
        const renderBoughtProductList = renderBoughtProductListFilter.map(function(item, i) {
            return(
                <div className="watchlist_items" key={i}>
                    <div className="col-sm-2 col-xs-12 no-padding text-center watchlist_itemsImg">
                        {item.product.product_image !== undefined ?
                        item.product.product_image.slice(0,1).map(function(images, j) {
                                    return(
                            <img key={j} src={Path.CLOUDINARY.url+'/c_scale,h_80,w_80/'+Path.CLOUDINARY.product_image+images.image_url.replace(/^.*[\\/]/, '')} onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_NEW_IMAGE}} />
                            )
                        })
                        :   <img src={Path.NO_PRODUCT_NEW_IMAGE} />
                        } 
                    </div>
                    <div className="col-sm-4 col-xs-12 watchlist_itemsCont">
                        <div className="pro_name">Re : {item.product.product_name}</div>
                        <div className="pro_amount">Price : <i className="fa fa-gbp"></i> {item.product.product_worth}</div>
                    </div>
                    <div className="col-sm-4 col-xs-12 watchlist_itemsCont">
                        <div className="pro_name">Seller : {item.name} {item.lastname}</div>
                    </div>
                    <div className="col-sm-2 col-xs-12 no-padding text-center watchlist_itemsImg">
                        {item.profile_image !== undefined ?
                            <img src={Path.CLOUDINARY.url+'/c_scale,h_80,w_80/'+Path.CLOUDINARY.product_image+item.profile_image.replace(/^.*[\\/]/, '')} onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_NEW_IMAGE}} />
                        :   <img src={Path.NO_PRODUCT_NEW_IMAGE} />
                        } 
                    </div>
                </div>
                )
        },this);
        const renderProductList = renderLendedItemList.map(function(item, i) {
            return(
                <div className="watchlist_items" key={i}>
                    <div className="col-sm-2 col-xs-12 no-padding text-center watchlist_itemsImg">
                        {item.product.product_image !== undefined ?
                        item.product.product_image.slice(0,1).map(function(images, j) {
                                    return(
                            <img key={j} src={Path.CLOUDINARY.url+'/c_fill,h_80,w_80/'+Path.CLOUDINARY.product_image+images.image_url.replace(/^.*[\\/]/, '')} onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_NEW_IMAGE}} />
                            )
                        })
                        :   <img src={Path.NO_PRODUCT_NEW_IMAGE} />
                        }
                        <span>{item.borro_firstname} {item.borro_lastname}</span>
                    </div>
                    <div className="col-sm-8 col-xs-12 watchlist_itemsCont">
                        <div className="pro_name">{item.product.product_name}</div>
                        <div className="pro_amount">From {this.formatDate(item.rented_date)} to {this.formatDate(item.returned_date)}</div>
                        <div className="pro_kg">Total Amount : <i className="fa fa-gbp"></i> {item.grand_total}</div> 
                    </div>  
                    <div className="col-sm-2 col-xs-12 watchlist_itemsBtn">
                        <a href={Path.BASE_URL+Path.PAGE_NAME.LendInvoice+'/'+item.borrowing_id.replace("BORR", "LEND")} className="watchlist_items_view marginTopViewMore">View Details</a>
                    </div>
                </div>
                )
        },this);
        const renderSoldProductList = renderSoldProductListFilter.map(function(item, i) {
            return(
                <div className="watchlist_items" key={i}>
                    <div className="col-sm-2 col-xs-12 no-padding text-center watchlist_itemsImg">
                        {item.product_image !== undefined ?
                        item.product_image.slice(0,1).map(function(images, j) {
                                    return(
                            <img key={j} src={Path.CLOUDINARY.url+'/c_scale,h_80,w_80/'+Path.CLOUDINARY.product_image+images.image_url.replace(/^.*[\\/]/, '')} onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_NEW_IMAGE}} />
                            )
                        })
                        :   <img src={Path.NO_PRODUCT_NEW_IMAGE} />
                        } 
                    </div>
                    <div className="col-sm-4 col-xs-12 watchlist_itemsCont">
                        <div className="pro_name">Re : {item.product_name}</div>
                        <div className="pro_amount">Price : <i className="fa fa-gbp"></i> {item.product_worth}</div>
                    </div>
                    <div className="col-sm-4 col-xs-12 watchlist_itemsCont">
                        <div className="pro_name">Buyer : {item.selling_product[0].user.name}{item.selling_product[0].user.lastname}</div>
                    </div>
                    <div className="col-sm-2 col-xs-12 no-padding text-center watchlist_itemsImg">
                        {item.user.profile_image !== undefined ?
                            <img src={Path.CLOUDINARY.url+'/c_scale,h_80,w_80/'+Path.CLOUDINARY.product_image+item.selling_product[0].user.profile_image.replace(/^.*[\\/]/, '')} onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_NEW_IMAGE}} />
                        :   <img src={Path.NO_PRODUCT_NEW_IMAGE} />
                        } 
                    </div>
                </div>
                )
        },this);
        const renderBorrowedProductList = renderBorrowedProductListFilter.map(function(item, k) {
            return(
                <div className="watchlist_items" key={k}>
                    <div className="col-sm-2 col-xs-12 no-padding text-center watchlist_itemsImg">
                        {item.product.product_image !== undefined ?
                        item.product.product_image.slice(0,1).map(function(images, l) {
                            return(
                            <img key={l} src={Path.CLOUDINARY.url+'/c_scale,h_80,w_80/'+Path.CLOUDINARY.product_image+images.image_url.replace(/^.*[\\/]/, '')} onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_NEW_IMAGE}} />
                            )
                        })
                        :   <img src={Path.NO_PRODUCT_NEW_IMAGE} />
                        }
                        <span>{item.product.user.name} {item.product.user.lastname}</span>
                    </div>   
                    <div className="col-sm-8 col-xs-12 watchlist_itemsCont">
                        <div className="pro_name">{item.product.product_name}</div>
                        <div className="pro_amount">From {this.formatDate(item.rented_date)} to {this.formatDate(item.returned_date)}</div>
                        <div className="pro_kg">{item.postcode}</div>
                    </div>
                    <div className="col-sm-2 col-xs-12 watchlist_itemsBtn">
                        <a className="watchlist_items_view" onClick={e => this.reviewModal(item.product_id,item.product.user.id)}>Write Review</a>
                        <a href={Path.BASE_URL+Path.PAGE_NAME.Invoice+'/'+item.borrowing_id} className="watchlist_items_view m-t-10 no-margin-bottom">View Details</a>
                    </div>
                </div>
                )
        },this);
        
        return(
            <div>               
                <div className="main_wrapper">
                    <section className="dashboard_bg">
                        <Sidebar/>
                        <div className="content-wrapper">
                            <div className="content-height-wrapper">
                                <div className="tab-content">
                                    <div className="col-xs-12 no-padding m-t-40">
                                        <div className="col-xs-12 text-center">
                                            <h3 className="ho_renshop_sH">My Rentals Items</h3>
                                        </div>
                                        <div className="col-xs-12 m-t-40">
                                            <div className="col-sm-8 col-sm-offset-2 col-xs-12 text-center">
                                                <div className="rentallist_tab">
                                                    <span onClick={e => this.openTab('borrowTab')} className={this.state.borrowTab === true ? "watchlist_tabs1 active" : "watchlist_tabs1" }>Borrowing</span>
                                                    <span onClick={e => this.openTab('lendTab')} className={this.state.lendTab === true ? "watchlist_tabs2 active" : "watchlist_tabs1" }>Lending</span>
                                                    <span onClick={e => this.openTab('soldTab')} className={this.state.soldTab === true ? "watchlist_tabs2 active" : "watchlist_tabs1" }>Sold</span>
                                                    <span onClick={e => this.openTab('boughtTab')} className={this.state.boughtTab === true ? "watchlist_tabs2 active" : "watchlist_tabs1" }>Bought</span>
                                                </div>
                                            </div>
                                            <div id="innerrentaltab-1" className={this.state.borrowTab === true ? "tab-innerrentalcont active" : "tab-innerrentalcont" }>
                                                {this.state.product.length > 0 ?
                                                renderBorrowedProductList
                                                :
                                                <div className="col-xs-12 text-center">
                                                    <div className="watchlist_contact_text">Looks like you're not borrowed anything yet</div>
                                                    <a href={Path.BASE_URL+Path.PAGE_NAME.Search} className="watchlist_contact_btn">Search an items</a>
                                                </div>
                                                }
                                            </div>
                                            <div id="innerrentaltab-2" className={this.state.lendTab === true ? "tab-innerrentalcont active" : "tab-innerrentalcont" }>
                                                {this.state.lendedproduct.length > 0 ?
                                                    renderProductList
                                                    :
                                                    <div className="col-xs-12 text-center">
                                                        <div className="watchlist_contact_text">Looks like you're not lending anything yet</div>
                                                        <a href={Path.BASE_URL+Path.PAGE_NAME.ProductAdd} className="watchlist_contact_btn">List an items</a>
                                                    </div>
                                                }
                                            </div>
                                            <div id="innerrentaltab-3" className={this.state.soldTab === true ? "tab-innerrentalcont active" : "tab-innerrentalcont" }>
                                                {this.state.soldproduct.length > 0 ?
                                                    renderSoldProductList
                                                    :
                                                    <div className="col-xs-12 text-center">
                                                        <div className="watchlist_contact_text">Looks like you're not sold anything yet</div>
                                                    </div>
                                                }
                                            </div>
                                            <div id="innerrentaltab-4" className={this.state.boughtTab === true ? "tab-innerrentalcont active" : "tab-innerrentalcont" }>
                                                {this.state.boughtproduct.length > 0 ?
                                                    renderBoughtProductList 
                                                    :
                                                    <div className="col-xs-12 text-center">
                                                        <div className="watchlist_contact_text">Looks like you're not bought anything yet</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className={this.state.reviewshow == true ? 'weborro_modal display-block' : 'weborro_modal display-none'}>
                    <section className='weborro_modal_wrapper loginDesi'>
                        <button type="button" className="close" onClick={e => this.hideModal(e)}>&times;</button>
                        <form>                            
                            <div className="log_H">Write Review</div>
                            <div id="login_message"></div>
                            <div className="form-group clearfix">
                                <label className="control-label">Select Rating</label>
                                <div className="col-xs-12 no-padding">
                                    <div className="pull-left">
                                        <input className="starInput" type="radio" id="st1" name="rating" value={this.state.rating_5} onChange={e => this.handleChange(e)} />
                                        <label className="starInputLabel" htmlFor="st1"></label>
                                        <input className="starInput" type="radio" id="st2" name="rating" value={this.state.rating_4} onChange={e => this.handleChange(e)} />
                                        <label className="starInputLabel" htmlFor="st2"></label>
                                        <input className="starInput" type="radio" id="st3" name="rating" value={this.state.rating_3} onChange={e => this.handleChange(e)} />
                                        <label className="starInputLabel" htmlFor="st3"></label>
                                        <input className="starInput" type="radio" id="st4" name="rating" value={this.state.rating_2} onChange={e => this.handleChange(e)}/>
                                        <label className="starInputLabel" htmlFor="st4"></label>
                                        <input className="starInput" type="radio" id="st5" name="rating" value={this.state.rating_1} onChange={e => this.handleChange(e)} />
                                        <label className="starInputLabel" htmlFor="st5"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group clearfix">
                                <label className="control-label">Comments</label>
                                <textarea type="password" className="form-control" placeholder="Enter your comments" onChange={e => this.handleChange(e)} value={this.state.comments} id="comments" name="comments" ></textarea>
                            </div>
                            <div className="form-group clearfix">
                                <span className="log_Bt text-center pointer" onClick={e => this.submitReview(e)}>Submit Review</span>
                            </div>
                        </form>
                    </section>
                </div>
            </div> 
        );
    }
}
export default MyRentals;