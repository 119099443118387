import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {Image, Video, Transformation, CloudinaryContext} from 'cloudinary-react';
import {TweenMax,TimelineMax,TimelineLite,TweenLite,Elastic,Sine,bbox,center,Power1,Circ} from 'gsap';
import {reactLocalStorage} from 'reactjs-localstorage';
import Path from './Path';
import Sidebar from './Sidebar';
class MyAccount extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
            auth_token : reactLocalStorage.get('auth_token'),
            cards_info:[]
        }
        this.loadData = this.loadData.bind(this);
        this.deleteSavedCards = this.deleteSavedCards.bind(this);
    }
    componentDidMount() {
        this.loadData();
        setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    loadData(){
        var _this = this;        
        axios({
            method: 'get',
            url: Path.PRODUCT_URL + Path.URL.SAVED_CARDS,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {  
          if(response.data.success == "1"){     
            _this.setState({
                cards_enable:true,
                cards_info:response.data.cards
            });
          }
          else{
            this.setState({cards_enable:false});
          }
        })
        .catch(function (error) {
            //console.log(error);
        });
    }
    deleteSavedCards(e,id){
        var _this = this;        
        axios({
            method: 'delete',
            url: Path.PRODUCT_URL + Path.URL.REMOVE_SAVED_CARDS+'/'+id,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {  
          $("#card_"+id).remove();
        })
        .catch(function (error) {
            //console.log(error);
        });
    }
    render(){
        const renderSavedCardList = this.state.cards_info.map(function(item, i) {
            return(
              <div id={'card_'+item.id} className="col-sm-4 col-xs-12 no-padding-left" key={i}>
                <div className="credit_card">
                    <span><img src={Path.IMAGE_PATH+"/master.png"}/></span>
                    <div className="credit_det">
                        <div className="card_number">{item.customer_name}</div>
                        <div className="card_number">XXX-XXXXXXXX-{item.card_number} </div>
                        <div className="card_validity">Valid till {item.exp_month}/{item.exp_year}</div>
                    </div>
                    <div className="credit_det">
                        <div className="card_delete"><a onClick={e => this.deleteSavedCards(e,item.id)}>DELETE</a></div>
                    </div>
                </div>
              </div>
                )
        },this);
        return(
            <div>    
                {this.state.showError === 'success' &&
                <div className="flashmessage show"><i className="fa fa-check"></i><span id="success_msg"></span></div>}
                {this.state.showError === 'error' &&
                <div className="flashmessage-error show-error"><i className="fa fa-close"></i><span id="error_msg"></span></div>
                }           
                <div className="main_wrapper">
                    <section className="dashboard_bg">
                        <Sidebar/>
                        <div className="content-wrapper">
                            <div className="content-height-wrapper">
                                <div className="col-xs-12">
                                    <div className="savedCardsWrapper">
                                        <h3 className="ho_renshop_sH">Saved Card Details</h3>
                                        {renderSavedCardList}
                                    </div>
                                </div>
                            </div>
                            <div className="ho_footer inner_footer myaccount_footer m-t-40">
                                <div className="col-xs-12">
                                    <div className="ho_foot_b">
                                        <div className="ho_foot_b_L">weborro limited 2018</div>
                                        <div className="ho_foot_b_WT">Made withlove by the weborro team</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div> 
        );
    }
}
export default GoogleApiWrapper({
    apiKey: (Path.API_LIST.GOOGLE_API_KEY)
})(MyAccount);