import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import Geocode from "react-geocode";
import Autocomplete from 'react-google-autocomplete';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {Image, Video, Transformation, CloudinaryContext} from 'cloudinary-react';
import {reactLocalStorage} from 'reactjs-localstorage';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import Path from './Path';
const google = window.google = window.google ? window.google : {}
let radiusKey = 1;
class ProductAdd extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
            auth_token : reactLocalStorage.get('auth_token'),
            category:[],
            sub_category:[],
            sibling_category:[],
            pickup:true,
            delivery:false,
            step1:true,
            showError:false,
            getSiblingCategoryId:'0',
            product_name:'',
            image_url:'',
            description:'',
            product_worth:'',
            price_day:'',
            price_week:'',
            postcode:'',
            rent:'Yes',
            buy:'No',
            addRadiusBtn:true,
            miles_0:'',
            charge_0:'',
            miles_1:'',
            charge_1:''
        }
        this.goToNextScreen = this.goToNextScreen.bind(this);
        this.productImageUpload = this.productImageUpload.bind(this);
        this.handleChange = this.handleChange.bind(this);    
        this.getCategoryList = this.getCategoryList.bind(this);
        this.getSubCategoryList = this.getSubCategoryList.bind(this);
        this.getSiblingCategoryList = this.getSiblingCategoryList.bind(this);
        this.getCategory = this.getCategory.bind(this);
        this.getSubCategory = this.getSubCategory.bind(this);
        this.getSiblingCategory = this.getSiblingCategory.bind(this);
        this.stepFirst = this.stepFirst.bind(this);
        this.stepSecond = this.stepSecond.bind(this); 
        this.goToPrevScreen = this.goToPrevScreen.bind(this);
        this.handleDayPriceChange = this.handleDayPriceChange.bind(this);
        this.handleWeekPriceChange = this.handleWeekPriceChange.bind(this);
        this.handlePostCodeChange = this.handlePostCodeChange.bind(this);
        this.handlePickupDeliveryChange = this.handlePickupDeliveryChange.bind(this);
        this.initMap = this.initMap.bind(this);
        this.setRadius = this.setRadius.bind(this);
        this.addRadius = this.addRadius.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNumberChange = this.handleNumberChange.bind(this);
        this.checkPickupDelivery = this.checkPickupDelivery.bind(this);
        this.recieveCheckbox = this.recieveCheckbox.bind(this);
        this.handleMilesChange = this.handleMilesChange.bind(this);
        this.getUserData = this.getUserData.bind(this);
        this.deleteItemImage = this.deleteItemImage.bind(this);
        this.removeTopBar = this.removeTopBar.bind(this);  
    }
    removeTopBar(){
        this.setState({showError:''}); 
    }
    getUserData(){
        var _this = this;
        axios({
            method: 'get',
            url: Path.USER_URL+Path.URL.GET_USER_DETAILS,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {
            if(response.data.success == "1"){
                _this.setState({
                    stripeAccount: 666, //response.data.customer.stripe_account_id,
                    account_otp:response.data.customer.otp,
                    account_email_verify:response.data.customer.email_verify,
                    verifiedUser:response.data.customer.profile_status
                });
            }
        })
        .catch(function (error) {
            //console.log(error);
        });
    }    
    handleChange(e) {
        this.setState({showError:''});
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    handleMilesChange(e) {
        this.setState({showError:''});
        if(e.target.name == 'delivery_area[0][radius]'){
            this.setState({
                miles_0 : e.target.value
            });
        }
        if(e.target.name == 'delivery_area[1][radius]'){
            this.setState({
                miles_1 : e.target.value
            });
        }
        if(e.target.name == 'delivery_area[0][delivery_charge]'){
            this.setState({
                charge_0 : e.target.value
            });
        }
        if(e.target.name == 'delivery_area[1][delivery_charge]'){
            this.setState({
                charge_1 : e.target.value
            });
        }
    }
    handleNumberChange(e){      
        var theEvent = e || window.event;
        if (theEvent.type === 'paste') {
        key = e.clipboardData.getData('text/plain');
        } else {
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;
        if( !regex.test(key) ) {
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }  
    goToNextScreen(a,b){
        $('#'+a).removeClass('in').removeClass('active');
        $('#'+b).addClass('in').addClass('active');
    }
    goToPrevScreen(a,b){
        $('#'+a).addClass('in').addClass('active');
        $('#'+b).removeClass('in').removeClass('active');
    }
    componentDidMount() {
        this.getUserData();
        this.getCategoryList();
        this.checkPickupDelivery();
        if(reactLocalStorage.get('auth_token') === undefined){
            window.location = Path.BASE_URL+Path.PAGE_NAME.Home;
        }
        if($(".radius_wrapper").length == 1){
            this.setState({addRadiusBtn:false});
        }
        setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    productImageUpload(){
        var images = '';
        var widget = window.cloudinary.openUploadWidget({
            cloudName: Path.CLOUDINARY.cloudName,
            apiKey: Path.CLOUDINARY.apiKey,
            uploadPreset: Path.CLOUDINARY.uploadPreset,
            cropping: false,
            folder:Path.CLOUDINARY.folder,
            sources:['local','url'],
            croppingAspectRatio:'1',
            croppingDefaultSelectionRatio:'1.0',
            croppingShowDimensions:true,
            multiple: true,
            maxFileSize:'10000000',
            //minImageWidth:'200',
            //minImageHeight:'200',
            croppingValidateDimensions:true,
            //thumbnails:".product_thumbnail_list",
            //thumbnailTransformation:{ width: 90, height: 90, crop: 'fill' },
            maxFiles: '5',
            resourceType: 'image',
            styles:{
                palette: {
                    window: "#FFF",
                    windowBorder: "#90A0B3",
                    tabIcon: "#0E2F5A",
                    menuIcons: "#5A616A",
                    textDark: "#000000",
                    textLight: "#FFFFFF",
                    link:  "#0078FF",
                    action:  "#FF620C",
                    inactiveTabIcon: "#0E2F5A",
                    error: "#F44235",
                    inProgress: "#0078FF",
                    complete: "#20B832",
                    sourceBg: "#E4EBF1"
                },
                fonts: {
                    "Lato": "https://fonts.googleapis.com/css?family=Lato:400,700,900,900i",
                }
            }
        }, (error, result) => {
            if (result && result.event === "success") {
                images =  result.info.secure_url;
                var imageList = $("#product_image").val();
                if(imageList !== undefined && imageList !== ''){
                    imageList = $("#product_image").val()+',';
                    $("#product_image").val(imageList.concat(images));
                    this.setState({image_url:imageList.concat(images)});
                }else{
                    $("#product_image").val(images);
                    this.setState({image_url:images});
                }               
                $(".product_thumbnail_list").append("<li><img src='"+images+"'/><span class='delete_preview_image'>x</span></li>");
                setTimeout(function(){
                    //widget.close({quiet: true});
                },2000);
            }
        });
    }
    deleteItemImage(e){
        var _this = this;
        var images = [];
        if(e.target.className == 'delete_preview_image'){
            $(e.target).parent("li").remove();
            $("#product_image").val('');
            $(".product_thumbnail_list li").each(function(){
                images = $(this).find('img').attr('src');                
                var imageList = $("#product_image").val();
                if(imageList !== undefined && imageList !== ''){
                    imageList = $("#product_image").val()+',';
                    $("#product_image").val(imageList.concat(images));
                    _this.setState({image_url:imageList.concat(images)});
                }else{
                    $("#product_image").val(images);
                    _this.setState({image_url:images});
                }
            })
        }
    }
    stepFirst(e){
        if(this.state.product_name === undefined || this.state.product_name === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Enter product name');               
            });
            return false;
        }
        if(this.state.image_url === undefined || this.state.image_url === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Upload your images');               
            });
            return false;
        }
        if(this.state.product_name && this.state.image_url){
            this.setState({showError:''});
            this.goToNextScreen('step1','step2');
            this.setState({step1:false,step2:true,step3:false,step4:false});
            window.scrollTo(0, 0);
        }
    }
    stepSecond(e){

        //set daily rate to 0
        if(this.state.rent ===  'No'){
            this.setState(function(prevState, props){
                return {
                    price_day   : 0,
                    price_week  : 0
                }
            });
        }


        if(this.state.getCategoryId === undefined || this.state.getCategoryId === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Choose Category');               
            });
            return false;
        }
        if(this.state.getSubCategoryId === undefined || this.state.getSubCategoryId === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Choose Sub Category');               
            });
            return false;
        }
        if(this.state.description === undefined || this.state.description === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Enter Description');               
            });
            return false;
        }
        if(this.state.buy == 'Yes'){
            if(this.state.product_worth === undefined || this.state.product_worth === ''){
                this.setState({showError:'error'}, () => {
                    $("#error_msg").html('Enter valid worth of product');               
                });
                return false;
            }
        }
        else{
            this.setState({product_worth:'0'});
        }
        this.goToNextScreen('step2','step3');
        this.setState({step1:false,step2:false,step3:true,step4:false});
        window.scrollTo(0, 0);
    }
    handleDayPriceChange(e){
        this.setState({
            showError:'',
            price_day:e.target.value,
            price_week:e.target.value*7
        });  
    }
    handleWeekPriceChange(e){
        this.setState({
            price_week:e.target.value,
            showError:''
        });
    }
    handlePostCodeChange(e){
        this.setState({
            showError:'',
            [e.target.name]: e.target.value
        });
        let postcode = e.target.value;
        if(postcode.replace(/\s/g,'').length >=3){
            var _this = this;
            axios({
                method: 'get',
                url: Path.POST_CODE_API+postcode+'/validate'
            })
            .then(function (response) {
                if(response.data.result){
                    _this.setState({
                        postcode:postcode
                    });
                    axios({
                        method: 'get',
                        url: Path.POST_CODE_API+postcode
                    })
                    .then(function (response) {
                        _this.setState({postcodeCheck:true,latitude:response.data.result.latitude,longitude:response.data.result.longitude,location:response.data.result.region});
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
                }
                else{
                    _this.setState({showError:'error'}, () => {
                        $("#error_msg").html('Invalid Postcode');               
                    });
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
        }
    }    
    getCategoryList(){
        var _this = this;
        axios({
            method: 'get',
            url: Path.USER_URL + Path.URL.GET_CAT_LIST
        })
        .then(function (response) {
            _this.setState({category:response.data.category});
        })
        .catch(function (error) {
            //console.log(error);
        });
    }
    getSubCategoryList(id){
        var _this = this;
        axios({
            method: 'get',
            url: Path.USER_URL + Path.URL.GET_SUB_CAT_LIST+'/'+id
        })
        .then(function (response) {
            _this.setState({sub_category:response.data.subCategory});
        })
        .catch(function (error) {
            //console.log(error);
        });
    }
    getSiblingCategoryList(id){
        var _this = this;
        axios({
            method: 'get',
            url: Path.USER_URL + Path.URL.GET_SIBLING_CAT_LIST +'/'+id
        })
        .then(function (response) {
            _this.setState({sibling_category:response.data.siblingCategory});
        })
        .catch(function (error) {
        });
    }
    recieveCheckbox(e){
        var _this = this;
        _this.setState({
           [e.target.name]: e.target.value
        });
        if(e.target.checked){
            if(e.target.name == 'rent'){
                _this.setState({rent:'Yes'});
            }
            if(e.target.name == 'buy'){
                _this.setState({buy:'Yes'});
            }                    
        }
        else{
            if(e.target.name == 'rent'){
                _this.setState({rent:'No'});
            }
            if(e.target.name == 'buy'){
                _this.setState({buy:'No'});
            }
        }
    }   
    handleSubmit(event) {  
        event.preventDefault();

        /*if(this.state.rent ===  'No'){
            this.setState(function(prevState, props){
                return {
                    price_day   : 0,
                    price_week  : 0
            }
            });
        }*/

        if(this.state.price_day === undefined || this.state.price_day === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Enter valid daily price');               
            });
            return false;
        }
        if(this.state.price_week === undefined || this.state.price_week === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Enter valid weekly price');               
            });
            return false;
        }
        if(this.state.postcode === undefined || this.state.postcode === ''){
            this.setState({showError:'error'}, () => {
                $("#error_msg").html('Enter valid postcode');               
            });
            return false;
        }
        //if(this.state.price_day && this.state.price_week && this.state.postcode){
        if(this.state.postcode){
            $("#submitItemData").attr("disabled","disabled");
            const data = new FormData(event.target);
            if(this.state.buy == 'No'){
                data.append("buy", 'No');
            }
            if(this.state.rent == 'No'){
                data.append("rent", 'No');
            }
            if(this.state.pickup == false){
                data.append("pickup", 'No');
            }
            if(this.state.delivery == false){
                data.append("delivery", 'No');
            }


            if(this.state.price_week == 0){
                data.append("price_week", 0);
            }
            if(this.state.price_day == 0){
                data.append("price_day", 0);
            }
            data.append("cat_id", this.state.getCategoryId);
            data.append("subcat_id", this.state.getSubCategoryId);
            data.append("sibling_id", this.state.getSiblingCategoryId);
            data.append("location", this.state.location);
            data.append("latitude", this.state.latitude);
            data.append("longitude", this.state.longitude);
            
            
            

            var _this = this;           
            axios({
                method: 'post',
                url: Path.PRODUCT_URL + Path.URL.PRODUCT_ADD,
                headers: {
                    'Api-Token':'Bearer '+_this.state.auth_token
                },
                data:data
            })
            .then(function (response) {
                confirmAlert({
                    title: 'Your item has been successfully uploaded.',
                    buttons: [
                    {
                        label: 'OK',
                        onClick: () =>{
                            setTimeout(() => {
                                window.location = Path.BASE_URL+Path.PAGE_NAME.MyItems;
                            },1000);
                        }
                    }
                    ]
                });
                
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({showError:'error'}, () => {
                    $("#error_msg").html('Check your details');               
                });
                $("#submitItemData").removeAttr("disabled");
            });            
        }
    }
    getCategory(e){
        this.setState({
            getCategoryValue: e.target.value,
            getCategoryId: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].getAttribute("name")
        }, () => {            
            this.getSubCategoryList(this.state.getCategoryId); 
        });  
    }
    getSubCategory(e){
        this.setState({
            getSubCategoryValue: e.target.value,
            getSubCategoryId: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].getAttribute("name")
        }, () => {            
            this.getSiblingCategoryList(this.state.getSubCategoryId);
        });  
    }   
    getSiblingCategory(e){
        this.setState({
            getSiblingCategoryValue: e.target.value,
            getSiblingCategoryId: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].getAttribute("name")
        }, () => {            
        });  
    }    
    initMap() {        
        var map = new google.maps.Map(document.getElementById('map'), {
            zoom: 8,
            center: {lat: this.state.latitude, lng: this.state.longitude},
            mapTypeId: 'terrain'
        }); 
        var marker = new google.maps.Marker({
            position: {lat: this.state.latitude, lng: this.state.longitude},
            map: map,
            title: 'Hello World!'
        });
    }
    addRadius(){
        if(radiusKey == 1){
            this.setState({addRadiusBtn:false});
        }
        if(radiusKey<2){
            $("#radiusAddData").append('<div class="no-padding col-xs-12 radius_wrapper" id="radius_wrapper['+radiusKey+']"><div class="col-sm-4 col-xs-12 no-padding"><input class="create_pro_leftInput" type="text" placeholder="" name="delivery_area['+radiusKey+'][radius]" value="" /></div><div class="col-sm-4 col-xs-12 "><input class="create_pro_leftInput" type="text" placeholder="" name="delivery_area['+radiusKey+'][delivery_charge]" value="" /></div><div class="col-sm-3 col-xs-12 hide"><input class="radiuskeypress" type="text" placeholder="" name="delivery_area['+radiusKey+'][color_code]" value="#4f8515" /></div></div>');
            radiusKey++;
        }
    }
    setRadius() {
        var len = $('.radius_wrapper').length;
        var mapData = [];
        var i = 0;
        for (i = 0; i < len ;i++) {
            mapData.push({'radius':$('input[name="delivery_area['+i+'][radius]"]').val(),'fillColor':'#4f8515'});
        }
        var citymap = mapData;
        var map = new google.maps.Map(document.getElementById('map'), {
            zoom: 10,
            center: {lat: this.state.latitude, lng: this.state.longitude},
            mapTypeId: 'terrain'
        });   
        for (var city in citymap) {
            // Add the circle for this city to the map.
            var cityCircle = new google.maps.Circle({
                strokeColor: citymap[city].fillColor,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: citymap[city].fillColor,
                fillOpacity: 0.35,
                map: map,
                center: {lat: this.state.latitude, lng: this.state.longitude},
                radius: citymap[city].radius * 1609.34
            });
        }
    }
    handlePickupDeliveryChange(e) {        
        this.setState({
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        }, () => {                   
            if(this.state.pickup === true){
                this.setState({pickupOpt:'Yes'});
            }
            if(this.state.delivery === false){
                this.setState({showMap:false,deliveryOpt:'No'});
            }
            if(this.state.delivery === true){
                this.setState({showMap:true,deliveryOpt:'Yes'}, () => { 
                    this.initMap();
                });
            }
        });
    }
    checkPickupDelivery(){
        if(this.state.pickup === true){
            this.setState({pickupOpt:'Yes'});
        }
        if(this.state.delivery === false){
            this.setState({showMap:false,deliveryOpt:'No'});
        }
        if(this.state.delivery === true){
            this.setState({showMap:true,deliveryOpt:'Yes'}, () => { 
                this.initMap();
            });
        }
    }
    render(){
        const renderCategory = this.state.category.map(function(item, i) {
            return(
                <option name={item.id} key={i}>{item.category_name}</option>
            )
        },this);
        const renderSubCategory = this.state.sub_category.map(function(item, j) {
            return(
                <option name={item.id} key={j}>{item.sub_category_name}</option>
            )
        },this);
        const renderSiblingCategory = this.state.sibling_category.map(function(item, k) {
            return(
                <option name={item.id} key={k}>{item.sibling_category_name}</option>
            )
        },this);
        const coords = { lat: -21.805149, lng: -49.0921657 };
        return(
            <div>
                {this.state.showError === 'success' &&
                <div onClick={e => this.removeTopBar(e)} className="flashmessage show"><i className="fa fa-check"></i><span id="success_msg"></span></div>}
                {this.state.showError === 'error' &&
                <div onClick={e => this.removeTopBar(e)} className="flashmessage-error show-error"><i className="fa fa-close"></i><span id="error_msg"></span></div>
                }

                {/*{this.state.verifiedUser == '0'|| this.state.stripeAccount === undefined || this.state.stripeAccount === '' || this.state.account_email_verify == '0' || this.state.account_otp == '0' ? */}
                {this.state.verifiedUser == '0'|| this.state.account_email_verify == '0' || this.state.account_otp == '0' ?
                <div className="main_wrapper">
                    <div className="no_product_detail_found m-t-40 m-b-40">
                        <div className="col-xs-12 ReadyChangeGamep">Sorry - You will need to complete your profile verification before making any listing.</div>
                        <div className="col-xs-12 m-t-20"><a className="btn btn-success" href={Path.BASE_URL+Path.PAGE_NAME.Verifications}>Click here</a></div>
                    </div>
                </div>
                :
                <div className="main_wrapper">
                    <section className="create_pro">
                        <div className="container">
                            <div className="col-xs-12 create_pro_steps">                                
                                <ul>
                                    <li className={this.state.step1 === true ? "active" : ""}><a>1</a></li>
                                    <li className={this.state.step2 === true ? "active" : ""}><a>2</a></li>
                                    <li className={this.state.step3 === true ? "active" : ""}><a>3</a></li>
                                </ul>
                            </div>
                            <form className="postBg" onSubmit={this.handleSubmit}>
                                <div className="tab-content">                                
                                    <div id="add_item_message"></div>
                                    <div id="step1" className="tab-pane fade in active">
                                        <div className="col-sm-6 col-xs-12 create_pro_left">
                                            <h3 className=" create_pro_stepHead">Step 1</h3>
                                            <div className="create_pro_leftHead">Give your item a name...</div>
                                            <input className="create_pro_leftInput" type="text" placeholder="Bikes, drones, cameras, campervans..." name="product_name" value={this.state.product_name} onChange={e => this.handleChange(e)} />
                                            <div className="create_pro_leftHead">Add some photos...</div>
                                            <div id="product_image_view" className="col-xs-12 col-sm-8"><ul onClick={e =>this.deleteItemImage(e)} className="product_thumbnail_list"></ul></div>
                                            <input type="hidden" value={this.state.image_url} name="image_url" id="product_image"/>
                                            <label onClick={this.productImageUpload} className="create_pro_left_step1UP" htmlFor="create_pro_left_step1UPIn">
                                                <i className="fa fa-file-image-o"></i>
                                                <span className="create_pro_left_step1UP_tex">Click to upload images</span>
                                            </label>
                                            <div className="col-xs-12 no-padding">
                                                <div className="col-xs-12 col-sm-6 no-padding-left no-xs-padding">
                                                    <div className="productsBoxWrapper">
                                                        <div className="productsBoxTop">
                                                            Lending 
                                                            <div className="relative pull-right">
                                                                <input id="rent" type="checkbox" value={this.state.rent} checked={this.state.rent == 'Yes' && 'checked'} className="checkbox_swicth_input" name="rent" onChange={e => this.recieveCheckbox(e)}/>
                                                                <label htmlFor="rent" className="checkbox_swicth"></label>
                                                            </div>
                                                        </div>
                                                        <div className="productsBoxBottom">Available to rent</div>
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 col-sm-6 no-padding-right no-xs-padding">
                                                    <div className="productsBoxWrapper">
                                                        <div className="productsBoxTop">
                                                            Selling 
                                                            <div className="relative pull-right">
                                                                <input id="buy" type="checkbox" value={this.state.buy} checked={this.state.buy == 'Yes' && 'checked'} className="checkbox_swicth_input" name="buy" onChange={e => this.recieveCheckbox(e)}/>
                                                                <label htmlFor="buy" className="checkbox_swicth"></label>
                                                            </div>
                                                        </div>
                                                        <div className="productsBoxBottom">Available to buy</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.stripeAccount !== undefined && this.state.stripeAccount !== '' ?
                                                <a className="create_prof_next" onClick={e => this.stepFirst(e)}>Next</a>
                                            : ""
                                            }
                                            
                                        </div>
                                        <div className="col-sm-6 col-xs-12 create_pro_right">
                                            <div className="create_pro_right_bg">
                                                <div className="create_pro_rightHead">Top tips:</div>
                                                <p>1. Make sure you photograph the actual item (don't use stock images). People are more likely to request if they can see what they will actually be getting.</p>
                                                <p>2. Your photo will be cropped to a 4:3 ratio. You can click on the image to choose the crop area when it is uploading. Ideal dimensions are 1300 x 975 pixels.</p>
                                                <p>3. Pick your cover photo carefully, when uploading you can drag images to reorder. If it's a package make sure all items are shown in the cover photo.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="step2" className="tab-pane fade">
                                        <div className="col-sm-6 col-xs-12 create_pro_left">
                                            <h3 className=" create_pro_stepHead">Step 2</h3>
                                            <div className="create_pro_leftHead">Category 1</div>
                                            <div className="col-xs-12 no-padding" data-error="true">
                                                <select className="create_pro_leftInput" onChange={e => this.getCategory(e)} value={this.state.getCategoryValue}>
                                                    <option>Select Category</option>
                                                    {renderCategory}
                                                </select>
                                            </div>
                                            {this.state.sub_category !== undefined && this.state.sub_category != '' ?
                                            <div className="col-xs-12 no-padding">
                                                <div className="create_pro_leftHead">Category 2</div>
                                                <select className="create_pro_leftInput" onChange={e => this.getSubCategory(e)} value={this.state.getSubCategoryValue}>
                                                    <option>Select Sub Category</option>
                                                    {renderSubCategory}
                                                </select>
                                            </div>
                                            :
                                            ''}
                                            {this.state.sibling_category !== undefined && this.state.sibling_category != '' ?
                                            <div className="col-xs-12 no-padding">
                                                <div className="create_pro_leftHead">Category 3</div>
                                                <select className="create_pro_leftInput" onChange={e => this.getSiblingCategory(e)} value={this.state.getSiblingCategoryValue}>
                                                    <option>Select Sibling Category</option>
                                                    {renderSiblingCategory}
                                                </select>
                                            </div>
                                            :
                                            ''}
                                            <div className="create_pro_leftHead">Describe your item...</div>
                                            <textarea rows="8" className="create_pro_left_des" placeholder="Describe your item or package" name="description" value={this.state.description} onChange={e => this.handleChange(e)}></textarea>
                                            {this.state.buy == 'Yes' ?
                                            <div className="col-xs-12 create_pro_left">
                                                <div className="create_pro_leftHead">Set the sale price for your item</div>
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="fa fa-gbp"></i></span>
                                                    <input className="create_pro_leftInput form-control" type="text" placeholder="" name="product_worth" value={this.state.product_worth} onChange={e => this.handleChange(e)} onKeyPress={e => this.handleNumberChange(e)}/>
                                                </div>
                                            </div>
                                            :
                                            <input type="hidden" name="product_worth" value="0"/>
                                            }
                                            <a className="create_prof_back" onClick={e => this.goToPrevScreen('step1','step2')}>Back</a>
                                            <a className="create_prof_next" onClick={e => this.stepSecond(e)}>Next</a>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 create_pro_right">
                                            <div className="create_pro_right_bg">
                                                <div className="create_pro_rightHead">Top tips:</div>
                                                <p>1. Explain what is included / not included. Describe the condition and flag any faults.</p>
                                                <p>2. It's good to mention what other items you have that they could also rent.</p>
                                                <p>3. Try and answer all their questions in the description so they can just book without having to message you.</p>
                                            </div>
                                        </div>	
                                    </div>
                                    <div id="step3" className="tab-pane fade">
                                        <div className="col-sm-6 col-xs-12 create_pro_left">
                                            <h3 className=" create_pro_stepHead">Step 3</h3>





                                            <div className={(this.state.rent === 'No') ? 'hidden' : ''}>
                                                <div className="create_pro_leftHead">Set your price...</div>

                                                <div className="row">
                                                    <div className="col-sm-4 col-xs-12">
                                                        <label className="control-label">Daily (£)</label>
                                                        <input className="create_pro_leftInput"
                                                               type="text"
                                                               placeholder=""
                                                               name="price_day"
                                                               value={this.state.price_day}
                                                               onChange={e => this.handleDayPriceChange(e)}
                                                               onKeyPress={e => this.handleNumberChange(e)}
                                                        />
                                                    </div>
                                                    <div className="col-sm-4 col-xs-12">
                                                        <label className="control-label">Weekly (£)</label>
                                                        <input className="create_pro_leftInput"
                                                               type="text"
                                                               placeholder=""
                                                               name="price_week"
                                                               value={this.state.price_week}
                                                               onChange={e => this.handleWeekPriceChange(e)}
                                                               onKeyPress={e => this.handleNumberChange(e)}/>
                                                    </div>
                                                    <div className="col-sm-4 col-xs-12">
                                                        <p className="tooltipRate">(Note : You can set your own weekly
                                                            rate)</p>
                                                    </div>
                                                </div>

                                            </div>




                                            <div className="create_pro_leftHead">Item location...(Enter your Postal Code to add delivery location)</div>
                                            <input className="create_pro_leftInput" type="text" placeholder="Enter a Postcode" name="postcode" value={this.state.postcode} onChange={e => this.handlePostCodeChange(e)}/>
                                            {this.state.postcodeCheck === true &&
                                            <div className="col-xs-12 no-padding m-t-20">
                                                <input id="priceS1" type="checkbox" className="create_pro_leftPriceSI" name="pickup" value={this.state.pickupOpt} defaultChecked={this.state.pickup} onChange={e => this.handlePickupDeliveryChange(e)}/>
                                                <label htmlFor="priceS1" className="create_pro_leftPriceSL"><span>Pickup</span></label>
                                                <input id="priceS2" type="checkbox" className="create_pro_leftPriceSI" name="delivery" value={this.state.deliveryOpt} defaultChecked={this.state.delivery} onChange={e => this.handlePickupDeliveryChange(e)}/>
                                                <label htmlFor="priceS2" className="create_pro_leftPriceSL"><span>Delivery</span></label>
                                            </div>
                                            }
                                        </div>
                                        <div className="col-sm-6 col-xs-12 create_pro_right hidden-xs">
                                            <div className="create_pro_right_bg">
                                                <div className="create_pro_rightHead">Top tips:</div>
                                                <p>1. Make sure you photograph the actual item (don't use stock images). People are more likely to request if they can see what they will actually be getting.</p>
                                                <p>2. Your photo will be cropped to a 4:3 ratio. You can click on the image to choose the crop area when it is uploading. Ideal dimensions are 1300 x 975 pixels.</p>
                                                <p>3. Pick your cover photo carefully, when uploading you can drag images to reorder. If it's a package make sure all items are shown in the cover photo.</p>
                                            </div>
                                        </div> 
                                        <div className="col-xs-12">
                                            {this.state.showMap === true &&
                                            <div className="row">
                                                <div className="col-sm-6 col-xs-12 no-padding" id="radiusAddData">
                                                    <div className="create_pro_leftHead">Set your Delivery charge based on Miles</div>
                                                    <div className="no-padding col-xs-12 radius_wrapper" id="radius_wrapper[0]">                                                
                                                        <div className="col-sm-4 col-xs-6 no-padding">
                                                            <label className="control-label">Delivery Radius (Miles)</label> 
                                                            <input className="create_pro_leftInput" type="text" placeholder="Enter Miles" value={this.state.miles_0} name="delivery_area[0][radius]" onChange={e => this.handleMilesChange(e)} onKeyPress={e => this.handleNumberChange(e)}/>
                                                        </div>
                                                        <div className="col-sm-4 col-xs-6">
                                                            <label className="control-label">Charge (<i className="fa fa-gbp"></i>)</label>
                                                            <input className="create_pro_leftInput" type="text" placeholder="Enter Charges" value={this.state.charge_0} name="delivery_area[0][delivery_charge]" onChange={e => this.handleMilesChange(e)}  onKeyPress={e => this.handleNumberChange(e)}/>
                                                        </div>   
                                                        <div className="col-sm-3 col-xs-12 hide">
                                                            <label className="control-label">Color Code</label>
                                                            <input className="radiuskeypress" type="text" placeholder="" name="delivery_area[0][color_code]" defaultValue="#4f8515" />
                                                        </div>
                                                    </div>
                                                    <div className="no-padding col-xs-12 radius_wrapper" id="radius_wrapper[1]">
                                                        <div className="col-sm-4 col-xs-6 no-padding">
                                                            <input className="create_pro_leftInput" type="text" placeholder="Enter Miles" value={this.state.miles_1} name="delivery_area[1][radius]" onChange={e => this.handleMilesChange(e)} onKeyPress={e => this.handleNumberChange(e)}/>
                                                        </div>
                                                        <div className="col-sm-4 col-xs-6">
                                                            <input className="create_pro_leftInput" type="text" placeholder="Enter Charges" value={this.state.charge_1} name="delivery_area[1][delivery_charge]" onChange={e => this.handleMilesChange(e)}  onKeyPress={e => this.handleNumberChange(e)}/>
                                                        </div>   
                                                        <div className="col-sm-3 col-xs-12 hide">
                                                            <input className="radiuskeypress" type="text" placeholder="" name="delivery_area[1][color_code]" defaultValue="#4f8515" />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-8 col-xs-12 create_pro_left">
                                                        <a className="create_prof_back" onClick={e => this.goToPrevScreen('step3','step2')}>Back</a>
                                                        <button id="submitItemData" className="create_prof_next">Submit</button>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-xs-12 create_pro_right">
                                                    <div id="map" className="map_circle"></div>
                                                    <div className="col-sm-6 col-xs-12 create_pro_left">
                                                        <a className="create_prof_next" onClick={e => this.setRadius(e)}>Click to view in Map</a>
                                                    </div>
                                                </div>                                                
                                            </div>
                                            }                                        
                                        </div>
                                        {this.state.showMap !== true &&
                                        <div className="col-sm-6 col-xs-12 create_pro_left">
                                            <a className="create_prof_back" onClick={e => this.goToPrevScreen('step2','step3')}>Back</a>
                                            <button id="submitItemData" className="create_prof_next">Submit</button>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
                }                  
            </div> 
        );
    }
}
export default ProductAdd;