import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import Geocode from "react-geocode";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import ReactSVG from 'react-svg';
import {Image, Video, Transformation, CloudinaryContext} from 'cloudinary-react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {reactLocalStorage} from 'reactjs-localstorage';
import {TweenMax,TimelineMax,TimelineLite,TweenLite,Elastic,Sine,bbox,center,Power1,Circ} from 'gsap';
import ModalVideo from 'react-modal-video';
import Path from './Path';
import 'react-modal-video/css/modal-video.min.css';
Geocode.setApiKey(Path.API_LIST.GOOGLE_API_KEY);
Geocode.enableDebug();
class Home extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
            category:[],
            subcategory:[],
            product_images:[],
            productName:'',
            catName:'',
            subCatName:'',
            recent:'',
            cheapest:'',
            limit:'',
            nearest:'',
            similar:'',
            address: '',
            addressLabel: '',
            mode:reactLocalStorage.get("mode") === undefined ? 'rent' : reactLocalStorage.get("mode"),
            ratingValue:[],
            isOpen: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.showTopSubCategories = this.showTopSubCategories.bind(this);
        this.showCatBasedSubCatList = this.showCatBasedSubCatList.bind(this);
        this.showProducts = this.showProducts.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.randomFloatBetween = this.randomFloatBetween.bind(this);
        this.animationHands = this.animationHands.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleError = this.handleError.bind(this);
        this.changeMode = this.changeMode.bind(this);
        this.openModal = this.openModal.bind(this);
        this.goToLogin = this.goToLogin.bind(this);
        this.searchProductBasedList = this.searchProductBasedList.bind(this);
    }
    handleChange(e) {
        this.setState({
           [e.target.name]: e.target.value
        });
    }
    searchProductBasedList(subCatName,catName,subcat,cat){
        reactLocalStorage.set("catName",catName);
        reactLocalStorage.set("subCatName",subCatName);
        window.location = Path.BASE_URL+Path.PAGE_NAME.Search;
    }
    goToLogin(){
		$(".weborro_modal").removeClass('display-none').addClass("display-block");
	}
    openModal(){
        this.setState({isOpen: true})
    }
    handleLocationChange(addressLabel){
        this.setState({ addressLabel });
    }
    handleError(address){
        reactLocalStorage.set('getLocation',this.state.addressLabel);
        reactLocalStorage.set('getLat','55.378051');
        reactLocalStorage.set('getLng','-3.43597299999999');
        this.showProducts();
    }
    handleSelect(address){
        this.setState({'address':address});
        this.setState({'addressLabel':address});
        geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
            reactLocalStorage.set('getLocation',this.state.addressLabel);
            reactLocalStorage.set('getLat',lat);
            reactLocalStorage.set('getLng',lng);
            this.setState({latitude:lat,longitude:lng}, () => {
                //this.showProducts();
            })            
        }); 
    }
    handleKeyPress(e){
        if (e.key === 'Enter') {
            this.showProducts();
        }
    }
    changeMode(mode){
        this.setState({mode:mode}, () => {
            reactLocalStorage.set("mode",mode);
        });
    }
    showTopSubCategories(){
        var _this = this;
        axios({
            method: 'get',
            url: Path.USER_URL+Path.URL.TOP_SUBCAT_LIST
        })
        .then(function (response) {
            if(response.data.success == "1"){
                _this.setState({subcategory:response.data.subCategory,ratingValue:response.data.rating})
            }
        })
        .catch(function (error) {
            //console.log(error);
        });
    }
    showCatBasedSubCatList(){
        var _this = this;
        axios({
            method: 'get',
            url: Path.USER_URL+Path.URL.HOME_VIEW_CATEGORY
        })
        .then(function (response) {
            if(response.data.success == "1"){
                _this.setState({category:response.data.category})
            }
        })
        .catch(function (error) {
            //console.log(error);
        });
    }
    randomFloatBetween(minValue,maxValue,precision){
        if(typeof(precision) == 'undefined'){
            precision = 2;
        }
        return parseFloat(Math.min(minValue + (Math.random() * (maxValue - minValue)),maxValue).toFixed(precision));
    }
    animationHands(){
        var iconAnimation1 = new TimelineMax({
            repeat: -1,
            repeatDelay: 1
        });
        iconAnimation1.fromTo('#icon-1 #car', 1, {x:20, y:12, opacity:0}, {x: 0, y:0, opacity:1})
        .fromTo('#icon-1 #man', .5, {x:10, y:-5, opacity:0}, {x: 0, y:0, opacity:1})
        .fromTo('#icon-1 #text-bubble', 1, {rotation:'-90deg', scale: 0, opacity: 0, svgOrigin: '94px 112px'}, { rotation:'0deg', scale: 1, opacity: 1,	ease: Elastic.easeOut.config(1, 1),	svgOrigin: '94px 112px'}, 1.5)
        .to('#icon-1 #right-forearm', .4, {rotation: '15deg', yoyo: true, repeat: 1, svgOrigin: '96px 143px'}, 1.5)
        .to('#icon-1 #left-forearm', .4, {rotation: '-15deg', yoyo: true, repeat: 1, svgOrigin: '114px 146px'}, 1.5)
        .to(['#icon-1 #car, #icon-1 #man, #icon-1 #text-bubble'], 1, { opacity: 0}, 3.5); 
        var iconAnimation2 = new TimelineMax({
            repeat: -1,
            repeatDelay: 1
        }),
        counter = { number: 0.0 };            
        iconAnimation2
        .fromTo('#icon-2 #phone', 1, {y: -15, opacity: 0}, {y: 0, opacity: 1, ease: Sine.easeInOut})
        .to('#icon-2 #dark-screen', .7, {opacity: 0}, 1.1)
        .fromTo('#icon-2 #right-arm', .5, {rotation: '-105deg', svgOrigin: '91px 115px'}, {rotation: '0deg', svgOrigin: '91px 115px', ease: Power1.easeOut}, 1.7)
        .to('#icon-2 #right-arm', .15, {x: -2, y: -2, rotation: '-5deg', svgOrigin: '91px, 115px', ease: Sine.easeInOut, yoyo: true, repeat: 1}, 2.5)
        .to('#icon-2 #button', .1, {scale: 1.1, svgOrigin: '71.5px, 93.5px', ease: Sine.easeInOut, yoyo: true, repeat: 1}, 2.65)
        .fromTo('#icon-2 #tent-and-distance', .5, {opacity: 0}, {opacity: 1}, 2.65)
        .to(counter, 2, {
              number: 2.3, 
              onUpdate: function () {
                  if(document.querySelectorAll('#icon-2 #distance')[0]) {
                  document.querySelectorAll('#icon-2 #distance')[0].innerHTML = counter.number.toFixed(1);
                  }
              },
              ease:Circ.easeOut
          }, 2.65)
        .to('#icon-2 #phone', 1, {opacity: 0}, 6)
        .to('#icon-2 #right-arm', 1, {x: 0, y: 0, rotation: '-105deg', svgOrigin: '91px 115px', ease: Sine.easeInOut}, 3);
        var iconAnimation3 = new TimelineMax({
            repeat: -1,
            repeatDelay: 1.5
        });            
        iconAnimation3
        .fromTo('#icon-3 #woman', .7, {x:7, y:-5, opacity:0}, {x: 0, y:0, opacity:1})
        .fromTo('#icon-3 #text-bubble', 1, {rotation:'90deg', scale: 0, opacity: 0, svgOrigin: '146.66px 99.79px'}, {rotation:'0deg', scale: 1, opacity: 1,	ease: Elastic.easeOut.config(1, 1),	svgOrigin: '146.55px 99.79px'}, .7)
        .to('#icon-3 #right-arm', .4, {rotation: '15deg', yoyo: true, repeat: 1, svgOrigin: '123.9px 150.3px'}, .7)
        .to('#icon-3 #left-arm', .4, {rotation: '-15deg', yoyo: true, repeat: 1, svgOrigin: '136.5px 153.1px'}, .7)
        .to('#icon-3 #tent-glow', .4, {opacity: .5, repeat: 3, yoyo: true}, 1.4)
        .to(['#icon-3 #woman, #icon-3 #text-bubble'], 1, { opacity: 0}, 4.5);            
        TweenMax.fromTo('#icon-3 #drone', 1, {y: -55}, {y: -60, repeat: -1, yoyo: true, ease: Sine.easeInOut});
        var iconAnimation4 = new TimelineMax({
            repeat: -1,
            repeatDelay: 1
        });            
        iconAnimation4
        .fromTo('#icon-4 #window', 2, {y: 4}, {y: 0, repeat: -1, yoyo: true, ease: Sine.easeInOut})
        .to('#icon-4 #head', 2, {rotation: '-8deg', svgOrigin: '97.8px 124.2px', repeat: -1, yoyo: true, ease: Sine.easeInOut}, 0)
        .to('#icon-4 #right-arm', 1, {rotation: '10deg', svgOrigin: '102.2px 129.8px', repeat: -1, yoyo: true, repeatDelay: 1, ease: Sine.easeInOut}, 0)
        .fromTo('#icon-4 #progress-bar', 2.5, {x: -5, y:-3}, {x: 5, y:3, repeat: -1, yoyo: true, repeatDelay: 1, ease: Sine.easeInOut}, 0);
        var iconAnimation5 = new TimelineMax({
            repeat: -1,
            repeatDelay: 1
        });            
        iconAnimation5
        .fromTo('#icon-5 #woman', .7, {x:7, y:-5, opacity:0}, {x: 0, y:0, ease: Sine.easeInOut, opacity:1})
        .fromTo('#icon-5 #man', .7, {x:-7, y:5, opacity:0}, {x: 0, y:0, ease: Sine.easeInOut, opacity:1})
        .fromTo('#icon-5 #man .right-arm', .5, {rotation: '30deg', svgOrigin: '152px 130px'}, {rotation: '0deg', svgOrigin: '152px 130px'}, 1.8)
        .fromTo('#icon-5 #woman .right-arm', .5, {rotation: '-20deg', svgOrigin: '175px 121px'}, {rotation: '0deg', svgOrigin: '175px 121px'}, 1.8)
        .fromTo('#icon-5 #man .right-arm', .2, {x: 0, y: 0, svgOrigin: '152px 130px'}, {rotation: '4deg', x: .5, y: .5, repeat: 3, yoyo: true, ease: Sine.easeInOut, svgOrigin: '152px 130px'}, 2.5)
        .fromTo('#icon-5 #woman .right-arm', .2, {x: 0, y: 0, svgOrigin: '175px 121px'}, {rotation: '-4deg', x: -.5, y: .5, repeat: 3, yoyo: true, ease: Sine.easeInOut, svgOrigin: '175px 121px'}, 2.5)            
        .fromTo('#icon-5 #text-bubble-1', 1, {rotation:'90deg', scale: 0, opacity: 0, svgOrigin: '153.79px 105.7px'}, { rotation:'0deg', scale: 1, opacity: 1,	ease: Elastic.easeOut.config(1, 1),	svgOrigin: '153.79px 105.7px'})
        .to('#icon-5 #text-bubble-1', .3, {opacity: 0}, '+=.5')            
        .fromTo('#icon-5 #text-bubble-2', 1, {rotation:'-90deg', scale: 0, opacity: 0, svgOrigin: '171.08px 105.69px'}, { rotation:'0deg', scale: 1, opacity: 1,	ease: Elastic.easeOut.config(1, 1),	svgOrigin: '171.08px 105.69px'})
        .to('#icon-5 #text-bubble-2', .3, {opacity: 0}, '+=.5')            
        .to('#icon-5 #man, #icon-5 #woman', 1, {opacity: 0}, '+=1')
        var iconAnimation6 = new TimelineMax({
            repeat: -1,
            repeatDelay: 1
        });           
        for (var i = 0; i < 8; i++) {
            TweenMax.to('#icon-6 #person-' + i + ' .left-arm', this.randomFloatBetween(0.8, 1.5, 2), {rotation: '10deg', repeat: -1, yoyo: true, ease: Sine.easeInOut}, 0);
            TweenMax.to('#icon-6 #person-' + i + ' .right-arm', this.randomFloatBetween(0.8, 1.5, 2), {rotation: '-10deg', repeat: -1, yoyo: true, ease: Sine.easeInOut}, 0);
            TweenMax.to('#icon-6 #person-' + i + ' .left-forearm', this.randomFloatBetween(0.8, 1.5, 2), {rotation: '-15deg', repeat: -1, yoyo: true, ease: Sine.easeInOut}, 0)
            TweenMax.to('#icon-6 #person-' + i + ' .right-forearm', this.randomFloatBetween(0.8, 1.5, 2), {rotation: '15deg', repeat: -1, yoyo: true, ease: Sine.easeInOut}, 0)
        }            
        iconAnimation6
        .fromTo('#icon-6 #floating-logo', 3, {y: -5}, {y: -12, repeat: -1, yoyo: true, ease: Sine.easeInOut})
        .fromTo('#icon-6 #drone', 1.5, {y: -5}, {y: -12, repeat: -1, yoyo: true, ease: Sine.easeInOut}, 0)
        .fromTo('#icon-6 #dog-tail', 1, {rotation: '-10deg', svgOrigin: '59.56px 182.5px'}, {rotation: '0deg', svgOrigin: '59.56px 182.5px', repeat: -1, yoyo: true, ease: Sine.easeInOut}, 0)
        document.querySelectorAll("#icon-6 .left-arm, #icon-6 .left-forearm").forEach(function(el) {
            var bbox = el.getBBox();
            var center = {
                x: bbox.x,
                y: bbox.y + bbox.height - 3
            };
            el.setAttribute('data-svg-origin', center.x + "px " + center.y + "px");
        });            
        document.querySelectorAll("#icon-6 .right-arm, #icon-6 .right-forearm").forEach(function(el) {
            var bbox = el.getBBox();
            var center = {
                x: bbox.x + bbox.width - 5,
                y: bbox.y + bbox.height
            };
            el.setAttribute('data-svg-origin', center.x + "px " + center.y + "px");
        });
    }    
    componentDidMount() {        
        this.showTopSubCategories();
        this.showCatBasedSubCatList();
        setTimeout(() => {
            this.animationHands();
        },2000);
        const location = window.navigator && navigator.geolocation;
        if (location) {
            location.getCurrentPosition((position) => {
                this.setState({
                allowlatitude: position.coords.latitude,
                allowlongitude: position.coords.longitude
            })
            let lat = this.state.allowlatitude;
            let long = this.state.allowlongitude;
            Geocode.fromLatLng(lat, long).then(
                response => {
                    const address = response.results[0].formatted_address;
                    reactLocalStorage.set('getLocation',address);
                    reactLocalStorage.set('getLat',this.state.allowlatitude);
                    reactLocalStorage.set('getLng',this.state.allowlongitude);
                    this.setState({addressLabel:address});
                    this.setState({latitude:this.state.allowlatitude,longitude:this.state.allowlongitude})                    
                },
                error => {
                    console.error(error);
                }
            );
        },(error) => {
                this.setState({ allowlatitude: '', allowlongitude: '' })
            })
        }
        else{
            this.setState({address:Path.LOCATION});
            const address = {addressLabel:Path.LOCATION};
        }
        setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    showProducts(){
        reactLocalStorage.set("productName",this.state.productName);
        reactLocalStorage.set("catName",this.state.catName);
        reactLocalStorage.set("subCatName",this.state.subCatName);
        reactLocalStorage.set("recent",this.state.recent);
        reactLocalStorage.set("cheapest",this.state.cheapest);
        reactLocalStorage.set("limit",this.state.limit);
        reactLocalStorage.set("nearest",this.state.nearest);
        reactLocalStorage.set("similar",this.state.similar);
        window.location = Path.BASE_URL+Path.PAGE_NAME.Search;
    }
    componentWillReceiveProps(){
        if(reactLocalStorage.get('auth_token') !== undefined && reactLocalStorage.get('auth_token') !== ''){
            this.setState({loggedUserCheck:true});
        }
    }
    render(){
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };
        const renderTopCategoryList = this.state.subcategory.map(function(item, i) {
            return(
                <div className="col-xs-12 no-padding" key={i}>
                    <h3 className="ho_renshop_sH">{item.sub_category_name}</h3>
                    <div className="col-xs-12 no-padding">
                        <div className="row">
                            <Slider {...settings}>
                                {item.product.map(function(subitem, j) {

                                    return(
                                        <a key={j} className="col-sm-4 col-md-3" href={Path.BASE_URL+Path.PAGE_NAME.ProductDetails+'/'+subitem.lending_id} >
                                            <div className="ho_renshop_p">
                                                <div className="ho_renshop_pB">
                                                    {subitem.product_images.slice(0,1).map(function(images, m) {
                                                        return(
                                                        <img key={m} src={Path.CLOUDINARY.url+'/c_fill,h_270,w_360/'+Path.CLOUDINARY.product_image+images.image_url.replace(/^.*[\\/]/, '')} onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_IMAGE}} />
                                                        )
                                                    })}
                                                    <span className="ho_renshop_pB_amount">
                                                        <span className="ho_renshop_pB_amountCo">
                                                        <div>
                                                            {(subitem.rent === 'Yes')
                                                                ? "from only"
                                                                : "price"
                                                            }
                                                        </div>
                                                        <span className="currency">
                                                            {/*<i className="fa fa-gbp"></i>{subitem.price_day}<br/>*/}
                                                            {(subitem.rent === 'Yes')

                                                                ? <React.Fragment> £{subitem.price_day} </React.Fragment>
                                                                : <React.Fragment> £{subitem.product_worth} </React.Fragment>

                                                            }
                                                            <br/>
                                                        </span>
                                                        <div>
                                                           {(subitem.rent === 'Yes')
                                                                ? "per day"
                                                                : "only"
                                                            }
                                                         </div>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="ho_renshop_c">
                                                    <div className="ho_renshop_cH">{subitem.product_name}</div>
                                                    <div className="ho_renshop_cM">
                                                        {/*£{subitem.price_day} a day <span>or see offers</span>*/}
                                                        £{(subitem.rent === 'Yes')

                                                            ? <React.Fragment> {parseFloat(subitem.price_day) + ' a day'} <span>or see offers</span></React.Fragment>
                                                            : <React.Fragment> {parseFloat(subitem.product_worth) + ' to buy'} </React.Fragment>

                                                        }
                                                    </div>
                                                    <div className="ho_renshop_cS">
                                                        <span className="postcode_wrap">{subitem.postcode}</span>
                                                        {this.state.ratingValue.map(function(rating,q) {
                                                        return(
                                                            <div key={q} className="pull-right">
                                                                {rating.user_id === subitem.user_id &&
                                                                <div className="pull-left star_rating_wrap star_rating_small">                                                                    
                                                                    <span className="star_rating_active" style={{width:(rating.rating*20)+'%'}}></span>
                                                                </div>
                                                                }
                                                                {rating.user_id === subitem.user_id &&
                                                                    <span className="postcode_wrap">({rating.ratingCount})</span>
                                                                }
                                                            </div>
                                                        )
                                                    },this)}
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    )
                                },this)} 
                            </Slider>  
                        </div>
                    </div>
                </div>
            )
        },this);
        const renderCatBasedSubCatList = this.state.category.map(function(item, n) {
            return(
                <div className="col-md-3 col-sm-3 col-xs-12 ho_eve_T" key={n}>
                    <h3>{item.category_name}</h3>
                    <ul>
                        {item.sub_category.map(function(subitem, o) {
                            return(
                                <li key={o}><a onClick={e => this.searchProductBasedList(subitem.sub_category_seo_name,item.category_seo_name,subitem.sub_category_name,item.category_name)}>{subitem.sub_category_name}</a></li>
                            )
                        },this)} 
                    </ul>
                </div>
            )
        },this);
        const renderInput = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
        <div className="autocomplete-root">
            <input className="form-control" {...getInputProps({
                placeholder:"Enter your postcode or city",
                className:"form-control"
            })} />
            <ul className="autocomplete-dropdown-container">
                {suggestions.map(suggestion => (                
                    <li {...getSuggestionItemProps(suggestion)} className="suggestion">
                        {suggestion.terms.length > 2 ? 
                            <span className="cityname1">{suggestion.terms[0].value}<span className="cityname2">, {suggestion.terms[1].value}</span></span>
                        : 
                        <span className="cityname1">{suggestion.terms[0].value}</span>
                        }
                    </li>
                ))}
            </ul>
        </div>
        );
        // Limit the suggestions to show only cities in the US
        const searchOptions = {
        //types: ['(cities)'],
        types: ['geocode'], //For Home Page
        componentRestrictions: {country: "uk"}
        }
        return(
            <div>
                <section className="ho_bg" id="banner_section">
                    <div className="container">
                        <div className="col-md-12 col-sm-12 col-xs-12 ho_bg_left">
                            <h1 className="visible-xs">What do you Want to Borrow?</h1>
                            <h1 className="hidden-xs">What do you<br/> Want to<br/> Borrow?</h1>
                            <ul className="rentorbuymode">
                                <li className={this.state.mode == 'rent' ? "active" : ""} onClick={e => this.changeMode('rent')}>Rent</li>
                                <li className={this.state.mode == 'buy' ? "active" : ""} onClick={e => this.changeMode('buy')}>Buy</li>
                            </ul>
                            <div className={this.state.mode == 'rent' ? "bg_sBg" : "bg_sBg active"} onKeyPress={(event) => this.handleKeyPress(event)}>
                                <div className="bg_sBgS">
                                    <input type="text" className="form-control" onChange={e => this.handleChange(e)} name="productName" value={this.state.productName} placeholder="Bikes, drones, cameras, campervans..."/>
                                    <img src={Path.IMAGE_PATH+"/search.png"} className="ser_SaIc"/>
                                </div>
                                <div className="bg_sBgL">                                
                                    <PlacesAutocomplete
                                        value={this.state.addressLabel}
                                        onChange={this.handleLocationChange}
                                        onSelect={this.handleSelect}
                                        onError={this.handleError}
                                        clearItemsOnError={true}
                                        // Pass the search options prop
                                        searchOptions={searchOptions}
                                    >
                                        {renderInput}
                                    </PlacesAutocomplete>
                                    <img src={Path.IMAGE_PATH+"/location.png"} className="ser_LoIc"/>
                                </div>
                                <div className="bg_sBgB">
                                    <a className="bg_sBgBtn width_100 text-center" onClick={this.showProducts}>Search</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="ReadyChangeGame">
                    <h2>Ready to Change the Game</h2>
                    <p>Borrowing, lending and helping the environment with Weborro couldn't be easier</p>
                    <div className="container">
                        <div className="col-md-4 col-sm-4 col-xs-12 ReadyChangeGame_types">
                            <img className="readyTostartImage" src={Path.IMAGE_PATH+"/resume.svg"}/>
                            {this.props.loggedUser === true || this.state.loggedUserCheck === true? 
                            <a href={Path.BASE_URL+Path.PAGE_NAME.MyAccount}>Register</a>
                            :
                            <a onClick={e => this.goToLogin(e)}>Register</a>
                            }
                            <span>Create an account in no time</span>       
                            <p className="readyTostartTextDesc">Takes 2 mins to become a borrower</p> 
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12 ReadyChangeGame_types">
                            <img className="readyTostartImage" src={Path.IMAGE_PATH+"/camera.svg"}/>
                            {this.props.loggedUser === true || this.state.loggedUserCheck === true? 
                            <a href={Path.BASE_URL+Path.PAGE_NAME.MyAccount}>List</a>
                            :
                            <a onClick={e => this.goToLogin(e)}>List</a>
                            }
                            <span>Choose to borro or sell your items</span>    
                            <p className="readyTostartTextDesc">Have a rummage around your home and see what you could rent out</p>    
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12 ReadyChangeGame_types">
                            <img className="readyTostartImage" src={Path.IMAGE_PATH+"/friendship.svg"}/>
                            {this.props.loggedUser === true || this.state.loggedUserCheck === true? 
                            <a href={Path.BASE_URL+Path.PAGE_NAME.MyAccount}>Borro</a>
                            :
                            <a onClick={e => this.goToLogin(e)}>Borro</a>
                            }
                            <span>Exchange and meet new people</span>      
                            <p className="readyTostartTextDesc">Keep it local, rent to people close by</p>
                        </div>
                    </div>
                </section>
                <section className="ho_renshop">
                    <div className="container">
                        <h2>Open your own rental shop at home and earn upto £200 a week</h2>
                        <div className="col-xs-12 text-center m-t-20 m-b-20">
                            {this.props.loggedUser === true || this.state.loggedUserCheck === true? 
                            <a className="shop_now_btn" href={Path.BASE_URL+Path.PAGE_NAME.ProductAdd}>Open Shop</a>
                            :
                            <a className="shop_now_btn" onClick={e => this.goToLogin(e)}>Open Shop</a>
                            }
                        </div>
                        <div className="home_video_wrap">
                            <p className="m-t-10 hide">Click here to see how weborro will help you borro!</p>
                            <img className="play_home_video" onClick={e => this.openModal(e)} src={Path.IMAGE_PATH+"/play_button.png"} />
                            <ModalVideo channel='youtube' autoplay='0' rel='0' isOpen={this.state.isOpen} videoId={Path.YOUTUBE_ID} onClose={() => this.setState({isOpen: false})} />
                        </div>
                        {renderTopCategoryList}
                    </div>
                </section>
                {/*<section className="borro_better bg_grey">
                    <div className="container">
                        <h2> We do things a little different at weborro</h2>
                        <p>Borrowing is better with Weborro</p>
                        <img className="borro_better_banner" src={Path.IMAGE_PATH+"/borrowbetterbg.jpg"}/>
                        <div className="col-xs-12 no-padding">
                            <div className="col-md-4 col-sm-4 col-xs-12 borro_better_left">    
                                <h3>"The option to Sell or Lend is a great way to help borrowers decide what would be a better for them"</h3>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12 borro_better_center">    
                                <h3>The new approach to lending and borrowing</h3>
                                <p>At Weborro we believe in doing things a little differently because life can be so much better. You can buy anything you want off of Amazon and eBay, but what if you don’t want to buy it? What if you want to borrow it? That’s how Weborro was born, and here’s why it’s a great idea for borrowers, owners, and even smart investors:</p>
                                <p><b>Borrow</b> what you need when you need it </p>
                                <p><b>Invest</b> in a new platform that’s changing the way people do business</p>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12 borro_better_center">    
                            <img src={Path.IMAGE_PATH+"/borro_better_right.jpg"}/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="ho_neb">
                    <div className="container">
                        <div className="col-md-6 col-sm-6 col-xs-12 ho_neb_left">
                            <h2>Love thy neighbour</h2>
                            <p>We encourage you to borro and lend with people closest to you. You'll never know what your neighbour has stored away in their shed or upstairs in their attic. Sometimes</p>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12 ho_neb_right">
                            <img src={Path.IMAGE_PATH+"/user.png"}/>
                        </div>
                    </div>
                </section>*/}
                <section className="ho_eve">
                    <div className="container">
                        <h2>Something for everyone</h2>
                        <p>Check out our wide range of categories</p>
                        <div className="col-xs-12 no-padding">
                            {renderCatBasedSubCatList}
                        </div>
                    </div>
                </section>
            </div> 
        );
    }
}
export default GoogleApiWrapper({
    apiKey: (Path.API_LIST.GOOGLE_API_KEY)
})(Home)