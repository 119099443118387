import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {Image, Video, Transformation, CloudinaryContext} from 'cloudinary-react';
import {TweenMax,TimelineMax,TimelineLite,TweenLite,Elastic,Sine,bbox,center,Power1,Circ} from 'gsap';
import {reactLocalStorage} from 'reactjs-localstorage';
import Path from './Path';
import Sidebar from './Sidebar';
class Notification extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
            auth_token : reactLocalStorage.get('auth_token')
        }
        this.handleChange = this.handleChange.bind(this);
        this.loadData = this.loadData.bind(this);
    }
    handleChange(e) {
        this.setState({
           [e.target.name]: e.target.value
        });
    }
    componentDidMount() {
        setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    loadData(){
        
    }  
    render(){
        return(
            <div>               
                <div className="main_wrapper">
                    <section className="dashboard_bg">
                        <Sidebar/>
                        <div className="content-wrapper">
                            <div className="content-height-wrapper">
                                <div className="tab-content">
                                    <div className="col-xs-12 text-center m-t-40">
                                        <div className="watchlist_contact_text">Looks like you're not watching any users yet</div>
                                        <a href={Path.BASE_URL+Path.PAGE_NAME.Search} className="watchlist_contact_btn">Check out some items</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div> 
        );
    }
}
export default Notification;