import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {Image, Video, Transformation, CloudinaryContext} from 'cloudinary-react';
import {TweenMax,TimelineMax,TimelineLite,TweenLite,Elastic,Sine,bbox,center,Power1,Circ} from 'gsap';
import {reactLocalStorage} from 'reactjs-localstorage';
import Path from './Path';
import Sidebar from './Sidebar';
class Verifications extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
            auth_token : reactLocalStorage.get('auth_token'),
            emailOtp:'',
            mobileOtp:'',
            //showError:'',
            address: '',
            addressLabel:' ',
            editProfile:false,
            profile_image:Path.IMAGE_PATH+"/profile.png",
            profile_image_upload:'Upload Profile Image',
            editcity: " ",
            invoice_period:'15',
            showError:false,
            bio_info:'',
            stripeAccount:'',
            profile_status:'0',
            profile_info_status:'0',
            current_pwd:'',
            new_pwd:'',
            confirm_new_pwd:'',
            social_login:'0',

            //Bank details
            editBankDetails: false

        }
        this.handleChange = this.handleChange.bind(this);
        this.loadData = this.loadData.bind(this);
        this.mobileModal = this.mobileModal.bind(this);
        this.emailModal = this.emailModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.getUserData = this.getUserData.bind(this);
        this.submitMobileOtp = this.submitMobileOtp.bind(this);
        this.submitEmailOtp = this.submitEmailOtp.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.editProfile = this.editProfile.bind(this);
        this.clicktoeditProfile = this.clicktoeditProfile.bind(this);
        this.profileImageUpload = this.profileImageUpload.bind(this);
        this.handlePostCodeChange = this.handlePostCodeChange.bind(this);
        this.disconnectStripe = this.disconnectStripe.bind(this);
    }
    handleChange(e) {
        this.setState({
           [e.target.name]: e.target.value
        });
    }
    handleLocationChange(editcity){
        this.setState({ editcity });
    }
    editProfile(){
        var _this = this;
        axios({
            method: 'post',
            url: Path.USER_URL + Path.URL.UPDATE_PROFILE,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            },
            data: {
                "name":_this.state.editname,
                "lastname":_this.state.editlastname,
                "profile_image":_this.state.profile_image,
                "mobile_number":_this.state.editmobile_number,
                "invoice_period":_this.state.editinvoice_period,
                "city":_this.state.editcity,
                "latitude":_this.state.latitude,
                "longitude":_this.state.longitude,
                "postcode":_this.state.postcode,
                "bio_info":_this.state.bio_info,
                "receive_notification":_this.state.receive_notification,
                "receive_promotion": _this.state.receive_promotion,
                "address_line1":_this.state.address_line1,
                "address_line2":_this.state.address_line2
            }
        })
        .then(function (response) {
            _this.getUserData();
            _this.setState({editProfile:false});
        })
        .catch(function (error) {
            //console.log(error);
        });
    }
    handlePostCodeChange(e){
        this.setState({
            showError:'',
            [e.target.name]: e.target.value
        });
        let postcode = e.target.value;
        if(postcode.replace(/\s/g,'').length >=3){
            var _this = this;
            axios({
                method: 'get',
                url: Path.POST_CODE_API+postcode+'/validate'
            })
            .then(function (response) {
                if(response.data.result){
                    _this.setState({
                        postcode:postcode
                    });
                    axios({
                        method: 'get',
                        url: Path.POST_CODE_API+postcode
                    })
                    .then(function (response) {
                        _this.setState({postcodeCheck:true,latitude:response.data.result.latitude,longitude:response.data.result.longitude,location:response.data.result.region});
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
                }
                else{
                    _this.setState({showError:'error'}, () => {
                        $("#error_msg").html('Invalid Postcode');               
                    });
                }
            })
            .catch(function (error) {
                //console.log(error);
            });
        }
    } 
    profileImageUpload(){
        var widget = window.cloudinary.openUploadWidget({
            cloudName: Path.CLOUDINARY.cloudName,
            apiKey: Path.CLOUDINARY.apiKey,
            uploadPreset: Path.CLOUDINARY.uploadPreset,
            folder:Path.CLOUDINARY.folder,
            cropping: true,
            multiple: false,
            sources:['local','url'],
            croppingAspectRatio:'1',
            croppingDefaultSelectionRatio:'1.0',
            croppingShowDimensions:true,
            maxFileSize:'10000000',
            croppingValidateDimensions:true,
            styles:{
                palette: {
                    window: "#FFF",
                    windowBorder: "#90A0B3",
                    tabIcon: "#0E2F5A",
                    menuIcons: "#5A616A",
                    textDark: "#000000",
                    textLight: "#FFFFFF",
                    link:  "#0078FF",
                    action:  "#FF620C",
                    inactiveTabIcon: "#0E2F5A",
                    error: "#F44235",
                    inProgress: "#0078FF",
                    complete: "#20B832",
                    sourceBg: "#E4EBF1"
                },
                fonts: {
                    "Lato": "https://fonts.googleapis.com/css?family=Lato:400,700,900,900i",
                }
            }
        }, (error, result) => {
            if (result && result.event === "success") {
                console.log(result);
                //widget.close({quiet: true});
                if(typeof(result.info.coordinates) != 'undefined'  || result.info.coordinates != null){
                    let imageurl = "http://res.cloudinary.com/"+Path.CLOUDINARY.cloudName+"/image/upload/x_"+result.info.coordinates.custom[0][0]+",y_"+result.info.coordinates.custom[0][1]+",w_"+result.info.coordinates.custom[0][2]+",h_"+result.info.coordinates.custom[0][3]+",c_crop/"+result.info.path+"";
                    //$("#profile_image_view").attr("src",imageurl);
                    //$("#profile_image").val(imageurl);   
                    this.setState({profile_image:imageurl});
                }
                else{
                    //$("#profile_image_view").attr("src",result.info.secure_url);
                    //$("#profile_image").val(result.info.secure_url);
                    this.setState({profile_image:result.info.secure_url});
                }                
            }
        });
    }
    clicktoeditProfile(){
        this.setState({editProfile:true});
    }
    handleSelect(address){
        this.setState({'address':address});
        this.setState({'editcity':address.substring(0, address.length-4)});
        geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
            reactLocalStorage.set('getLocation',this.state.editcity);
            reactLocalStorage.set('getLat',lat);
            reactLocalStorage.set('getLng',lng);
            this.setState({latitude:lat,longitude:lng,city:this.state.editcity});
        });
    }
    mobileModal(){
        this.setState({reviewshow: true}, () => {
            var _this = this;
            axios({
                method: 'post',
                url: Path.USER_URL + Path.URL.MOBILE_VERIFY,
                data: {
                    'auth_token':_this.state.auth_token,
                    'mobile_number':_this.state.mobile_number
                }
            })
            .then(function (response) {
                _this.setState({otpnumber: atob(response.data.data.result)});
            })
            .catch(function (error) {
                //console.log(error);
            });
        });
    }
    emailModal(){
        this.setState({emailshow: true}, () => {
            var _this = this;
            axios({
                method: 'post',
                url: Path.USER_URL + Path.URL.EMAIL_VERIFY,
                data: {
                    'auth_token':_this.state.auth_token,
                    'email':_this.state.email
                }
            })
            .then(function (response) {
                _this.setState({emailotpnumber: atob(response.data.data.result)});
            })
            .catch(function (error) {
                //console.log(error);
            });
        });
    }
    hideModal(id){
        if(id == 'reviewshow'){
            this.setState({reviewshow: false});
        }
        if(id == 'emailshow'){
            this.setState({emailshow: false});
        }
    }
    submitMobileOtp(){
        var _this = this;
        if(_this.state.otpnumber == _this.state.mobileOtp){
            axios({
                method: 'post',
                url: Path.USER_URL + Path.URL.OTP_VERIFY,
                data: {
                    'auth_token':_this.state.auth_token,
                    'mobile_number':_this.state.mobile_number,
                    'verify_status':'1'
                }
            })
            .then(function (response) {
                _this.hideModal('reviewshow');
                _this.setState({otp:'1',showError:'success'}, () => {
                    $("#success_msg").html('Your One time password ( OTP ) verified successfully');
                    _this.getUserData();
                    setTimeout(() => {
                        _this.setState({showError:''});
                    },1000);
                });                
            })
            .catch(function (error) {
                //console.log(error);
            });
        }
        else{
            axios({
                method: 'post',
                url: Path.USER_URL + Path.URL.OTP_VERIFY,
                data: {
                    'auth_token':_this.state.auth_token,
                    'mobile_number':_this.state.mobile_number,
                    'verify_status':'0'
                }
            })
            .then(function (response) {
                _this.hideModal('reviewshow');
                _this.setState({otp:'0',showError:'error'}, () => {
                    $("#error_msg").html('Your One time password ( OTP ) verification failed');     
                    setTimeout(() => {
                        _this.setState({showError:''});
                    },1000);          
                });
            })
            .catch(function (error) {
                //console.log(error);
            });
        }        
    }
    submitEmailOtp(){
        var _this = this;
        if(_this.state.emailotpnumber == _this.state.emailOtp){
            axios({
                method: 'post',
                url: Path.USER_URL + Path.URL.EMAIL_OTP_VERIFY,
                data: {
                    'auth_token':_this.state.auth_token,
                    'email':_this.state.email,
                    'verify_status':'1'
                }
            })
            .then(function (response) {
                _this.hideModal('emailshow');
                _this.setState({email_verify:'1',showError:'success'}, () => {
                    $("#success_msg").html('Your One time password ( OTP ) verified successfully');
                    _this.getUserData();
                    setTimeout(() => {
                        _this.setState({showError:''});
                    },1000);
                });                
            })
            .catch(function (error) {
                //console.log(error);
            });
        }
        else{
            axios({
                method: 'post',
                url: Path.USER_URL + Path.URL.EMAIL_OTP_VERIFY,
                data: {
                    'auth_token':_this.state.auth_token,
                    'email':_this.state.email,
                    'verify_status':'0'
                }
            })
            .then(function (response) {
                _this.hideModal('emailshow');
                _this.setState({email_verify:'0',showError:'error'}, () => {
                    $("#error_msg").html('Your One time password ( OTP ) verification failed');     
                    setTimeout(() => {
                        _this.setState({showError:''});
                    },1000);          
                });
            })
            .catch(function (error) {
                //console.log(error);
            });
        }   
    }
    getUserData(){
        var _this = this;
        axios({
            method: 'get',
            url: Path.USER_URL+Path.URL.GET_USER_DETAILS,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {
            if(response.data.success == "1"){
                _this.setState({
                    name:response.data.customer.name,
                    lastname:response.data.customer.lastname,
                    role:response.data.customer.role,
                    email:response.data.customer.email,
                    mobile_number:response.data.customer.mobile_number,
                    profile_image:response.data.customer.profile_image,
                    city:response.data.customer.city,
                    invoice_period:response.data.customer.invoice_period,
                    editcity: response.data.customer.city,
                    receive_notification:response.data.customer.receive_notification,
                    receive_promotion:response.data.customer.receive_promotion,
                    holiday:response.data.customer.holiday_mode,
                    bio_info:response.data.customer.bio_info,
                    postcode:response.data.customer.postcode,
                    address_line1:response.data.customer.address_line1,
                    address_line2:response.data.customer.address_line2,
                    stripeAccount:response.data.customer.stripe_account_id,
                    otp:response.data.customer.otp,
                    email_verify:response.data.customer.email_verify,
                    profile_status:response.data.customer.profile_status,
                    profile_info_status:response.data.customer.profile_info_status,
                    social_login:response.data.customer.social_login,

                    //bank details
                    bank_name:response.data.customer.bank_name,
                    account_holder_name:response.data.customer.account_holder_name,
                    sort_code:response.data.customer.sort_code,
                    account_number:response.data.customer.account_number

                }, () => {
                    if(_this.state.auth_token === undefined || _this.state.auth_token === '' || _this.state.profile_status == '0'){
                        $(".verifications_bar").remove();
                        $(".verifications_header_bar").removeClass("verifications_header_bar");
                    }
                })
            }
        })
        .catch(function (error) {
            //console.log(error);
        });
    }
    disconnectStripe(){
        var _this = this;
        axios({
            method: 'get',
            url: Path.USER_URL + Path.URL.DISCONNECT_STRIPE,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {
            _this.setState({stripeAccount:''}, () => {

            });
        })
        .catch(function (error) {
            //console.log(error);
        });
    } 
    componentDidMount() {
        this.getUserData();
        setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    loadData(){
        
    }


    //------------Bank details
    changeBankDetails = () => {
        var _this = this;
        axios({
            method: 'post',
            url: Path.USER_URL + Path.URL.UPDATE_BANK_DETAILS,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            },
            data: {
                "bank_name":_this.state.bank_name,
                "account_holder_name":_this.state.account_holder_name,
                "sort_code":_this.state.sort_code,
                "account_number":_this.state.account_number,
            }
        })
            .then(function (response) {
                //_this.getUserData();
                _this.setState({editBankDetails:false});
            })
            .catch(function (error) {
                //console.log(error);
            });
    }
    clickToEditBankDetails = () =>{
        this.setState({editBankDetails:true});
    }
    //-------------------------

    render(){
        const renderInput = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
            <div className="autocomplete-root">
                <input className="form-control" {...getInputProps({
                    placeholder:"Location",
                    className:"form-control"
                })} />
                <ul className="autocomplete-dropdown-container">
                    {suggestions.map(suggestion => (                
                        <li {...getSuggestionItemProps(suggestion)} className="suggestion">
                            {suggestion.terms.length > 2 ? 
                                <span className="cityname1">{suggestion.terms[0].value}<span className="cityname2">, {suggestion.terms[1].value}</span></span>
                            : 
                            <span className="cityname1">{suggestion.terms[0].value}</span>
                            }
                        </li>
                    ))}
                </ul>
            </div>
        );
        // Limit the suggestions to show only cities in the US
        const searchOptions = {
        //types: ['(cities)'],
        types: ['geocode'], //For Home Page
        componentRestrictions: {country: "uk"}
        }
        return(
            <div>         
                {this.state.showError === 'success' &&
                <div className="flashmessage show"><i className="fa fa-check"></i><span id="success_msg"></span></div>}
                {this.state.showError === 'error' &&
                <div className="flashmessage-error show-error"><i className="fa fa-close"></i><span id="error_msg"></span></div>
                }         
                <div className="main_wrapper">
                    <section className="dashboard_bg verfication_list">
                        <Sidebar/>
                        <div className="content-wrapper">
                            <div className="content-height-wrapper">
                                <div className="tab-content">                                    
                                    <div className="col-xs-12 text-left m-b-20">
                                        <div className="col-md-5 col-xs-12 no-padding-xs dashboard_left">
                                            {this.state.editProfile == false ? 
                                            <div className="col-xs-12 no-padding" id="view_profile">
                                                <div className="col-xs-12 dashboardHead">Profile</div>
                                                <div className="col-xs-12">
                                                    <div className="my_account_pro">
                                                    {this.state.profile_image !== undefined && this.state.profile_image !== null && this.state.profile_image !== '' ?
                                                    <img onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_IMAGE}} src={this.state.profile_image}/>
                                                    :
                                                    <img src={Path.IMAGE_PATH+"/profile.jpg"} height="250" width="320"/>
                                                    }
                                                    </div>
                                                </div>
                                                <div className="col-xs-12 my_account_right_info">
                                                    <div className="my_account_item">
                                                        <span className="pro_label">Name *:</span>
                                                        <span className="pro_text">{this.state.name} {this.state.lastname}</span>
                                                    </div>
                                                    <div className="my_account_item">
                                                        <span className="pro_label">Email *:</span>
                                                        <span className="pro_text">{this.state.email}</span>
                                                    </div>
                                                    <div className="my_account_item">
                                                        <span className="pro_label">Address Line 1 *:</span>
                                                        <span className="pro_text">{this.state.address_line1}</span>
                                                    </div>
                                                    <div className="my_account_item">
                                                        <span className="pro_label">Address Line 2:</span>
                                                        <span className="pro_text">{this.state.address_line2}</span>
                                                    </div>
                                                    <div className="my_account_item">
                                                        <span className="pro_label">City *:</span>
                                                        <span className="pro_text">{this.state.city}</span>
                                                    </div>
                                                    <div className="my_account_item">
                                                        <span className="pro_label">Postcode *:</span>
                                                        <span className="pro_text">{this.state.postcode}</span>
                                                    </div>                                                                
                                                    <div className="my_account_item">
                                                        <span className="pro_label">Country *:</span>
                                                        <span className="pro_text">United Kingdom</span>
                                                    </div>   
                                                    {/*<div className="my_account_item">
                                                        <span className="pro_label">Invoice Period *:</span>
                                                        <span className="pro_text">{this.state.invoice_period} days</span>
                                                    </div>*/}
                                                    <div className="my_account_item">
                                                        <span className="pro_label">Telephone *:</span>
                                                        <span className="pro_text">{this.state.mobile_number}</span>
                                                    </div>
                                                    <div className="my_account_item">
                                                        {this.state.profile_info_status == '0' &&        
                                                        <span className="connect_error"><a>Please complete your profile to start renting and selling.</a></span>
                                                        }
                                                        <a id="edit_my_profile" className="pro_text_btn" onClick={this.clicktoeditProfile}>Edit Profile</a>
                                                    </div>                                                                
                                                </div>
                                            </div>
                                            :
                                            <div className="col-xs-12 no-padding" id="view_profile">
                                                <div className="col-xs-12 dashboardHead">Edit Profile</div>
                                                <div className="col-xs-12"> 
                                                    <label className="my_account_pro">
                                                        <input type="radio" className="hide_label" defaultChecked="checked" value={this.state.profile_image} id="profile_image" name="profile_image"/>
                                                        {typeof(this.state.profile_image) != 'undefined'  && this.state.profile_image != null ?
                                                            <img onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_IMAGE}} className="pull-left" src={this.state.profile_image} id="profile_image_view" width="320" height="250"/> :
                                                            <img onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_IMAGE}} src={Path.IMAGE_PATH+"/profile.jpg"} className="pull-left" id="profile_image_view" width="320" height="250"/>
                                                        }
                                                        <span className="my_account_proEditIcon" id="profile_image_upload" onClick={this.profileImageUpload}><i className="fa fa-pencil"></i>Edit</span>
                                                    </label>
                                                </div>
                                                <div className="col-xs-12 my_account_right_info">
                                                    <div className="my_account_item">
                                                        <span className="pro_label">First Name *:</span>
                                                        <span className="pro_text">
                                                            <input type="text" placeholder="Name" className="form-control" name="editname" defaultValue={this.state.name} onChange={e => this.handleChange(e)}/>
                                                        </span>
                                                    </div>
                                                    <div className="my_account_item">
                                                        <span className="pro_label">Last Name *:</span>
                                                        <span className="pro_text">
                                                            <input type="text" placeholder="Name" className="form-control" name="editlastname" defaultValue={this.state.lastname} onChange={e => this.handleChange(e)}/>
                                                        </span>
                                                    </div>
                                                    <div className="my_account_item">
                                                        <span className="pro_label">Email *:</span>
                                                        <span className="pro_text">{this.state.email}</span>
                                                    </div>
                                                    <div className="my_account_item">
                                                        <span className="pro_label">Address Line 1 *:</span>
                                                        <span className="pro_text">
                                                            <input type="text" placeholder="Address Line 1" className="form-control" name="address_line1" defaultValue={this.state.address_line1} onChange={e => this.handleChange(e)}/>
                                                        </span>
                                                    </div>
                                                    <div className="my_account_item">
                                                        <span className="pro_label">Address Line 2:</span>
                                                        <span className="pro_text">
                                                            <input type="text" placeholder="Address Line 2" className="form-control" name="address_line2" defaultValue={this.state.address_line2} onChange={e => this.handleChange(e)}/>
                                                        </span>
                                                    </div>
                                                    <div className="my_account_item">
                                                        <span className="pro_label">City *:</span>
                                                        <span className="pro_text">
                                                            {this.state.editcity !== null || this.state.editcity !== undefined ?
                                                            <PlacesAutocomplete
                                                                value={this.state.editcity}
                                                                onChange={this.handleLocationChange}
                                                                onSelect={this.handleSelect}
                                                                // Pass the search options prop
                                                                searchOptions={searchOptions}
                                                                name="editcity"
                                                            >
                                                                {renderInput}
                                                            </PlacesAutocomplete>
                                                            :
                                                            <PlacesAutocomplete
                                                                value=""
                                                                onChange={this.handleLocationChange}
                                                                onSelect={this.handleSelect}
                                                                // Pass the search options prop
                                                                searchOptions={searchOptions}
                                                                name="editcity"
                                                            >
                                                                {renderInput}
                                                            </PlacesAutocomplete>
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className="my_account_item">
                                                        <span className="pro_label">Postcode *:</span>
                                                        <span className="pro_text">
                                                            <input type="text" placeholder="Postcode" className="form-control" name="postcode" defaultValue={this.state.postcode} onChange={e => this.handlePostCodeChange(e)}/>
                                                        </span>
                                                    </div>
                                                    <div className="my_account_item">
                                                        <span className="pro_label">Country:</span>
                                                        <span className="pro_text">United Kingdom</span>
                                                    </div>
                                                    {/*<div className="my_account_item">
                                                        <span className="pro_label">Invoice Period *:</span>
                                                        <span className="pro_text">
                                                            <select className="form-control" name="editinvoice_period" onChange={e => this.handleChange(e)} defaultValue={this.state.invoice_period}>
                                                                <option value="15">15 days</option>
                                                                <option value="30">30 days</option>
                                                            </select>
                                                        </span>
                                                    </div>*/}
                                                    <div className="my_account_item">
                                                        <span className="pro_label">Telephone *:</span>
                                                        <span className="pro_text">
                                                            <input type="text" placeholder="Mobile Number" name="editmobile_number" className="form-control" defaultValue={this.state.mobile_number} onChange={e => this.handleChange(e)}/>
                                                        </span>
                                                    </div>
                                                    <div className="my_account_item">
                                                        <a id="edit_my_profile" className="pro_text_btn" onClick={this.editProfile}>Save</a>
                                                    </div>
                                                </div>
                                            </div>   
                                            }
                                        </div>
                                        <div className="col-md-7 col-xs-12 dashboard_right">
                                            <div className="col-xs-12 dashboardHead">Verifications</div>
                                            {this.state.otp == '0' ? 
                                            <div className="col-xs-12 text-left m-t-40">
                                                <div className="watchlist_items">
                                                    <div className="col-sm-7 col-xs-12 watchlist_itemsCont">
                                                        <div className="pro_name">Mobile</div>
                                                        <div className="pro_amount">{this.state.mobile_number}</div>
                                                        <div className="pro_kg">Not Verified</div>
                                                    </div>
                                                    <div className="col-sm-5 col-xs-12 watchlist_itemsBtn m-t-30">
                                                        <a className="watchlist_items_view" onClick={e => this.mobileModal()}>Click to verify</a>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="col-xs-12 text-left m-t-40">
                                                <div className="watchlist_items">
                                                    <div className="col-sm-7 col-xs-12 watchlist_itemsCont">
                                                        <div className="pro_name">Mobile</div>
                                                        <div className="pro_amount">{this.state.mobile_number}</div>
                                                        <div className="pro_kg">Verified</div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            {this.state.email_verify == '0' ? 
                                            <div className="col-xs-12 text-left m-t-40">
                                                <div className="watchlist_items">
                                                    <div className="col-sm-7 col-xs-12 watchlist_itemsCont">
                                                        <div className="pro_name">Email ID</div>
                                                        <div className="pro_amount">{this.state.email}</div>
                                                        <div className="pro_kg">Not Verified</div>
                                                    </div>
                                                    <div className="col-sm-5 col-xs-12 watchlist_itemsBtn m-t-30">
                                                        <a className="watchlist_items_view" onClick={e => this.emailModal()}>Click to verify</a>
                                                    </div>
                                                </div>
                                            </div>
                                            :    
                                            <div className="col-xs-12 text-left m-t-40">
                                                <div className="watchlist_items">
                                                    <div className="col-sm-7 col-xs-12 watchlist_itemsCont">
                                                        <div className="pro_name">Email ID</div>
                                                        <div className="pro_amount">{this.state.email}</div>
                                                        <div className="pro_kg">Verified</div>
                                                    </div>
                                                </div>
                                            </div> 
                                            }


                                            <div className="col-xs-12 text-left m-t-40">
                                                {this.state.editBankDetails == false ?
                                                    <div className="col-xs-12 no-padding" id="view_profile">

                                                        <div className="watchlist_items">
                                                            <div className="col-xs-12 watchlist_itemsCont">
                                                                <div className="pro_name">Payout Details</div>
                                                                <div className="col-xs-12 my_account_right_info" style={{
                                                                    paddingLeft:0
                                                                }}>
                                                                    <div className="my_account_item">
                                                                        <span className="pro_label">Name of Bank*:</span>
                                                                        <span className="pro_text">{this.state.bank_name}</span>
                                                                    </div>
                                                                    <div className="my_account_item">
                                                                        <span className="pro_label">Account Holder's Name*:</span>
                                                                        <span className="pro_text">{this.state.account_holder_name}</span>
                                                                    </div>
                                                                    <div className="my_account_item">
                                                                        <span className="pro_label">Sort Code*:</span>
                                                                        <span className="pro_text">{(this.state.sort_code == 0) ? '' : this.state.sort_code}</span>
                                                                    </div>
                                                                    <div className="my_account_item">
                                                                        <span className="pro_label">Account Number*:</span>
                                                                        <span className="pro_text">{(this.state.account_number == 0) ? '' : this.state.account_number}</span>
                                                                    </div>

                                                                    <div className="my_account_item">
                                                                        {this.state.profile_info_status == '0' &&
                                                                        <span className="connect_error"><a>Please complete your profile to start renting and selling.</a></span>
                                                                        }
                                                                        <a id="edit_bank_details" className="pro_text_btn" onClick={this.clickToEditBankDetails}>Edit Bank Details</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    :
                                                    <div className="col-xs-12 my_account_right_info no-padding">
                                                        <div className="watchlist_items">
                                                            <div className="col-xs-12 watchlist_itemsCont">
                                                                <div className="pro_name">Payout Details</div>
                                                                <div className="col-xs-12 my_account_right_info" style={{
                                                                    paddingLeft:0
                                                                }}>
                                                                    <div className="my_account_item">
                                                                        <span className="pro_label">Name of Bank*:</span>
                                                                        <span className="pro_text">
                                                                                <input type="text" placeholder="Enter Name of Bank" className="form-control"
                                                                                       name="bank_name" value={this.state.bank_name} onChange={e => this.handleChange(e)}
                                                                                />
                                                                        </span>
                                                                        <span id="bank_name" className="error_message"></span>
                                                                    </div>
                                                                    <div className="my_account_item">
                                                                        <span className="pro_label">Account Holder Name*:</span>
                                                                        <span className="pro_text">
                                                                                <input type="text" placeholder="Enter account Holder's Name" className="form-control"
                                                                                       name="account_holder_name" value={this.state.account_holder_name} onChange={e => this.handleChange(e)}
                                                                                />
                                                                        </span>
                                                                        <span id="account_holder_name" className="error_message"></span>
                                                                    </div>
                                                                    <div className="my_account_item">
                                                                        <span className="pro_label">Sort Code*:</span>
                                                                        <span className="pro_text">
                                                                            <input type="number" placeholder="Enter Sort Code"
                                                                                   className="form-control"
                                                                                   min="1"
                                                                                   max="99999"
                                                                                   name="sort_code" value={this.state.sort_code} onChange={e => this.handleChange(e)}
                                                                            />
                                                                        </span>
                                                                        <span id="sort_code" className="error_message"></span>
                                                                    </div>
                                                                    <div className="my_account_item">
                                                                        <span className="pro_label">Account Number :</span>
                                                                        <span className="pro_text">
                                                                            <input type="number" placeholder="Enter Account Number"
                                                                                   className="form-control"
                                                                                   min="1"
                                                                                   max="9999999"
                                                                                   name="account_number" value={this.state.account_number} onChange={e => this.handleChange(e)}
                                                                            />
                                                                        </span>
                                                                        <span id="account_number" className="error_message"></span>
                                                                    </div>
                                                                    <div className="my_account_item">
                                                            <a id="edit_bank_details" className="pro_text_btn" onClick={e => this.changeBankDetails(e)}>Submit</a>
                                                        </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>


                                            {/*{this.state.stripeAccount == '' || this.state.stripeAccount === undefined ?
                                            <div className="col-xs-12 text-left m-t-40 m-b-40">
                                                <div className="stripe_bg_wrapper">
                                                    <div className="stripe_bg_heading">Stripe Connect</div>
                                                    <div className="stripe_bg_sub_heading">Pay-out Account</div>
                                                    <div className="stripe_bg_content">Click below to create your Stripe Account to recieve payment for items you list on Weborro</div>
                                                    <div className="clearfix"></div>
                                                    <div className="stripe_bg_text">Not Verified</div>
                                                    <a href={Path.CONNECT_STRIPE+this.props.stripe_client_id+'&scope=read_write'} className="stripe_bg_button">Connect Stripe</a>
                                                </div>
                                            </div>
                                            :
                                            <div className="col-xs-12 text-left m-t-40 m-b-40">
                                                <div className="stripe_bg_wrapper">
                                                    <div className="stripe_bg_heading">Stripe Connect</div>
                                                    <div className="stripe_bg_sub_heading">Pay-out Account</div>
                                                    <div className="clearfix"></div>
                                                    <div className="stripe_bg_text">Verified</div>
                                                    <a onClick={e => this.disconnectStripe(e)} className="stripe_errorbg_button">Disconnect Stripe</a>
                                                </div>
                                            </div>
                                            }*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className={this.state.reviewshow == true ? 'weborro_modal display-block' : 'weborro_modal display-none'}>
                    <section className='weborro_modal_wrapper loginDesi'>
                        <button type="button" className="close" onClick={e => this.hideModal('reviewshow')}>&times;</button>
                        <form>                            
                            <div className="log_H">Verify OTP</div>
                            <div id="login_message"></div>
                            <div className="clearfix"></div>
                            <div className="form-group clearfix">
                                <label className="control-label">Check One time password ( OTP ) in your Mobile</label>
                                <input type="text" className="form-control" placeholder="Enter your OTP" onChange={e => this.handleChange(e)} value={this.state.mobileOtp} id="mobileOtp" name="mobileOtp" ></input>
                            </div>
                            <div className="form-group clearfix">
                                <span className="log_Bt text-center pointer" onClick={e => this.submitMobileOtp(e)}>Submit</span>
                            </div>
                        </form>
                    </section>
                </div>
                <div className={this.state.emailshow == true ? 'weborro_modal display-block' : 'weborro_modal display-none'}>
                    <section className='weborro_modal_wrapper loginDesi'>
                        <button type="button" className="close" onClick={e => this.hideModal('emailshow')}>&times;</button>
                        <form>                            
                            <div className="log_H">Verfiy Email</div>
                            <div id="login_message"></div>
                            <div className="clearfix"></div>
                            <div className="form-group clearfix">
                                <label className="control-label">Check One time password ( OTP ) in your Email</label>
                                <input type="text" className="form-control" placeholder="Enter your OTP" onChange={e => this.handleChange(e)} value={this.state.emailOtp} id="emailOtp" name="emailOtp" ></input>
                            </div>
                            <div className="form-group clearfix">
                                <span className="log_Bt text-center pointer" onClick={e => this.submitEmailOtp(e)}>Submit</span>
                            </div>
                        </form>
                    </section>
                </div>
            </div> 
        );
    }
}
export default GoogleApiWrapper({
    apiKey: (Path.API_LIST.GOOGLE_API_KEY)
})(Verifications);