import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import {reactLocalStorage} from 'reactjs-localstorage';
import { setPusherClient } from 'react-pusher';
import Pusher from 'pusher-js';
import Responsive from "react-responsive";
import Path from './Path';
import Sidebar from './Sidebar';
export const Mobile = props => <Responsive {...props} maxWidth={767} />;
export const Default = props => <Responsive {...props} minWidth={768} />;
class MessagesWrapper extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
            auth_token : reactLocalStorage.get('auth_token'),
            chat_message_search_input:'',
            chatUsers:[],
            chatHistory:[],
            enableSendMessage:false,
            chatWrapper:'',
            chat_user_id : reactLocalStorage.get('user_id'),
            msgsubmitbtn:false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.loadData = this.loadData.bind(this);
        this.createMessage = this.createMessage.bind(this);
        this.getChatProducts = this.getChatProducts.bind(this);
        this.getChatProductDetails = this.getChatProductDetails.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.datenth = this.datenth.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.filterResult = this.filterResult.bind(this);
        this.gotomessageList = this.gotomessageList.bind(this);
    }
    gotomessageList(e){
        this.setState({productInfo:false});
    }
    handleChange(e) {
        if(e.target.name == 'message'){
            if(e.target.value.length >= 1){
                this.setState({msgsubmitbtn:true});
            }
            else{
                this.setState({msgsubmitbtn:false});
            }
        }
        this.setState({
           [e.target.name]: e.target.value
        });

    }
    handleMessageChange(e){
        if(e.key === 'Enter'){
            e.preventDefault();
            return false;
        }
    }
    createMessage(){
        var _this = this;
        axios({
            method: 'post',
            url: Path.USER_URL + Path.URL.CREATE_MESSAGE,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            },
            data: {
                "conversation_id":_this.state.conversation_id,
                "message":_this.state.message
            }
        })
        .then(function (response) {
            _this.scrollToBottom();
            _this.setState({message:''});
        })
        .catch(function (error) {
            //console.log(error);
        });
    }
    getChatProducts(){
        var _this = this;
        let id = this.props.match.params.conversationId;
        axios({
            method: 'get',
            url: Path.USER_URL+Path.URL.GET_CUSTOMER_CHAT_PRODUCTS,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {
            _this.setState({chatUsers:response.data.product}, () => {
                _this.setState({lending_id:$('#'+id).attr('data-lendid')},() => {
                    axios({
                        method: 'get',
                        url: Path.USER_URL+Path.URL.GET_CUSTOMER_CHAT_PRODUCT_DETAILS+'/'+_this.state.lending_id,
                        headers: {
                            'Api-Token':'Bearer '+_this.state.auth_token
                        }
                    })
                    .then(function (response) {
                        _this.setState({
                            product_name:response.data.product.product_name,
                            user_name:response.data.product.user.name,
                            product_image:response.data.product.productImage 
                        }, () => {
                            _this.setState({productInfo:true});
                        })
                    })
                    .catch(function (error) {
                        //console.log(error);
                    });
                });
            });            
        })
        .catch(function (error) {
            //console.log(error);
        });
    }
    getChatProductDetails(id,lending_id){
        $(".chat_msg_push").remove();
        var _this = this;
        _this.props.history.push(Path.PAGE_NAME.MessagesWrapper+'/'+id);
        _this.setState({chatWrapper:id})
        $(".chat_message_list_items_container_list").removeClass("active");
        $("[data-active='"+id+"']").addClass("active");
        _this.setState({conversation_id:id,enableSendMessage:true});
        axios({
            method: 'get',
            url: Path.USER_URL+Path.URL.GET_CUSTOMER_CHAT_PRODUCT_DETAILS+'/'+lending_id,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {
            _this.setState({
                product_name:response.data.product.product_name,
                user_name:response.data.product.user.name,
                product_image:response.data.product.productImage
            }, () => {
                _this.setState({productInfo:true});
            })
        })
        .catch(function (error) {
            //console.log(error);
        }); 
        _this.setState({chatHistory:[]}, () => {
            axios({
                method: 'get',
                url: Path.USER_URL+Path.URL.GET_CHAT_HISTORY+'/'+id,
                headers: {
                    'Api-Token':'Bearer '+_this.state.auth_token
                }
            })
            .then(function (response) {
                _this.setState({chatHistory:response.data.history,chatRightUser:response.data.user,chatRightBorrower:response.data.borrower},() => {
                    _this.scrollToBottom();
                })
            })
            .catch(function (error) {
                //console.log(error);
            });
        });
    }
    handleKeyPress(e){
        if (e.key === 'Enter') {
            this.createMessage();
        }
    }
    datenth(d) {
		if(d>3 && d<21) return 'th';
		switch (d % 10) {
				case 1:  return "st";
				case 2:  return "nd";
				case 3:  return "rd";
				default: return "th";
			}
	} 
	formatDate(date){
		var fortnightAway = new Date(date), dateNew = fortnightAway.getDate(),
		month = "Jan,Feb,Mar,Apr,May,Jun,July,Aug,Sep,Oct,Nov,Dec".split(",")[fortnightAway.getMonth()];
		return dateNew+this.datenth(dateNew)+" "+month;
    }
    scrollToBottom(){
        var scrollElement = document.getElementById('chat_message_wrapper_right_conversations_scroll');
        scrollElement.scrollTop = scrollElement.scrollHeight;
    }
    updateDimensions(){
        $(".chat_message_wrapper").css("height",($(window).height() - ($("header").outerHeight()+60)));
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions();
        this.loadData();
        this.getChatProducts();
        const pusher = new Pusher(Path.PUSHER_API_KEY, {
            cluster: 'ap2',
            encrypted: true
        });
        const channel = pusher.subscribe('my-channel');
            channel.bind('my-event', data => {
                //console.log(data)                
                //var messageUserType = this.state.chat_user_id == data.lender_id ? "chat_message_conversation_bg chat_message_right" : "chat_message_conversation_bg chat_message_left";
                var messageUserType = this.state.chat_user_id == data.lender_id && data.userType == 'seller' || this.state.chat_user_id == data.borrower_id && data.userType == 'buyer' ? "chat_message_conversation_bg chat_message_right" : "chat_message_conversation_bg chat_message_left";
                this.setState({newMessage:data.message,messageType:messageUserType,messageTime:new Date(data.created.date),chatconversationid:data.conversation_id}, () => {
                    //$('#chat_message_wrapper_right_conversations_scroll[data-chatid="'+this.state.chatconversationid+'"]').append('<div class="chat_message_conversation_wrap"><div class="'+this.state.messageType+'"><span class="chat_message_text">'+this.state.newMessage+'</span><span class="chat_message_time">'+this.state.messageTime.getHours() +':'+ (this.state.messageTime.getMinutes()<10 ? '0':'') + this.state.messageTime.getMinutes()+'<span class="chat_message_status chat_read"></span></span></div></div>');
                    this.state.chat_user_id == data.lender_id && data.userType == 'seller' &&
                    $('#chat_message_wrapper_right_conversations_scroll[data-chatid="'+this.state.chatconversationid+'"]').append('<div class="chat_msg_push chat_message_right_view chat_message_conversation_wrap"><div class="chat_message_profile"><span class="chat_message_profile_img"><img src="'+data.lender.profile_image+'" onError="this.src='+`'${Path.NO_PROFILE_IMAGE}'`+'"></span></div><div class="chat_message_conversation_bg chat_message_right"><span class="chat_message_text">'+this.state.newMessage+'</span><span class="chat_message_time"><span class="pull-left">'+data.lender.name+' '+data.lender.lastname+' - '+this.state.messageTime.getHours() +':'+ (this.state.messageTime.getMinutes()<10 ? '0':'') + this.state.messageTime.getMinutes()+'</span> <span class="chat_message_status chat_unread"></span></span></div></div>')
                    this.state.chat_user_id == data.lender_id && data.userType == 'buyer' &&
                    $('#chat_message_wrapper_right_conversations_scroll[data-chatid="'+this.state.chatconversationid+'"]').append('<div class="chat_msg_push chat_message_left_view chat_message_conversation_wrap"><div class="chat_message_profile"><span class="chat_message_profile_img"><img src="'+data.borrower.profile_image+'" onError="this.src='+`'${Path.NO_PROFILE_IMAGE}'`+'"></span></div><div class="chat_message_conversation_bg chat_message_left"><span class="chat_message_text">'+this.state.newMessage+'</span><span class="chat_message_time"><span class="pull-left">'+data.borrower.name+' '+data.borrower.lastname+' - '+this.state.messageTime.getHours() +':'+ (this.state.messageTime.getMinutes()<10 ? '0':'') + this.state.messageTime.getMinutes()+'</span> <span class="chat_message_status chat_unread"></span></span></div></div>')                    
                    this.state.chat_user_id == data.borrower_id && data.userType == 'buyer' &&
                    $('#chat_message_wrapper_right_conversations_scroll[data-chatid="'+this.state.chatconversationid+'"]').append('<div class="chat_msg_push chat_message_right_view chat_message_conversation_wrap"><div class="chat_message_profile"><span class="chat_message_profile_img"><img src="'+data.borrower.profile_image+'" onError="this.src='+`'${Path.NO_PROFILE_IMAGE}'`+'"></span></div><div class="chat_message_conversation_bg chat_message_right"><span class="chat_message_text">'+this.state.newMessage+'</span><span class="chat_message_time">'+data.borrower.name+' '+data.borrower.lastname+' - '+this.state.messageTime.getHours() +':'+ (this.state.messageTime.getMinutes()<10 ? '0':'') + this.state.messageTime.getMinutes()+'</span></div></div>')                    
                    this.state.chat_user_id == data.borrower_id && data.userType == 'seller' &&
                    $('#chat_message_wrapper_right_conversations_scroll[data-chatid="'+this.state.chatconversationid+'"]').append('<div class="chat_msg_push chat_message_left_view chat_message_conversation_wrap"><div class="chat_message_profile"><span class="chat_message_profile_img"><img src="'+data.lender.profile_image+'" onError="this.src='+`'${Path.NO_PROFILE_IMAGE}'`+'"></span></div><div class="chat_message_conversation_bg chat_message_left"><span class="chat_message_text">'+this.state.newMessage+'</span><span class="chat_message_time">'+data.lender.name+' '+data.lender.lastname+' - '+this.state.messageTime.getHours() +':'+ (this.state.messageTime.getMinutes()<10 ? '0':'') + this.state.messageTime.getMinutes()+'</span></div></div>')
                });
        });
        setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    dateExists(createdDate) {
        return this.state.chatHistory.some(function(el) {
            return el.createdDate === createdDate;
        }); 
    }
    filterResult(e){ 
        this.setState({
            [e.target.name]: e.target.value
        });
        var searchText = e.target.value;
        var that = e.target, $allListElements = $('.chat_message_list_items_container > li');
        if(searchText.length > 0){            
            var $matchingListElements = $allListElements.filter(function(i, li){
                var listItemText = $(li).find('.chat_message_user_name').attr('data-product').toUpperCase(), 
                    searchText = that.value.toUpperCase();
                return ~listItemText.indexOf(searchText);
            });            
            $allListElements.hide();
            $matchingListElements.show();
        }
        else{
            $allListElements.show();
        }       
    }
    loadData(){
        let id = this.props.match.params.conversationId;
        var _this = this;
        _this.setState({chatWrapper:_this.props.match.params.conversationId})
        axios({
            method: 'get',
            url: Path.USER_URL+Path.URL.GET_USER_DETAILS,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {
            if(response.data.success == "1"){
                _this.setState({
                    user_id:response.data.customer.id
                });
            }
        }).catch(function (error) {
            //console.log(error);
        });
        $(".chat_message_list_items_container_list").removeClass("active");
        _this.setState({conversation_id:id,enableSendMessage:true});
        axios({
            method: 'get',
            url: Path.USER_URL+Path.URL.GET_CHAT_HISTORY+'/'+id,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {
            _this.setState({chatHistory:response.data.history,chatRightUser:response.data.user,chatRightBorrower:response.data.borrower},() => {
                _this.scrollToBottom();
            })
        })
        .catch(function (error) {
            //console.log(error);
        });
    }
    render(){
        let chatId = this.props.match.params.conversationId;
        const renderChatUersList = this.state.chatUsers.map((item, key) => {
            return(
                <li id={item.conversation_id} data-lendid={item.product.lending_id} className={chatId == item.conversation_id ? "chat_message_list_items_container_list active" : "chat_message_list_items_container_list"} data-active={item.conversation_id} key={key} onClick={() => this.getChatProductDetails(item.conversation_id,item.product.lending_id)} >                    
                    {this.state.user_id === item.lender.id ? 
                    <div className="chat_message_list_items_container_list_inner">
                        <div className="chat_message_user_image"><img src={item.borrower.profile_image} onError={(e)=>{e.target.onerror = null; e.target.src=Path.IMAGE_PATH+"/profile.jpg"}}/></div>
                        <div className="chat_message_list_items_container_info">
                            <div className="chat_message_user_name text-capitalize" data-product={item.borrower.name +''+ item.product.product_name}>{item.borrower.name} {item.borrower.lastname}</div>
                            <div className="chat_message_date">{this.formatDate(item.created)}</div>
                            <div className="chat_message_product_name"><span className="chat_subject_head">Re: </span>{item.product.product_name}</div>
                            <div className="chat_message_last_chat_desc">{item.message}</div>
                            <div className={item.message_status == '0' ? "chat_message_last_chat_status chat_read chat_unread" : "chat_message_last_chat_status chat_read chat_read"}></div>
                        </div>
                    </div>
                    :
                    <div className="chat_message_list_items_container_list_inner">
                        <div className="chat_message_user_image"><img src={item.lender.profile_image} onError={(e)=>{e.target.onerror = null; e.target.src=Path.IMAGE_PATH+"/profile.jpg"}}/></div>
                        <div className="chat_message_list_items_container_info">
                            <div className="chat_message_user_name text-capitalize" data-product={item.lender.name +''+ item.product.product_name}>{item.lender.name} {item.lender.lastname}</div>
                            <div className="chat_message_date">{this.formatDate(item.created)}</div>
                            <div className="chat_message_product_name"><span className="chat_subject_head">Re: </span>{item.product.product_name}</div>
                            <div className="chat_message_last_chat_desc">{item.message}</div>
                            <div className={item.message_status == '0' ? "chat_message_last_chat_status chat_read chat_unread" : "chat_message_last_chat_status chat_read chat_read"}></div>
                        </div>
                    </div>
                    }
                </li>
            )
        },this);        
        const renderChatHistory = this.state.chatHistory.map((item, key) => {
            return(
                <div key={key}>
                    {this.state.chatHistory[key-1] !== undefined ?
                        this.state.chatHistory[key-1].createdDate != item.createdDate &&
                        <div className="chat_message_day_of_conversation">
                            <span className="chat_message_day">
                                {this.formatDate(item.createdDate)}
                            </span>
                        </div>
                        :
                        <div className="chat_message_day_of_conversation">
                            <span className="chat_message_day">
                                {this.formatDate(item.createdDate)}
                            </span>
                        </div>
                    }
                    <div className={this.state.chat_user_id == item.lender_id && item.user_type == 'seller' || this.state.chat_user_id == item.borrower_id && item.user_type == 'buyer' ? "chat_message_right_view chat_message_conversation_wrap" : "chat_message_left_view chat_message_conversation_wrap"}>
                        <div className="chat_message_profile">
                            <span className="chat_message_profile_img"><img src={this.state.chat_user_id == item.lender_id && item.user_type == 'buyer' || this.state.chat_user_id == item.borrower_id && item.user_type == 'buyer' ? item.borrower.profile_image : item.lender.profile_image} onError={(e)=>{e.target.onerror = null; e.target.src=Path.IMAGE_PATH+"/profile.jpg"}}/></span>                            
                        </div>
                        <div className={this.state.chat_user_id == item.lender_id && item.user_type == 'seller' || this.state.chat_user_id == item.borrower_id && item.user_type == 'buyer' ? "chat_message_conversation_bg chat_message_right" : "chat_message_conversation_bg chat_message_left"}>
                            <span className="chat_message_text">{item.message}</span>
                            {this.state.chat_user_id == item.lender_id && item.user_type == 'buyer' || this.state.chat_user_id == item.borrower_id && item.user_type == 'buyer' ?
                            <span className="chat_message_time"><span className="pull-left">{item.borrower.name+' '+item.borrower.lastname} - {new Date(item.created).getHours() +':'+ (new Date(item.created).getMinutes()<10 ? '0':'') + new Date(item.created).getMinutes()}</span> {item.message_status == '0' ? <span className="chat_message_status chat_unread"></span> : <span className="chat_message_status chat_read"></span>}</span>
                            : 
                            <span className="chat_message_time">{item.lender.name+' '+item.lender.lastname} - {new Date(item.created).getHours() +':'+ (new Date(item.created).getMinutes()<10 ? '0':'') + new Date(item.created).getMinutes()}</span>
                            }
                        </div>                        
                    </div>
                </div>
                )
        },this)
        return(
            <div>
                <div className="main_wrapper">
                    <section className="dashboard_bg">
                        <Sidebar/>
                        <div className="content-wrapper">
                            <div className="conversation-chat-wrapper">
                                <div className={this.state.productInfo === true ? "chat_message_wrapper showMessage" : "chat_message_wrapper"}>
                                    <div className="chat_message_wrapper_left">
                                        <div className="chat_message_wrapper_left_searchitems">
                                            <span className="chat_message_search_icon"></span>
                                            <input type="text" className="chat_message_search_input" placeholder="Search user or item" name="chat_message_search_input" value={this.state.chat_message_search_input} onChange={e => this.filterResult(e)}/>
                                        </div>
                                        <div className="chat_message_wrapper_left_itemslist">
                                            <ul className="chat_message_list_items_container">
                                                {renderChatUersList}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="chat_message_wrapper_right">
                                        <ul className="chat_message_list_items_right_container">
                                            {this.state.productInfo === true ?
                                                <li className="chat_message_list_items_container_list"> 
                                                    <div className="chat_message_user_image chat_message_user_image_backicon" onClick={e => this.gotomessageList(e)}><img className="backicon" src={Path.IMAGE_PATH+"/back.svg"} width="50"/></div> 
                                                    <div className="chat_message_user_image"><img src={this.state.product_image} onError={(e)=>{e.target.onerror = null; e.target.src=Path.IMAGE_PATH+"/profile.jpg"}}/></div>
                                                    <div className="chat_message_list_items_container_info chat_message_list_items_container_info_backmsg">
                                                        {this.state.user_id === this.state.chatRightUser.id ?
                                                            <div className="chat_message_user_name text-capitalize">{this.state.chatRightBorrower.name} {this.state.chatRightBorrower.lastname}</div>
                                                            :
                                                            <div className="chat_message_user_name text-capitalize">{this.state.chatRightUser.name} {this.state.chatRightUser.lastname}</div>
                                                        }
                                                        <div className="chat_message_product_name"><span className="chat_subject_head">Re:</span> {this.state.product_name}</div>
                                                    </div>
                                                </li>
                                                :
                                                <li className="chat_message_list_items_container_list">&nbsp;</li>
                                            }
                                        </ul>
                                        <div id="chat_message_wrapper_right_conversations_scroll" data-chatid={this.state.chatWrapper} className="chat_message_wrapper_right_conversations">
                                            {renderChatHistory}
                                        </div>
                                        <div onKeyPress={e => this.handleKeyPress(e)} className={this.state.enableSendMessage === true ? "chat_message_submit_text_wrapper" : "chat_disable_send_message"}>
                                            <textarea className="chat_message_submit_text" name="message" onChange={e => this.handleChange(e)} onKeyPress={e => this.handleMessageChange(e)} value={this.state.message} id="message"></textarea>
                                            <span onClick={e => this.createMessage()} className={this.state.msgsubmitbtn === true ? "chat_message_submit_button active" : "chat_message_submit_button"}><img src={Path.IMAGE_PATH+"/send-chat.png"}/></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div> 
        );
    }
}
export default MessagesWrapper;