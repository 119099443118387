import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import {withRouter} from 'react-router-dom';
import {reactLocalStorage} from 'reactjs-localstorage';
import Path from './views/Path';
class Footer extends Component {
    constructor(props,context) {
        super(props,context);        
        this.state = {
            show: false,
            loginmodal: true,
            registermodal: false
        }
        this.loginModal = this.loginModal.bind(this);
        this.registerModal = this.registerModal.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
    }
    scrollToTop(){
        $('body,html').animate({
	        scrollTop : 0
	    }, 500);
    }
    loginModal(){
        $(".weborro_modal").removeClass('display-none').addClass("display-block");
        this.setState({loginmodal:true,registermodal:false,forgotPassModal:false,show: true,menuOpen:false,active: !this.state.active});
    }
    registerModal(){
        $(".weborro_modal").removeClass('display-none').addClass("display-block");
        this.setState({registermodal:true,loginmodal:false,forgotPassModal:false,show: true,menuOpen:false,active: !this.state.active});
    }
    componentDidMount(){
        this.setState({page:window.location.pathname.split("/").pop(),pageInner:window.location.pathname.split("/")[2],pageSubInner:window.location.pathname.split("/")[1],innerPage:window.location.pathname.split("/")[3]});
    }
    render(){
        return (
            <div>
                {this.props.location.pathname.indexOf('myaccount') !== -1 || this.props.location.pathname === Path.PAGE_NAME.Search ? '' :
                <footer>
                    <div className="container">
                        <div className="ho_foot_t">
                            <div className="col-md-2 col-sm-2 col-xs-12 ho_foot_tS">
                                <a  className="foot_logo" href={Path.BASE_URL}>
                                    <img alt={this.props.site_name} title={this.props.site_name} src={this.props.site_logo}/>
                                </a>
                            </div>
                            <div className="col-md-2 col-sm-2 col-xs-12 ho_foot_tS">
                                <h3>About</h3>
                                <ul>
                                    <li><a href={Path.BASE_URL+Path.PAGE_NAME.About}>About us</a></li>
                                    <li><a href={Path.BASE_URL+Path.PAGE_NAME.Howitworks}>How it works</a></li>
                                    <li><a href={Path.BASE_URL+Path.PAGE_NAME.Faq}>FAQ</a></li>
                                    <li><a href={Path.BASE_URL+Path.PAGE_NAME.Terms}>Terms of service</a></li>
                                    <li><a href={Path.BASE_URL+Path.PAGE_NAME.Sitemap}>Sitemap</a></li>
                                </ul>
                            </div>
                            <div className="col-md-2 col-sm-2 col-xs-12 ho_foot_tS">
                                <h3>Account</h3>
                                <ul>
                                    <li><a onClick={e => this.loginModal(e)}>Log in</a></li>
                                    <li><a onClick={e => this.registerModal(e)}>Sign up</a></li>
                                </ul>
                            </div>
                            <div className="col-md-2 col-sm-2 col-xs-12 ho_foot_tS">
                                <h3>Other bits</h3>
                                <ul>
                                    <li><a href={Path.BASE_URL+Path.PAGE_NAME.Careers}>Careers</a></li>
                                    <li><a target="_blank" href={Path.BASE_URL+Path.PAGE_NAME.Blog}>Blog</a></li>
                                    <li><a href={Path.BASE_URL+Path.PAGE_NAME.PrivacyPolicy}>Privacy policy</a></li>
                                    <li><a href={Path.BASE_URL+Path.PAGE_NAME.Contactus}>Contact us</a></li>
                                </ul>
                            </div>
                            <div className="col-md-2 col-sm-2 col-xs-12 ho_foot_tS">
                                <h3>Get the app</h3>
                                <a className="coming_soon_app">
                                    <img src={Path.IMAGE_PATH+"/google.png"}/>
                                </a>
                                <a  className="coming_soon_app m-t-10">
                                    <img src={Path.IMAGE_PATH+"/apple.png"}/>
                                </a>
                            </div>
                            <div className="col-md-2 col-sm-2 col-xs-12 ho_foot_tS">
                                <h3>Follow us</h3>
                                <a href={this.props.facebook_url} target="_blank" className="m-r-10 m-b-10">
                                    <img src={Path.IMAGE_PATH+"/facebook.png"}/>
                                </a>
                                <a href={this.props.twitter_url} className="m-r-10 m-b-10">
                                    <img src={Path.IMAGE_PATH+"/twitter.png"}/>
                                </a>
                                <a href={this.props.instagram_url} className="m-r-10 m-b-10">
                                    <img src={Path.IMAGE_PATH+"/instagram.png"}/>
                                </a>
                            </div>
                        </div>
                        <div className="ho_foot_b">
                            <div className="ho_foot_b_L">weborro limited 2018</div>
                            <div className="ho_foot_b_WT">Made with love by the weborro team</div>
                        </div>
                    </div>
                    <div onClick={e => this.scrollToTop(e)} className="back-to-top"><i className="fa fa-arrow-up"></i></div>
                </footer>
                }
            </div>
        );
    }
}

export default withRouter(Footer);