import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import {Image, Video, Transformation, CloudinaryContext} from 'cloudinary-react';
import {TweenMax,TimelineMax,TimelineLite,TweenLite,Elastic,Sine,bbox,center,Power1,Circ} from 'gsap';
import {reactLocalStorage} from 'reactjs-localstorage';
import Path from './Path';
import Sidebar from './Sidebar';
class Watchlist extends Component {
    static contextTypes = {
        router: PropTypes.object
    }
    constructor(props,context) {
        super(props,context);
        this.state = {
            auth_token : reactLocalStorage.get('auth_token'),
            product:[]
        }
        this.loadData = this.loadData.bind(this);
    }
    componentDidMount() {
        this.loadData();
        setTimeout(() =>
            this.props.handler(false)
        , 2000);
    }
    loadData(){
        var _this = this;
        axios({
            method: 'get',
            url: Path.PRODUCT_URL+Path.URL.GET_WATCHLIST,
            headers: {
                'Api-Token':'Bearer '+_this.state.auth_token
            }
        })
        .then(function (response) {
            if(response.data.success == "1"){
                _this.setState({product:response.data.Product})
            }
        })
        .catch(function (error) {
            //console.log(error);
        });
    }  
    render(){
        const renderProductListFilter = this.state.product.filter(function (el) {
            return el != '';
        });
        const renderProductList = renderProductListFilter.map(function(item, i) {
            return(
                <div className="watchlist_items" key={i}>
                    <div className="col-sm-2 col-xs-12 no-padding text-center watchlist_itemsImg">
                        {item.product_image !== undefined ?
                        item.product_image.slice(0,1).map(function(images, j) {
                            return(
                            <img key={j} src={Path.CLOUDINARY.url+'/c_scale,h_80,w_80/'+Path.CLOUDINARY.product_image+images.image_url.replace(/^.*[\\/]/, '')} onError={(e)=>{e.target.onerror = null; e.target.src=Path.NO_PRODUCT_NEW_IMAGE}} />
                            )
                        })
                        :   <img src={Path.NO_PRODUCT_NEW_IMAGE} />
                        } 
                    </div>
                    <div className="col-sm-6 col-xs-12 watchlist_itemsCont">
                        <div className="pro_name">{item.product_name}</div>
                        <div className="pro_amount"><i className="fa fa-gbp"></i>{item.price_day}/d, <i className="fa fa-gbp"></i>{item.price_week}/w</div>
                    </div>
                    <div className="col-sm-4 col-xs-12 watchlist_itemsBtn">
                        <a href={Path.BASE_URL+Path.PAGE_NAME.ProductDetails+'/'+item.lending_id} className="watchlist_items_view marginTopViewMore">View more</a>
                    </div>
                </div>
                )
        },this);
        return(
            <div>               
                <div className="main_wrapper">
                    <section className="dashboard_bg">
                        <Sidebar/>
                        <div className="content-wrapper">
                            <div className="content-height-wrapper">
                                <div className="tab-content">
                                    <div className="col-xs-12 no-padding m-t-40">
                                        <div className="col-xs-12 text-center">
                                            <h3 className="ho_renshop_sH">Watchlist Items</h3>
                                        </div>
                                        <div id="innertab-1" className="tab-innercont active">
                                            {this.state.product.length > 0 ?
                                            renderProductList
                                            :
                                            <div className="col-xs-12 text-center">
                                                <div className="watchlist_contact_text">Looks like you're not watchlist anything yet</div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div> 
        );
    }
}
export default Watchlist;